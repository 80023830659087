import { SET_SELECTED_SERVICES } from '../constants/selectedServices';

const initialState = [];

const selectedServicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_SERVICES:
            return [...state, ...action.payload];
        default:
            return state;
    }
};

export default selectedServicesReducer;
