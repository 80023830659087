import Modal from './Modal';
import React from 'react';
import { Box, Button, makeStyles, Typography, withStyles } from '@material-ui/core';

import { confirmModalStyles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import PropTypes from 'prop-types';

const ConfirmModal = ({
    classes,
    onConfirm,
    title,
    content,
    centerContent,
    contentHtml = null,
    setIsOpen,
    isOpen,
    cancelText = 'Cancel',
    continueText = 'Confirm',
    hideCloseIcon = false,
    hideCancelButton = false
}) => {
    const globalStyles = makeStyles(modalsButtonStyles)();

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Modal
            id={title}
            isOpen={isOpen}
            title={title}
            size="xs"
            draggable
            onClose={handleClose}
            dialogClass={classes.modal}
            hideCloseIcon={hideCloseIcon}
        >
            <Box container spacing={4}>
                {content ? <Typography align={centerContent ? 'center' : 'inherit'}>{content}</Typography> : ''}
                {contentHtml ? (
                    <Typography
                        align={centerContent ? 'center' : 'inherit'}
                        dangerouslySetInnerHTML={{ __html: contentHtml }}
                    ></Typography>
                ) : (
                    ''
                )}
            </Box>
            <div className={globalStyles.buttonsContainer}>
                {hideCancelButton ? (
                    ''
                ) : (
                    <Button className={globalStyles.cancelButton} onClick={handleClose}>
                        {cancelText}
                    </Button>
                )}
                <Button
                    className={globalStyles.confirmButton}
                    onClick={() => {
                        onConfirm();
                        handleClose();
                    }}
                >
                    {continueText}
                </Button>
            </div>
        </Modal>
    );
};

ConfirmModal.propTypes = {
    classes: PropTypes.string,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    content: PropTypes.any,
    centerContent: PropTypes.any,
    contentHtml: PropTypes.any,
    setIsOpen: PropTypes.func,
    isOpen: PropTypes.bool,
    cancelText: PropTypes.string,
    continueText: PropTypes.string,
    hideCloseIcon: PropTypes.bool,
    hideCancelButton: PropTypes.bool
};

export default withStyles(confirmModalStyles)(ConfirmModal);
