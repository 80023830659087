import api from './api';

export const getPractitioners = async () => {
    const currentClinic = localStorage.getItem('currentClinic');
    return api
        .request({
            method: 'GET',
            url: `/practitioners?limit=50&clinic=${currentClinic}`
        })
        .then((res) => res);
};
