import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableHead, TableRow, TableCell, withStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SaleInvoiceTableRow from './SaleInvoiceTableRow';
import { loadServices } from '../../redux/actions/servicesActions';
import { saleInvoiceTableStyles as styles } from './styles';
import { calculateTaxValue } from '../../services/invoice';
import { PaymentStatus } from '../../constants/Invoice';
import { getLocationItem, roundTwoDecimals } from '../../collums-constants/utils/index';
import { INVOICE_ITEM_TYPES, INVOICE_PAYMENT_STATUS } from '../../collums-constants/index';
import { getServicePrice } from '../../services/helpers';
import ServiceApi from '../../collums-components/api/ServiceApi';
import { setServicesSelected } from '../../redux/actions/selectedServices';

// prettier-ignore

const SaleInvoiceTable = ({ customerId,
    invoiceItems,
    clinic,
    allTaxes,
    handleUpdateItem,
    handleDeleteItem,
    classes,
    invoiceStatus,
    setDiscount,
    shouldApplyDiscount,
    discountUnits }) => {
    const dispatch = useDispatch();
    const invoiceState = useSelector(({ invoice }) => invoice);
    const [title, setTitle] = useState(null);
    const selectedServices = useSelector(store => store.selectedServices);

    useEffect(() => {
        const fetchData = async () => {

            if (invoiceState.invoice) {
                const { invoice } = invoiceState;
                const invoiceLabelRefund = invoiceState.isExchange ? 'Exchange' : 'Refund';

                if (invoice.PaymentStatus === PaymentStatus.REFUND) {
                    setTitle(`Invoice ${invoice.code} - ${invoiceLabelRefund}`);
                    return;
                }

                setTitle(`Invoice ${invoice.code}`);
            }
        };

        fetchData();
        /*eslint-disable-next-line */
    }, [invoiceState]);

    const canEditItemValues = invoiceItemCopy => {
        if (invoiceItemCopy.type !== INVOICE_ITEM_TYPES.SERVICE) return false;
        if (!invoiceState.invoice) return true;
        return invoiceState.invoice?.paymentStatus === INVOICE_PAYMENT_STATUS.UNPAID;
    };

    const editItem = async (index, property, value) => {
        const invoiceItemsCopy = [...invoiceItems];
        const invoiceItemCopy = invoiceItemsCopy[index];

        if (property === 'discount') {
            const oldDiscount = invoiceItems?.[index]?.discount;
            if (oldDiscount !== value) {
                // Reset temporary discount if value changed
                shouldApplyDiscount.current = false;
                setDiscount(
                    {
                        value: null,
                        itemsToApply: [],
                        unit: discountUnits.currency,
                        productChange: true
                    },
                    true
                );
            }
        }

        invoiceItemCopy[property] = value;

        const computateNewValues = async () => {
            const { netPrice, quantity, discount, tax } = invoiceItemCopy;

            if (property === 'soldBy') {
                invoiceItemCopy.practitioner = value;
                if (canEditItemValues(invoiceItemCopy)) {
                    const service = await (async () => {
                        const serviceOnList = selectedServices.find(el => el.id === invoiceItemCopy.referenceId || invoiceItemCopy.id === el.id);
                        if (serviceOnList) return serviceOnList;
                        const newService = await ServiceApi.get(invoiceItemCopy.referenceId || invoiceItemCopy.id);
                        if (newService) {
                            dispatch(setServicesSelected([...selectedServices, newService]));
                            return newService;
                        }
                    })();
                    if (service) {                        
                        const newGrossPrice = (roundTwoDecimals(netPrice * (1 + (tax / 100))) * value);
                        getLocationItem(service, clinic).itemTax = (allTaxes || []).find(el => el.id === getLocationItem(service, clinic).taxType).rate;
                        const newValues = getServicePrice(getLocationItem(service, clinic), value.id || value);
                        invoiceItemCopy.tax = (allTaxes || []).find(el => el.id === getLocationItem(service, clinic).taxType).rate;
                        invoiceItemCopy.netPrice = newValues.netPrice;
                        invoiceItemCopy.listPrice = newValues.netPrice;
                        invoiceItemCopy.taxValue = calculateTaxValue(netPrice, invoiceItemCopy.itemTax) * invoiceItemCopy.quantity;
                        invoiceItemCopy.grossPrice = newGrossPrice;
                    }
                }
                return;
            }

            if (property === 'name') return;

            if (property === 'quantity') {
                const newGrossPrice = (roundTwoDecimals(netPrice * (1 + (tax / 100))) * value);
                invoiceItemCopy.grossPrice = newGrossPrice;
                invoiceItemCopy.taxValue = calculateTaxValue(netPrice, tax) * value;
                invoiceItemCopy.finalPrice = newGrossPrice - discount;
                invoiceItemCopy.quantity = value;
                return;
            }

            if (property === 'netPrice') {
                if (invoiceItemCopy?.type === INVOICE_ITEM_TYPES.ACCOUNT) {
                    invoiceItemCopy.listPrice = value;
                }

                if (invoiceItemCopy?.type === INVOICE_ITEM_TYPES.GIFTCARD) {
                    invoiceItemCopy.listPrice = value;
                }

                value = value / quantity;
                const newGrossPrice = (roundTwoDecimals(value * (1 + (tax.rate / 100))) * quantity);
                invoiceItemCopy.grossPrice = newGrossPrice;
                invoiceItemCopy.finalPrice = newGrossPrice - discount;
                return;
            }

            if (property === 'tax') {
                const newTaxValue = calculateTaxValue(netPrice, tax);
                const newGrossPrice = ((newTaxValue) * quantity);
                invoiceItemCopy.grossPrice = newGrossPrice;
                invoiceItemCopy.finalPrice = newGrossPrice - discount;
                invoiceItemCopy.taxValue = newTaxValue;
                return;
            }

            if (property === 'grossPrice') {
                const newNetPrice = value / quantity / (1 + (tax / 100));
                invoiceItemCopy.netPrice = newNetPrice;
                invoiceItemCopy.taxValue = calculateTaxValue(newNetPrice, tax);
                invoiceItemCopy.finalPrice = value - discount;
                return;
            }

            if (property === 'discount') {
                invoiceItemCopy.discount = value;
                invoiceItemCopy.editedDiscount = value;
                invoiceItemCopy.appliableDiscount = false;
                invoiceItemCopy.hasPromotionDiscount = false;
                invoiceItemCopy.finalPrice = roundTwoDecimals(netPrice * (1 + tax) * quantity - value);
                invoiceItemCopy.isTempDiscountApplied = false;
                //return;
            }

            if (property === '_totalPrice') {
                invoiceItemCopy.editedDiscount = 0;
                invoiceItemCopy.discount = 0;

                invoiceItemCopy.appliableDiscount = false;
                invoiceItemCopy.hasPromotionDiscount = false;
                invoiceItemCopy.isTempDiscountApplied = false;

                const newNetPrice = (+value + invoiceItemCopy.editedDiscount) / quantity / (1 + tax);
                const grossPrice = (roundTwoDecimals(newNetPrice * (1 + tax)) * quantity);

                invoiceItemCopy.netPrice = newNetPrice;
                invoiceItemCopy.grossPrice = grossPrice;
                invoiceItemCopy.taxValue = calculateTaxValue(netPrice, tax) * quantity;
                return;
            }
        };

        await computateNewValues();
        handleUpdateItem(invoiceItemCopy, index);
    };

    const renderNoContent = () =>
        <div className={classes.emptyTable}>
            <Typography>There are no items to show</Typography>
        </div>;

    useEffect(() => {
        dispatch(loadServices());
    }, [dispatch]);

    if (!customerId) {
        return null;
    }

    return (
        <div>
            <Typography className={classes.topLabel} variant="h3" align="center">
                {title}
            </Typography>
            <Table size="medium" style={{ minWidth: 816 }}>
                <TableHead>
                    <TableRow>

                        <TableCell className={classes.tableHeader}>Sold by</TableCell>
                        <TableCell className={classes.tableHeader}>Item</TableCell>
                        <TableCell className={classes.tableHeader}>#</TableCell>
                        <TableCell className={classes.tableHeader} align="right">
                            Net Price
                        </TableCell>
                        <TableCell className={classes.tableHeader} align="right">
                            Tax %
                        </TableCell>
                        <TableCell className={classes.tableHeader} align="right">
                            Sub Total
                        </TableCell>
                        <TableCell className={classes.tableHeader} align="right">
                            Gross Discount
                        </TableCell>
                        <TableCell className={classes.tableHeader} align="right">
                            Total Price
                        </TableCell>
                        <TableCell className={classNames(classes.tableHeader, classes.tableActionsColumns)} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoiceItems.length
                        ? invoiceItems.map((item, index) =>
                            (
                                <SaleInvoiceTableRow
                                    clinic={clinic}
                                    allTaxes={allTaxes}
                                    key={index}
                                    item={item}
                                    index={index}
                                    editItem={editItem}
                                    removeItem={handleDeleteItem}
                                    invoiceStatus={invoiceStatus}
                                />
                            )
                        )
                        : null
                    }
                </TableBody>
            </Table>
            {(!(invoiceState.invoice && invoiceState.invoice.items.length) && !invoiceItems.length) && renderNoContent()}
        </div>
    );
};

SaleInvoiceTable.propTypes = {
    customerId: PropTypes.string,
    invoiceItems: PropTypes.array.isRequired,
    handleUpdateItem: PropTypes.func.isRequired,
    handleDeleteItem: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    invoiceStatus: PropTypes.string,
    allTaxes: PropTypes.array.isRequired,
    clinic: PropTypes.string.isRequired,
    setDiscount: PropTypes.func.isRequired,
    shouldApplyDiscount: PropTypes.object.isRequired,
    discountUnits: PropTypes.object.isRequired
};

export default withStyles(styles)(SaleInvoiceTable);
