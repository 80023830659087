import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { salePaymentStyles as styles } from './styles';
import { Calculator } from '../../assets/icons';

import { INVOICE_PAYMENT_TYPES, INVOICE_PAYMENT_STATUS } from '../../collums-constants/index';
import { toLocaleString } from '../../collums-components/helpers';
import { CURRENT_CLINIC } from '../../collums-constants/storageKeys';

import GiftcardApi from '../../api/GiftcardApi';
import { getByInvoice } from '../../api/invoiceApi';
import { getOrganisation } from '../../api/organisationApi';
import NotificationApi from '../../api/notificationApi';

import SaleNumericPad from './SaleNumericPad';
import SaleCardPaymentModal from './SaleCardPaymentModal';
import RedeemGiftCard from './RedeemGiftCard';

import { copyAndSetValueInArray, createUniqId } from '../../services/helpers';
import processPayments from '../../services/processPayments';

import {
    TextField as MUITextField,
    IconButton,
    Typography,
    withStyles,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Button from '../common/Button';
import CloseBtn from '../common/CloseBtn';

import { setInvoice } from '../../redux/actions/invoiceActions';
import { setNotifications } from '../../redux/actions/notificationsActions';
import { setPaymentsPayload } from '../../redux/actions/paymentsPayloadActions';
import { setOrganisation } from '../../redux/actions/organisationActions';

const SalePayment = ({
    customerId,
    tipValue,
    items,
    classes,
    setItems,
    wasPaid,
    discount,
    saveInvoiceNote,
    isProcessing,
    setIsProcessing
}) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [defaultValue, setDefaultValue] = useState([]);
    const [notificationStatus, setNotificationStatus] = useState([]);
    const [allNotifications, setAllNotifications] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [voucher, setVoucher] = useState(undefined);
    const [voucherCode, setVoucherCode] = useState(undefined);
    const [editedPaymentIndex, setEditedPaymentIndex] = useState(null);
    const [editedPaymentText, setEditedPaymentText] = useState(null);
    const [isActiveNumericPad, setIsActiveNumericPad] = useState(false);
    const [cardPayment, setCardPayment] = useState({
        isVisible: false,
        cardType: INVOICE_PAYMENT_TYPES.CREDIT_CARD,
        value: 0
    });
    const [showRedeem, setShowRedeem] = useState(false);
    // const [outstandingAmountModal, setOutstandingAmountModal] = useState(false);
    const [paymentInProgress, setPaymentInProgress] = useState(false);

    const { invoice, invoicePrice, invoiceDiscount, appointment } = useSelector((state) => state.invoice);
    const currentCustomerData = useSelector((state) => state.currentCustomer.customerData);
    const [organisationNotification, setOrganisationNotification] = useState({
        email: false,
        SMS: false
    });
    const [notifications, setNotificationsState] = useState({
        email: true,
        SMS: false
    });
    const isProcessingPayment = useRef(false);

    const getIsProcessingPayment = useCallback(() => isProcessingPayment.current, [isProcessingPayment]);
    const updateProcessPaymentStatus = useCallback((status, updatePaymentHandler = true) => {
        isProcessingPayment.current = status;
        if (updatePaymentHandler) {
            setIsDisabled(status);
            setIsProcessing(status);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //currentCustomerData.notificationTypes.some((element) => element === 'Email')
    const temporaryDiscount = useSelector((state) => state.invoice.selectedDiscount);

    const currentClinic = localStorage.getItem(CURRENT_CLINIC);
    const amountPaid = invoice && invoice.payments ? invoice.payments?.reduce((acc, item) => acc + item.amount, 0) : 0;

    const invoiceUrl = new URL(window.location.href).searchParams.get('invoice');

    useEffect(() => {
        if (currentCustomerData !== null && currentCustomerData.notificationTypes !== undefined) {
            setNotificationsState({
                email:
                    organisationNotification.email &&
                    currentCustomerData.notificationTypes.some((element) => element === 'Email'),
                SMS:
                    organisationNotification.SMS &&
                    currentCustomerData.notificationTypes.some((element) => element === 'SMS')
            });
        }
    }, [currentCustomerData, organisationNotification]);

    useEffect(() => {
        if (voucher !== undefined && voucher.customer && voucher.customer !== customerId) {
            const index = paymentOptions.findIndex((item) => item.type === INVOICE_PAYMENT_TYPES.GIFTCARD);
            setVoucher(undefined);
            setPaymentOptions(copyAndSetValueInArray(paymentOptions, 0, index, 'value'));
        }
        if (!customerId) {
            setVoucher(undefined);
            setPaymentOptions(defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);

    useEffect(() => {
        getOrganisation().then((res) => {
            const options = res.paymentTypes
                .filter((paymentType) => paymentType.active)
                .map((paymentType) => ({
                    type: paymentType.name,
                    label: paymentType.name === 'Card' ? 'Coherent payment' : paymentType.label,
                    value: 0
                }));

            setDefaultValue(options);
            setPaymentOptions(options);
            setOrganisationNotification(res.paymentNotifications);
            dispatch(setNotifications(res.paymentNotifications));
            dispatch(setOrganisation(res));
        });
    }, [dispatch]);

    useEffect(() => {
        NotificationApi.query().then(setAllNotifications);
    }, []);

    const getNotificationsStatus = useCallback(async () => {
        allNotifications.forEach((element) => {
            if (element.name === 'Invoice Payment') {
                setNotificationStatus(element);
            }
        });
    }, [allNotifications]);

    useEffect(() => {
        getNotificationsStatus();
    }, [getNotificationsStatus]);

    const changeNotificationCheckbox = useCallback(
        (checked, type) => {
            if (type === 0) {
                // email
                setNotificationsState({ email: checked, SMS: notifications.SMS });
                dispatch(setNotifications({ email: checked, SMS: notifications.SMS }));
            } else {
                // SMS
                setNotificationsState({ SMS: checked, email: notifications.email });
                dispatch(setNotifications({ SMS: checked, email: notifications.email }));
            }
        },
        [dispatch, notifications.SMS, notifications.email]
    );

    const getInvoicePrice = useCallback(() => {
        return invoice?.id || invoice?._id ? invoicePrice + tipValue - amountPaid : invoicePrice + tipValue;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoicePrice, tipValue, amountPaid, invoice?.id, invoice?._id]);

    const calculateOutstandingValue = useCallback(
        (exceptPaymentIndex) => {
            const paymentsSum = paymentOptions.reduce(
                (acc, option, index) =>
                    acc + (null == exceptPaymentIndex || exceptPaymentIndex !== index ? option.value : 0),
                0
            );

            return paymentsSum < getInvoicePrice() ? invoicePrice - amountPaid - paymentsSum + tipValue : 0;
        },
        [paymentOptions, getInvoicePrice, invoicePrice, amountPaid, tipValue]
    );

    const calculateChangeValue = () => {
        const paymentsSum = paymentOptions.reduce((acc, option) => acc + option.value, 0);
        const invoiceTotalPrice = getInvoicePrice();
        return paymentsSum > invoiceTotalPrice ? paymentsSum - invoiceTotalPrice - tipValue : 0;
    };

    const editCurrencyValue = useCallback(
        (index) => {
            setEditedPaymentIndex(index);
            setEditedPaymentText(paymentOptions[index].value ? paymentOptions[index].value.toString() : '');
        },
        [paymentOptions]
    );

    const editGiftcardValue = useCallback(
        (index) => {
            if (voucher) {
                setEditedPaymentIndex(index);
                setEditedPaymentText('');
            } else {
                toastr.error('Whoops', 'Please enter a gift voucher number first');
            }
        },
        [voucher]
    );

    const enterCurrencyValue = () => {
        setEditedPaymentIndex(null);
        setIsActiveNumericPad(false);
        setPaymentOptions(
            copyAndSetValueInArray(paymentOptions, Number(editedPaymentText), editedPaymentIndex, 'value')
        );
    };

    const fullfillCurrencyValue = (index) => {
        setEditedPaymentIndex(null);
        setPaymentOptions(copyAndSetValueInArray(paymentOptions, calculateOutstandingValue(index), index, 'value'));
    };

    const fullfillGiftcardValue = (index) => {
        if (voucher) {
            const calculateVoucher = () => {
                const outstanding = calculateOutstandingValue(index);

                if (outstanding >= voucher.balance) {
                    return voucher.balance;
                } else {
                    return outstanding;
                }
            };

            setEditedPaymentIndex(null);
            setPaymentOptions(copyAndSetValueInArray(paymentOptions, calculateVoucher(), index, 'value'));
        } else {
            toastr.error('Whoops', 'Please enter a gift voucher number first');
        }
    };

    const fulfillAccountValue = (index) => {
        const calculateAccount = () => {
            const outstanding = calculateOutstandingValue(index);

            if (currentCustomerData.accountBalance && currentCustomerData.accountBalance > 0) {
                if (outstanding >= currentCustomerData.accountBalance) return currentCustomerData.accountBalance;
                else return outstanding;
            } else {
                toastr.error('Whoops', "Seems like you don't have enough money in your account balance");
                return 0;
            }
        };

        setEditedPaymentIndex(null);
        setPaymentOptions(copyAndSetValueInArray(paymentOptions, calculateAccount(), index, 'value'));
    };

    const clearCurrencyValue = (index) => {
        setEditedPaymentIndex(null);
        setPaymentOptions(copyAndSetValueInArray(paymentOptions, 0, index, 'value'));
    };

    const takeZeroPayments = () => {
        return {
            value: 0,
            discount: invoiceDiscount,
            type: INVOICE_PAYMENT_TYPES.FOC
        };
    };

    const takeRedemptionPayments = () => {
        const containsRedemption = (items || []).some((item) => item.isRedemption);
        if (!containsRedemption) return [];
        const mappedCoursePayments = _.uniqBy(
            (items || [])
                .map((item) => {
                    if (item.isRedemption) {
                        return {
                            value: 0,
                            discount: 0,
                            type: item.redemptionCourse
                        };
                    }
                    return {};
                })
                .filter((payment) => payment.type),
            'type'
        );
        return mappedCoursePayments;
    };

    const clearValues = () => {
        setPaymentOptions(defaultValue);
    };

    const showInvoicePayment = async (invoiceId) => {
        wasPaid.current = true;

        await saveInvoiceNote({ takingPayment: true, invoiceId });

        clearValues();

        const reloadedInvoice = await getByInvoice(invoiceId);
        reloadedInvoice.items = reloadedInvoice.items.map((item) => {
            return { ...item, tax: typeof item.tax === 'number' && item.tax < 1 ? item.tax * 100 : item.tax };
        });

        dispatch(setInvoice(reloadedInvoice));

        setItems(reloadedInvoice.items);

        history.push(`/sale?invoice=${invoiceId}`);
        dispatch(setPaymentsPayload([]));
    };

    const takeOutsidePayments = () => {
        let outStandingPayments = paymentOptions.filter(
            ({ type }) =>
                type !== INVOICE_PAYMENT_TYPES.CREDIT_CARD &&
                type !== INVOICE_PAYMENT_TYPES.GIFTCARD &&
                type !== INVOICE_PAYMENT_TYPES.ACCOUNT_BALANCE
        );

        const outstanding = calculateOutstandingValue(null);
        let changeValue = calculateChangeValue();
        const isSurplus = outstanding <= 0 && changeValue > 0;

        // correcting surplus value if cash payment exists.
        const getValue = (option) => {
            if (option.type === INVOICE_PAYMENT_TYPES.CASH && isSurplus && changeValue > 0) {
                if (changeValue <= Number(option.value)) {
                    const finalValue = Number(option.value) - changeValue;
                    changeValue = 0;
                    return finalValue;
                } else {
                    changeValue = Number(option.value);
                    return 0;
                }
            }

            return Number(option.value);
        };

        // map through the optional payment options and get their values
        return outStandingPayments
            .map((option) => {
                const value = getValue(option);

                if (value > 0) {
                    return {
                        value,
                        discount: invoiceDiscount,
                        type: option.type,
                        splitPaymentId: createUniqId()
                    };
                }
                return null;
            })
            .filter((payment) => payment);
    };

    const takePayment = async () => {
        if (getIsProcessingPayment()) return;
        try {
            updateProcessPaymentStatus(true);
            if (invoice && (invoice.id || invoice._id)) {
                const invoiceId = invoice._id || invoice.id;
                const reloadedInvoice = await getByInvoice(invoiceId);
                if (reloadedInvoice.paymentStatus === INVOICE_PAYMENT_STATUS.PAID) {
                    toastr.error('Error', 'Already Paid Invoice');
                    showInvoicePayment(invoiceId);
                    return;
                }
            }
            const cardValues = paymentOptions.filter(
                ({ type, value }) => type === INVOICE_PAYMENT_TYPES.CREDIT_CARD && value > 0
            );
            const giftCardValue = paymentOptions.filter(
                ({ type, value }) => type === INVOICE_PAYMENT_TYPES.GIFTCARD && value > 0
            );

            const accountValue = paymentOptions.filter(
                ({ type, value }) => type === INVOICE_PAYMENT_TYPES.ACCOUNT_BALANCE && value > 0
            );

            let payments = [];

            let haveCreditCardPayment = false;

            const redemptions = takeRedemptionPayments();
            if (invoice && invoice.paymentStatus === INVOICE_PAYMENT_STATUS.UNPAID) {
                if (redemptions && redemptions.length) payments = [...redemptions];
            }
            if (invoicePrice === 0 && !items.filter((item) => item.isRedemption).length) {
                payments.push(takeZeroPayments());
            }
            if (cardValues.length > 0) {
                await takeCardPayment(cardValues);
                haveCreditCardPayment = true;
            }

            if (giftCardValue.length > 0) payments.push(takeGiftcardPayment());
            if (accountValue.length > 0) payments.push(takeAccountPayment());

            // take all payments that are optional to the organisation
            payments.push(...takeOutsidePayments());

            if (!haveCreditCardPayment) {
                const invoiceId = await processPayments(
                    payments,
                    { items, invoiceDiscount, invoicePrice, temporaryDiscount, discountProperties: discount },
                    { invoice, paymentsPayload: [], customerId },
                    false,
                    notifications,
                    currentClinic,
                    appointment?.id
                );
                if (invoiceId) {
                    await showInvoicePayment(invoiceId.id);
                }
            } else {
                dispatch(setPaymentsPayload(payments));
            }

            // setOutstandingAmountModal(false);
            setPaymentInProgress(true);
            setIsDisabled(false);
            setIsProcessing(false);
            setVoucher(undefined);
        } catch (e) {
            console.error(e);
        } finally {
            updateProcessPaymentStatus(false);
        }
    };

    const takeCardPayment = () => {
        setCardPaymentOpts();
    };

    const takeGiftcardPayment = () => {
        const paymentOption = paymentOptions.find((item) => item.type === INVOICE_PAYMENT_TYPES.GIFTCARD);

        return {
            value: paymentOption ? paymentOption.value : paymentOptions[1].value,
            discount: invoiceDiscount,
            type: INVOICE_PAYMENT_TYPES.GIFTCARD,
            giftcard: {
                id: voucher.id,
                balance: voucher.balance
            },
            splitPaymentId: createUniqId()
        };
    };

    const takeAccountPayment = () => {
        const paymentOption = paymentOptions.find((item) => item.type === INVOICE_PAYMENT_TYPES.ACCOUNT_BALANCE);

        return {
            value: Number(paymentOption ? paymentOption.value : paymentOptions[2].value),
            discount: invoiceDiscount,
            type: INVOICE_PAYMENT_TYPES.ACCOUNT_BALANCE,
            splitPaymentId: createUniqId()
        };
    };

    const setCardPaymentOpts = () => {
        const paymentOption = paymentOptions.find((item) => item.type === INVOICE_PAYMENT_TYPES.CREDIT_CARD);

        setCardPayment({
            isVisible: true,
            cardType: INVOICE_PAYMENT_TYPES.CREDIT_CARD,
            value: paymentOption ? paymentOption.value : paymentOptions[0].value
        });
    };

    const handleTakePayment = () => {
        setIsDisabled(true);
        setIsProcessing(true);
        const outstandingAmount = calculateOutstandingValue();
        if (outstandingAmount > 0) {
            takePayment();
            return;
        }
        takePayment().then(() => {
            setIsDisabled(false);
            setIsProcessing(false);
        });
    };

    const calculatorIconButtonHandler = useCallback(
        (option, index) => {
            if (editedPaymentIndex !== null) {
                setIsActiveNumericPad(false);
                setEditedPaymentIndex(null);
            } else {
                if (
                    option.type === INVOICE_PAYMENT_TYPES.ACCOUNT_BALANCE &&
                    (!currentCustomerData?.accountBalance || currentCustomerData?.accountBalance <= 0)
                ) {
                    toastr.error('Whoops', "Seems like you don't have enough money in your account balance");
                    return 0;
                } else if (option.type !== INVOICE_PAYMENT_TYPES.GIFTCARD) {
                    editCurrencyValue(index);
                } else {
                    editGiftcardValue(index);
                }
            }
        },
        [currentCustomerData, editedPaymentIndex, editCurrencyValue, editGiftcardValue]
    );

    const saleNumericPadHandler = useCallback(
        (value) => {
            let updateText;
            const outstandingValue = calculateOutstandingValue(editedPaymentIndex);
            const paymentType = paymentOptions[editedPaymentIndex]?.type;

            if (paymentType === INVOICE_PAYMENT_TYPES.GIFTCARD) {
                updateText = Number(value) <= voucher.balance && outstandingValue >= Number(value);
            } else if (paymentType === INVOICE_PAYMENT_TYPES.ACCOUNT_BALANCE) {
                updateText = Number(value) <= currentCustomerData?.accountBalance && outstandingValue >= Number(value);
            } else if (paymentType === INVOICE_PAYMENT_TYPES.CASH) {
                updateText = true; // allow put amount higher then total invoice price
            } else {
                updateText = outstandingValue >= Number(value);
            }

            if (updateText) {
                setEditedPaymentText(value.toString());
                setIsActiveNumericPad(true);
            }
        },
        [editedPaymentIndex, paymentOptions, currentCustomerData, calculateOutstandingValue, voucher]
    );

    useEffect(() => {
        if (paymentInProgress && !cardPayment.isVisible) {
            setPaymentInProgress(false);
        }
        /*eslint-disable-next-line */
    }, [paymentInProgress, cardPayment]);

    const getVoucher = async () => {
        const clearVoucher = () => {
            const index = paymentOptions.findIndex((item) => item.type === INVOICE_PAYMENT_TYPES.GIFTCARD);
            setVoucher(undefined);
            setPaymentOptions(copyAndSetValueInArray(paymentOptions, 0, index, 'value'));
        };

        if (!voucherCode) {
            clearVoucher();
            return;
        }

        try {
            const res = await GiftcardApi.getGiftcard(voucherCode);

            clearVoucher();
            if (res.customer && res.customer !== customerId) {
                throw new Error('Gift voucher cannot be used by this customer.');
            }

            setVoucher(res);
            toastr.success('Success!', 'The giftvoucher was found');
        } catch (e) {
            clearVoucher();
            toastr.error('Error', e.message || e.data.message);
        }
    };

    if (!customerId) {
        return null;
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getVoucher();
        }
    };

    const checkPaymentStatusAvailability = () => {
        if (invoice.paymentStatus === 'Paid' || invoice.paymentStatus === 'Refund' || invoice.paymentStatus === 'Void')
            return true;
        return false;
    };

    const getIsButtonBlocked = () => {
        const outstandingValue = calculateOutstandingValue();
        if (items.length) {
            return outstandingValue === 0
                ? false
                : isDisabled || editedPaymentIndex !== null || outstandingValue >= invoicePrice;
        } else {
            return true;
        }
    };

    if (invoice && checkPaymentStatusAvailability() && invoiceUrl)
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '10%'
                }}
            >
                <SaleCardPaymentModal
                    items={items}
                    isVisible={cardPayment.isVisible}
                    setIsVisible={setCardPayment}
                    cardType={cardPayment.cardType}
                    paymentValue={cardPayment.value}
                    clearValues={clearValues}
                    showInvoicePayment={showInvoicePayment}
                    saveInvoiceNote={saveInvoiceNote}
                    discount={discount}
                />
                {/* <SaleOutstandingAmountModal
                    isVisible={outstandingAmountModal}
                    close={() => setOutstandingAmountModal(false)}
                    outstandingValue={calculateOutstandingValue()}
                    confirm={takePayment}
                /> */}
            </div>
        );

    return (
        <div className={classes.container}>
            <div className={classes.methodsContainer}>
                <Typography className={classes.topLabel} variant="h3" align="center">
                    Payment
                </Typography>
                {paymentOptions.map((option, index) => {
                    const isEdited = index === editedPaymentIndex;
                    return (
                        <div key={index} className={classes.row}>
                            <div>
                                <Button
                                    className={classes.payBtn}
                                    type="secondary"
                                    onClick={() => {
                                        if (option.type === INVOICE_PAYMENT_TYPES.CREDIT_CARD)
                                            fullfillCurrencyValue(index);
                                        else if (option.type === INVOICE_PAYMENT_TYPES.ACCOUNT_BALANCE)
                                            fulfillAccountValue(index);
                                        else if (option.type === INVOICE_PAYMENT_TYPES.GIFTCARD)
                                            fullfillGiftcardValue(index);
                                        else fullfillCurrencyValue(index);
                                    }}
                                >
                                    {option.type === INVOICE_PAYMENT_TYPES.GIFTCARD
                                        ? 'Gift voucher'
                                        : option.label || option.type}
                                </Button>
                                <IconButton
                                    className={classes.calculatorBtn}
                                    onClick={() => calculatorIconButtonHandler(option, index)}
                                >
                                    <Calculator />
                                </IconButton>
                            </div>
                            <div>
                                <Typography className={classNames(classes.amount, isEdited && classes.editedAmount)}>
                                    {isEdited ? toLocaleString(editedPaymentText) : toLocaleString(option.value)}
                                </Typography>
                                <CloseBtn className={classes.closeBtn} onClick={() => clearCurrencyValue(index)} />
                            </div>
                        </div>
                    );
                })}
                <div
                    className={classes.row}
                    style={{ display: 'flex', alignItems: 'unset', flexDirection: 'column', justifyContent: 'center' }}
                >
                    <MUITextField
                        size={'small'}
                        style={{ width: '156px', marginRight: '1%' }}
                        label="Gift voucher no"
                        variant="outlined"
                        onChange={(e) => setVoucherCode(e.target.value)}
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            endAdornment: <SearchIcon className={classes.voucherSearchIcon} onClick={getVoucher} />
                        }}
                        InputLabelProps={{ style: { fontSize: '10pt', marginTop: '5px' } }}
                        id="giftvoucher"
                    />
                </div>
                <div className={classes.row} style={{ justifyContent: 'unset', marginBottom: '16px' }}>
                    <div style={{ marginLeft: '0' }} className={classes.summaryLabel}>
                        Balance
                    </div>
                    <div className={classes.summaryAmount}>
                        {toLocaleString(
                            (voucher?.balance || 0) -
                                paymentOptions.find((element) => element.type === INVOICE_PAYMENT_TYPES.GIFTCARD)?.value
                        )}
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.summaryLabel}>Invoice Total</div>
                    <div className={classes.summaryAmount}>{toLocaleString(invoicePrice)}</div>
                </div>
                <div className={classes.row}>
                    <div className={classNames(classes.summaryLabel, classes.bold)}>Amount Paid</div>
                    <div className={classNames(classes.summaryAmount, classes.bold)}>{toLocaleString(amountPaid)}</div>
                </div>
                <div className={classes.row}>
                    <div className={classNames(classes.summaryLabel, classes.bold, classes.red)}>Outstanding</div>
                    <div className={classNames(classes.summaryAmount, classes.bold, classes.red)}>
                        {toLocaleString(calculateOutstandingValue())}
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.summaryLabel}>Change</div>
                    <div className={classes.summaryAmount}>{toLocaleString(calculateChangeValue())}</div>
                </div>
                <div className={`${classes.row} ${classes.takePayment}`} style={{ marginTop: '16px' }}>
                    <Button
                        isDisabled={getIsButtonBlocked() || isDisabled || isProcessing}
                        type="primary"
                        className={`${classes.payBtn}`}
                        onClick={() => handleTakePayment()}
                    >
                        Take payment
                    </Button>
                </div>
                <div className={classes.paymentNotifications}>
                    <div>Payment notifications:</div>
                    <div className={classes.notificationsCheckboxes}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={notifications.email}
                                    onChange={({ target }) => changeNotificationCheckbox(target.checked, 0)}
                                    color="primary"
                                    disabled={notificationStatus.email ? !notificationStatus.email.active : false}
                                />
                            }
                            label="Email"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={notifications.SMS}
                                    onChange={({ target }) => changeNotificationCheckbox(target.checked, 1)}
                                    color="primary"
                                    disabled={notificationStatus.sms ? !notificationStatus.sms.active : false}
                                />
                            }
                            label="SMS"
                        />
                    </div>
                </div>
            </div>
            {null != editedPaymentIndex && (
                <SaleNumericPad
                    text={(() => (isActiveNumericPad ? editedPaymentText : ''))()}
                    setText={saleNumericPadHandler}
                    confirm={enterCurrencyValue}
                />
            )}
            <SaleCardPaymentModal
                items={items}
                isVisible={cardPayment.isVisible}
                setIsVisible={setCardPayment}
                cardType={cardPayment.cardType}
                paymentValue={cardPayment.value}
                clearValues={clearValues}
                showInvoicePayment={showInvoicePayment}
                discount={discount}
                isProcessingPayment={getIsProcessingPayment}
                updateProcessPaymentStatus={updateProcessPaymentStatus}
                saveInvoiceNote={saveInvoiceNote}
            />
            {/* <SaleOutstandingAmountModal
                isVisible={outstandingAmountModal}
                close={() => setOutstandingAmountModal(false)}
                outstandingValue={calculateOutstandingValue()}
                confirm={takePayment}
            /> */}
            <RedeemGiftCard
                isVisible={showRedeem}
                closeModal={setShowRedeem}
                voucherOptions={[]}
                currentCustomer={currentCustomerData}
            />
        </div>
    );
};

SalePayment.propTypes = {
    invoiceValue: PropTypes.number.isRequired,
    isExchange: PropTypes.bool.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    setIsProcessing: PropTypes.func.isRequired,
    tipValue: PropTypes.number.isRequired,
    customerId: PropTypes.string,
    customer: PropTypes.object.isRequired,
    items: PropTypes.array,
    classes: PropTypes.object.isRequired,
    setItems: PropTypes.func.isRequired,
    wasPaid: PropTypes.object.isRequired,
    discount: PropTypes.object.isRequired,
    saveInvoiceNote: PropTypes.func
};

export default withStyles(styles)(SalePayment);
