const styles = theme => ({
    errorMessageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        padding: 48
    },
    errorIcon: {
        width: 72,
        height: 72
    },
    errorTitle: {
        fontSize: 42,
        color: theme.palette.red.main,
        marginBottom: 8
    },
    errorMessage: {
        fontSize: 20,
        maxWidth: '100%',
        width: 480,
        textAlign: 'center'
    }
});

export default styles;
