import { SET_PAYMENTS } from '../constants/paymentsPayload';

const initialValue = [];

const paymentsPayloadReducer = (state = initialValue, action) => {
    switch (action.type) {
        case SET_PAYMENTS:
            return action.payload || [];
        default:
            return state;
    }
};

export default paymentsPayloadReducer;
