import React from 'react';
import PropTypes from 'prop-types';
import { Icon, makeStyles } from '@material-ui/core';

const getPath = file => {
    return `/icons/${file}`;
};

const useStyles = makeStyles({
    imageIcon: {
        height: '100%',
        width: '100%'
    },
    iconRoot: {
        textAlign: 'center',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        verticalAlign: 'center'
    },
    small: {
        height: '18px',
        width: '18px'
    },
    medium: {
        height: '30px',
        width: '30px'
    },
    large: {
        height: '35px',
        width: '35px'
    }
});

const IconBuilder = ({ path, classes, variant, opacity }) => {
    const useClasses = useStyles();

    if (!classes && variant) {
        switch (variant) {
            case 'small':
                classes = useClasses.small;
                break;
            case 'medium':
                classes = useClasses.medium;
                break;
            case 'large':
                classes = useClasses.large;
                break;
            default:
                break;
        }
    }
    if (!opacity) opacity = '1.0';
    return (
        <Icon className={classes ? [`${classes}`, `${useClasses.iconRoot}`] : useClasses.iconRoot}>
            <img alt={path} className={useClasses.imageIcon} style={{ opacity }} src={require(`.${getPath(path)}`)} />
        </Icon>
    );
};

IconBuilder.propTypes = {
    path: PropTypes.string.isRequired,
    classes: PropTypes.object,
    variant: PropTypes.string,
    opacity: PropTypes.string
};

/*eslint-disable */
export const GenericError = ({ classes, variant, opacity }) => (
    <IconBuilder path="generic-error.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const CloseOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="close-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Client = ({ classes, variant, opacity }) => (
    <IconBuilder path="avatar.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const AddBlue = ({ classes, variant, opacity }) => (
    <IconBuilder path="add_blue.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Calendar = ({ classes, variant, opacity }) => (
    <IconBuilder path="calendar.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const ChevronBackWhite = ({ classes, variant, opacity }) => (
    <IconBuilder path="chevron-back-white.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const ChevronForwardWhite = ({ classes, variant, opacity }) => (
    <IconBuilder path="chevron-forward-white.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Eye = ({ classes, variant, opacity }) => (
    <IconBuilder path="eye.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const EyeOffWhite = ({ classes, variant, opacity }) => (
    <IconBuilder path="eye-off-white.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Help = ({ classes, variant, opacity }) => (
    <IconBuilder path="help.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Stock = ({ classes, variant, opacity }) => (
    <IconBuilder path="inventory.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Lock = ({ classes, variant, opacity }) => (
    <IconBuilder path="lock.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const LogOut = ({ classes, variant, opacity }) => (
    <IconBuilder path="logout.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Marketing = ({ classes, variant, opacity }) => (
    <IconBuilder path="marketing.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Pos = ({ classes, variant, opacity }) => (
    <IconBuilder path="pos.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Reports = ({ classes, variant, opacity }) => (
    <IconBuilder path="reports.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Search = ({ classes, variant, opacity }) => (
    <IconBuilder path="search.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Settings = ({ classes, variant, opacity }) => (
    <IconBuilder path="settings.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const ZoomIn = ({ classes, variant, opacity }) => (
    <IconBuilder path="zoom-in.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const ZoomOut = ({ classes, variant, opacity }) => (
    <IconBuilder path="zoom-out.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Schedule = ({ classes, variant, opacity }) => (
    <IconBuilder path="schedule.svg" classes={classes} variant={variant} opacity={opacity} />
);
