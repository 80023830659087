import {
    INVOICE_FETCHED,
    SET_IS_EXCHANGE,
    SET_INVOICE_PRICE,
    SET_INVOICE_DISCOUNT,
    SET_TEMPORARY_DISCOUNT,
    SET_APPOINTMENT
} from '../constants/invoice';

import { getByInvoice, addItemInvoice, removeById, updateItem } from '../../api/invoiceApi';
import { getInvoiceDraft } from '../../api/appointmentApi';
import { toastr } from 'react-redux-toastr';
import { UpdateXeroItem } from '../../api/xeroApi';
import { getServicePrice } from '../../services/helpers';
import { getLocationItem } from '../../collums-constants/utils';

export const loadInvoiceItems = (invoiceId) => {
    return async (dispatch) => {
        try {
            const response = await getByInvoice(invoiceId);

            response.items = response.items.map((item) => {
                return { ...item, tax: typeof item.tax === 'number' && item.tax < 1 ? item.tax * 100 : item.tax };
            });

            return dispatch({
                type: INVOICE_FETCHED,
                payload: response
            });
        } catch (error) {
            toastr.error(error.data.message);
        }
    };
};

export const setAppointment = (appointmentId) => {
    return async (dispatch) => {
        try {
            const response = await getInvoiceDraft(appointmentId);

            // format tax or frontend view
            response.items = response.items.map((item) => {
                const servicePrice = getServicePrice(getLocationItem(item, response.clinic), response.soldBy);

                const defaultNetPrice = servicePrice.netPrice;
                const defaultGrossPrice = servicePrice.grossPrice;

                return {
                    ...item,
                    id: item?._id,
                    netPrice: defaultNetPrice,
                    listPrice: defaultNetPrice,
                    discount: item?.discount || 0,
                    tax: typeof item.tax === 'number' && item.tax < 1 ? item.tax * 100 : item.tax,
                    finalPrice: defaultGrossPrice
                };
            });

            return dispatch({
                type: SET_APPOINTMENT,
                payload: response
            });
        } catch (e) {
            toastr.error(e.data.message);
        }
    };
};

export const setInvoice = (invoice) => {
    return async (dispatch) => {
        return dispatch({
            type: INVOICE_FETCHED,
            payload: invoice
        });
    };
};

export const setCurrentInvoicePrice = (payload) => (dispatch) => {
    dispatch({
        type: SET_INVOICE_PRICE,
        payload: parseFloat(Number(payload).toFixed(2))
    });
};

export const setCurrentInvoiceDiscount = (payload) => (dispatch) => {
    dispatch({
        type: SET_INVOICE_DISCOUNT,
        payload
    });
};

export const setSelectedTemporaryDiscount = (payload) => (dispatch) => {
    dispatch({
        type: SET_TEMPORARY_DISCOUNT,
        payload
    });
};

export const addInvoiceItem = (data, invoiceId, commonDiscount) => {
    return async (dispatch) => {
        const addedItem = await addItemInvoice(invoiceId, data);
        UpdateXeroItem({ invoiceId, data, itemId: addedItem._id, commonDiscount }).then((value) => {
            if (value?.data?.statusCode) {
                toastr.error('Error on add a item to the Xero invoice');
            }
        });
        return dispatch(loadInvoiceItems(invoiceId));
    };
};

export const addMultipleInvoiceItems = (data, invoiceId) => {
    return async (dispatch) => {
        return Promise.all(
            data.map((item) => {
                return addItemInvoice(invoiceId, item);
            })
        ).then(() => {
            return dispatch(loadInvoiceItems(invoiceId));
        });
    };
};

export const addLinkedCourseItem = (course, services, invoiceId) => {
    const errorHandler = (error) => {
        switch (true) {
            case error.startsWith('Invalid card number'):
                return 'Card number is not correct';
            case error.startsWith('Invalid card expiration date'):
                return 'Expiry date is not correct';
            case error.startsWith('Card verification code check failed'):
                return ' CVV is not correct';
            case error.startsWith('Postal code check failed'):
                return 'Postal code is not correct';
            default:
                return error || 'Unhandled request';
        }
    };
    return async (dispatch) => {
        const response = await addItemInvoice(invoiceId, course);

        for (let i = 0; i <services.length; i++) {
            const service = services[i];
            const newService = {
                ...service,
                listPrice: 0,
                netPrice: 0,
                discount: 0,
                isRedemption: true,
                redemptionCourse: course.name,
                pricingBeforeRedemption: {
                    listPrice: service.listPrice,
                    netPrice: service.netPrice,
                    discount: service.discount
                },
                linkedInvoiceItem: response._id
            };

            try {
                await updateItem(newService);
            } catch (err) {
                let showMessage = true;
                if (typeof err === 'object') {
                    if (err.data && err.data.message) {
                        showMessage = false;
                        toastr.error(errorHandler(err.data.message));
                    }
                }
                if (showMessage) toastr.error('Something went wrong');
            }
        }
        return dispatch(loadInvoiceItems(invoiceId));
    };
};

export const updateInvoiceItem = (data, invoiceId) => {
    return async (dispatch) => {
        await updateItem(data);
        UpdateXeroItem({ invoiceId, data, updateDiscount: true }).then((value) => {
            if (value?.data?.statusCode) {
                toastr.error('Error on update the Xero invoice Item');
            }
        });
        return dispatch(loadInvoiceItems(invoiceId));
    };
};

export const removeInvoiceItem = (id, invoiceId, discount) => {
    return async (dispatch) => {
        UpdateXeroItem({ itemId: id, invoiceId, toRemove: true, commonDiscount: discount }).then((value) => {
            if (value?.data?.statusCode) {
                toastr.error('Error on Remove the Xero invoice Item');
            }
        });
        await removeById(id);
        return dispatch(loadInvoiceItems(invoiceId));
    };
};

export const setIsExchange = (isExchange) => async (dispatch) => {
    dispatch({
        type: SET_IS_EXCHANGE,
        payload: isExchange
    });
};
