import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import payment from './paymentReducer';
import customers from './customersReducer';
import currentCustomer from './currentCustomerReducer';
import practitioners from './practitionersReducer';
import services from './servicesReducer';
import invoice from './invoiceReducer';
import notifications from './notificationsReducer';
import taxes from './taxReducer';
import paymentsPayload from './paymentsPayloadReducer';
import selectedServices from './selectedServicesReducer';
import organisation from './organisationReducer';

export default combineReducers({
    toastr: toastrReducer,
    currentCustomer,
    payment,
    customers,
    practitioners,
    services,
    invoice,
    organisation,
    paymentsPayload,
    selectedServices,
    notifications,
    taxes
});
