import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '../Autocomplete';
import { useSelector } from 'react-redux';

import { submitButtonStyles, searchFieldStyles } from './styles';
import { FieldContainer, FieldLabel } from '.';

/**
 * SearchField
 */
const SearchFieldComponent = ({ onChange, label, options, classes, extra, value }) => (
    <FieldContainer styles={{ marginTop: '1px' }}>
        <FieldLabel>{label}</FieldLabel>
        <Grid item>
            <Autocomplete
                id={`${label}-autocomplete-field`}
                options={options}
                noOptionsText={'No results'}
                onChange={onChange}
                className={classes.root}
                value={value}
            />
        </Grid>
        {extra && extra()}
    </FieldContainer>
);

SearchFieldComponent.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    options: PropTypes.array,
    classes: PropTypes.object.isRequired,
    extra: PropTypes.func,
    value: PropTypes.any
};

export const SearchField = withStyles(searchFieldStyles)(SearchFieldComponent);

/**
 * SoldByField
 */
const SoldByFieldComponent = ({ value, onChange, classes }) => {
    const practitionersList =
        useSelector((store) =>
            store.practitioners.map((practitioner) => ({
                ...practitioner,
                label: practitioner.displayName,
                value: practitioner.displayName
            }))
        ) || [];

    const handleOnChange = (value) => {
        if (onChange) onChange(value);
    };

    return (
        <SearchField
            onChange={handleOnChange}
            label="Sold By *"
            options={practitionersList}
            value={value && value.displayName}
            extra={() => (
                <Grid item lg={3}>
                    <Button type="submit" size="medium" className={clsx(classes.btn, classes.blueBg)}>
                        <Typography>Add item</Typography>
                    </Button>
                </Grid>
            )}
        />
    );
};

SoldByFieldComponent.propTypes = {
    onChange: PropTypes.func,
    classes: PropTypes.object.isRequired,
    value: PropTypes.any
};

export const SoldByField = withStyles(submitButtonStyles)(SoldByFieldComponent);

/**
 * PerformedByField
 */
const PerformedByFieldComponent = ({ value, onChange, classes }) => {
    const practitionersList =
        useSelector((store) =>
            store.practitioners.map((practitioner) => ({
                ...practitioner,
                label: practitioner.displayName,
                value: practitioner.displayName
            }))
        ) || [];

    const handleOnChange = (value) => {
        if (onChange) onChange(value);
    };

    return (
        <SearchField
            onChange={handleOnChange}
            label="Performed By *"
            options={practitionersList}
            value={value && value.displayName}
            extra={() => (
                <Grid item lg={3}>
                    <Button type="submit" size="medium" className={clsx(classes.btn, classes.blueBg)}>
                        <Typography>Add item</Typography>
                    </Button>
                </Grid>
            )}
        />
    );
};

PerformedByFieldComponent.propTypes = {
    onChange: PropTypes.func,
    classes: PropTypes.object.isRequired,
    value: PropTypes.any
};

export const PerformedByField = withStyles(submitButtonStyles)(PerformedByFieldComponent);
