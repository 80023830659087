import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { toLocaleString, cutNumericValue } from '../../collums-components/helpers';
import { getLocationItem } from '../../collums-constants/utils/index';
import { INVOICE_ITEM_TYPES } from '../../collums-constants/index';

import { roundTwoDecimals } from '../../services/helpers';

import { saleInvoiceTableRowStyles as styles } from './styles';

import { IconButton, TableRow, TableCell, withStyles, TextField as MUITextField } from '@material-ui/core';
import { Autocomplete as MUIAutocomplete } from '@material-ui/lab';
import { Check, Close } from '@material-ui/icons';

import CloseBtn from '../common/CloseBtn';
import TextField from '../common/TextField';
import Select from '../common/Select';
import _ from 'lodash';

const SaleInvoiceTableRow = ({ item, index, editItem, clinic, removeItem, allTaxes, classes, invoiceStatus }) => {
    const [editedCell, setEditedCell] = useState(null);
    const [editedValue, setEditedValue] = useState(null);

    const { invoice } = useSelector((state) => state.invoice);
    const practitioners = useSelector(({ practitioners }) => practitioners);
    const services = useSelector(({ services }) => services);

    const isService = (() => {
        if (item.type === 'service') return true;
        return false;
    })();

    const moneyType = ['netPrice', 'discount', 'grossPrice', 'finalPrice', 'itemTotal', '_totalPrice'];
    const getItemPropertyValue = (property, callBack = () => {}) => {
        // Split string property into the . to obtain nested properties
        if (typeof item[property] === 'number') {
            if (property === 'netPrice') {
                // if (item?.locations?.length) {
                //     return getLocationItem(item, clinic)?.[property] * item.quantity;
                // }
                return item[property] * item.quantity;
            }
            if (property === 'grossPrice') {
                // if (item?.locations?.length) {
                //     return getLocationItem(item, clinic)?.[property];
                // }

                return item[property];
            }
            if (property === 'tax') {
                if (item?.locations?.length) {
                    // const itemTax = allTaxes.find(tax => tax.id === getLocationItem(item, clinic)?.[property]);
                    return {
                        value: item.tax.rate,
                        label: item.tax.name
                    };
                }
                return item[property];
            }
            return item[property];
        }

        if (!item[property]) {
            return callBack(property);
        }

        let splitProperty = property.split('.');

        if (splitProperty.length >= 1) {
            let currentPropValue = item;

            for (let prop of splitProperty) {
                const newValue = currentPropValue[prop];

                if (prop === splitProperty[splitProperty.length - 1]) return newValue;
                else currentPropValue = newValue;
            }
        }

        return item[property];
    };

    const closeCellEditing = (event) => {
        event.stopPropagation();
        setEditedCell(null);
        setEditedValue(null);
    };

    const renderEditableCell = (property, type, autocompleteOptions = [], callback) => {
        //if (property === 'itemTotal') return total();
        const value = (() => {
            if (editedValue || editedValue === 0 || editedValue === '') {
                return editedValue;
            }
            if (Number.isNaN(editedValue)) {
                return 0;
            }

            return getItemPropertyValue(property, callback);
        })();

        const taxOptions = [];
        if (item?.type?.toLowerCase() === 'service') {
            taxOptions.push(
                ...allTaxes
                    .filter((tax) => tax.items.services && tax.active)
                    .map((tax) => ({
                        label: tax.name,
                        value: tax.rate
                    }))
            );
        } else if (item?.type?.toLowerCase() === 'product') {
            taxOptions.push(
                ...allTaxes
                    .filter((tax) => tax.items.products && tax.active)
                    .map((tax) => ({
                        label: tax.name,
                        value: tax.rate
                    }))
            );
        } else {
            taxOptions.push(
                ...allTaxes.map((tax) => ({
                    label: tax.name,
                    value: tax.rate
                }))
            );
        }

        const IntegerOptions = new Array(20).fill(0).map((el, index) => {
            const current = index * 5;
            return {
                label: current,
                value: current
            };
        });

        const mapAutocompleteOptions = autocompleteOptions.map((option) => option);

        const getNumericValue = (value) => {
            if (!value) {
                if (value === '') {
                    return value;
                } else {
                    return 0;
                }
            }

            return value;
        };

        const inputMap = {
            text: <TextField className={classes.editCellInput} value={value} onChange={setEditedValue} />,
            number: (
                <TextField
                    className={classes.editCellInput}
                    value={getNumericValue(value)}
                    type="number"
                    style={{ width: 158 }}
                    onChange={(value) => {
                        if (
                            property === 'discount' &&
                            +value > roundTwoDecimals(item.netPrice * item.quantity + item.taxValue)
                        ) {
                            toastr.error('Error', 'Discounts cannot be higher than the gross price');
                            return;
                        }
                        if (+value < 0) {
                            toastr.error('Error', 'This value cannot be negative');
                            return;
                        }

                        if (value === '') {
                            setEditedValue('');
                            return;
                        }

                        setEditedValue(_.isEmpty(value) ? parseFloat(value) : cutNumericValue(value, 2));
                    }}
                />
            ),
            integer: (
                <Select
                    options={IntegerOptions}
                    value={typeof value === 'number' ? value.toString() : value}
                    onChange={setEditedValue}
                    className={classes.editCellInput}
                />
            ),
            tax: (
                <Select
                    options={taxOptions}
                    value={value}
                    onChange={(value) => {
                        setEditedValue(value);
                    }}
                    className={classes.editCellInput}
                />
            ),
            autocomplete: (
                <MUIAutocomplete
                    className={classes.editCellAutocomplete}
                    style={{ width: 300 }}
                    options={mapAutocompleteOptions}
                    getOptionLabel={(option) => option.displayName}
                    value={item.soldBy}
                    onChange={(e, value) => {
                        editItem(index, 'soldBy', value);
                    }}
                    clearOnBlur
                    renderInput={(params) => (
                        <MUITextField {...params} variant="outlined" className={classes.editCellInput} />
                    )}
                />
            )
        };

        if (property === editedCell) {
            const divStyle = type === 'number' ? { width: '300px' } : {};
            return (
                <div className={classes.editableCellWrapper} style={divStyle}>
                    {inputMap[type]}
                    <IconButton
                        className={classes.editCellBtn}
                        onClick={(event) => {
                            editItem(index, property, !value ? 0 : value);
                            closeCellEditing(event);
                        }}
                    >
                        <Check />
                    </IconButton>
                    <IconButton className={classes.editCellBtn} onClick={closeCellEditing}>
                        <Close />
                    </IconButton>
                </div>
            );
        }
        const propertyValue = getItemPropertyValue(property, callback);
        const itemPropertyValue =
            typeof propertyValue === 'string' && typeof propertyValue !== undefined
                ? parseFloat(item.appliableDiscountValue ? 'appliableDiscountValue' : propertyValue)
                : propertyValue;

        if (moneyType.includes(property)) {
            if (property === 'discount') {
                return toLocaleString(item.discount);
            }

            return toLocaleString(itemPropertyValue);
        }

        if (property === 'soldBy') {
            const field = (() => {
                if (propertyValue && propertyValue.displayName) return propertyValue.displayName;
                if (practitioners.find((practitioner) => practitioner.id === propertyValue))
                    return practitioners.find((practitioner) => practitioner.id === propertyValue).displayName;
                return 'online';
            })();
            return field;
        }

        if (property === 'tax') {
            if (item.type === INVOICE_ITEM_TYPES.ACCOUNT) {
                return allTaxes.find((tax) => tax.rate === item.tax)?.name + '%';
            }

            if (item?.locations?.length) {
                return allTaxes.find((tax) => tax.rate === getLocationItem(item, clinic).tax)?.rate + '%';
            }

            if (item.itemTax) {
                return allTaxes.find((tax) => tax.rate === item.itemTax.id)?.rate + '%';
            }
            return allTaxes.find((tax) => tax.rate === item.tax)?.rate + '%';
        }
        if (property === 'itemTotal') {
            return total();
        }

        return propertyValue || property;
    };

    const calculateGrossPrice = () => {
        if (INVOICE_ITEM_TYPES.ACCOUNT === item.type) {
            item.grossPrice = roundTwoDecimals(item.netPrice);
            return roundTwoDecimals(item.netPrice);
        }

        const tax = item.tax / 100;

        const grossPrice = (item.netPrice + roundTwoDecimals(item.netPrice * tax)) * item.quantity;
        item.grossPrice = grossPrice;
        return grossPrice;
    };

    useEffect(() => {
        if (editedCell === 'name') {
            const currentService = services.find((service) => service.name === item.name);

            if (!currentService) return;

            setEditedValue(currentService.name);
        }
        /*eslint-disable-next-line */
    }, [editedCell, services, item]);

    useEffect(() => {
        if (item.itemTax) {
            const tax = item.itemTax.rate / 100;

            if (!item.netPrice) {
                item.netPrice = item.listPrice || 0;
            }

            item.finalPrice =
                roundTwoDecimals(roundTwoDecimals(item.netPrice * (1 + tax)) * item.quantity - item.discount) || 0;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const isRedeemed = (item) => {
        if (item.isRedemption) return ' - Redeemed';
        return '';
    };

    const total = () => {
        let grossPrice = calculateGrossPrice();
        return `${toLocaleString(grossPrice - (item.discount ? cutNumericValue(item.discount, 2) : 0) || '0.00')}`;
    };

    // if (item.type === INVOICE_PAYMENT_TYPES.GIFTCARD)
    //     return (
    //         <TableRow>
    //             <TableCell className={classNames(classes.tableBodyCell, 'editable')}>
    //                 {item.soldBy?.displayName || item.practitioner?.displayName}
    //             </TableCell>
    //             <TableCell className={classNames(classes.tableBodyCell, 'editable')}>{item.name}</TableCell>
    //             <TableCell className={classNames(classes.tableBodyCell, 'editable')}>{item.quantity}</TableCell>
    //             <TableCell
    //                 className={classNames(classes.tableBodyCell, invoiceStatus !== 'Paid' ? 'editable' : '')}
    //                 onClick={() => {
    //                     setEditedCell('netPrice');
    //                 }}
    //                 align="right"
    //             >
    //                 {invoiceStatus !== 'Paid' && invoiceStatus !== 'Part paid' ? (
    //                     renderEditableCell('netPrice', 'number')
    //                 ) : (
    //                     <span>{toLocaleString(item ? roundTwoDecimals(item.netPrice * item.quantity) : 0)}</span>
    //                 )}
    //             </TableCell>
    //             <TableCell className={classNames(classes.tableBodyCell, 'editable')} align="right">
    //                 {item && allTaxes.find((tax) => tax.rate === 0).name + '%'}
    //             </TableCell>
    //             <TableCell className={classNames(classes.tableBodyCell, 'editable')} align="right">
    //                 {item && toLocaleString(calculateGrossPrice())}
    //             </TableCell>
    //             <TableCell className={classNames(classes.tableBodyCell, 'editable')} align="right">
    //                 {item && toLocaleString(roundTwoDecimals(item.editedDiscount || item.discount) || '0')}
    //             </TableCell>
    //             <TableCell className={classNames(classes.tableBodyCell, 'editable')} align="right">
    //                 {item && total()}
    //             </TableCell>
    //             <TableCell className={classNames(classes.tableBodyCell, classes.tableActionsColumns)}>
    //                 {(!invoice || invoice.paymentStatus === 'Unpaid') && <CloseBtn onClick={() => removeItem(index)} />}
    //             </TableCell>
    //         </TableRow>
    //     );

    return (
        <TableRow hover tabIndex={-1}>
            {item && (
                <TableCell
                    className={classNames(classes.tableBodyCell, invoiceStatus !== 'Paid' ? 'editable' : '')}
                    onClick={() => {
                        setEditedCell('soldBy');
                    }}
                >
                    {invoiceStatus !== 'Paid' ? (
                        renderEditableCell('soldBy', 'autocomplete', practitioners)
                    ) : (
                        <span>{item && item.soldBy?.displayName}</span>
                    )}
                </TableCell>
            )}

            <TableCell
                className={classNames(classes.tableBodyCell, invoiceStatus !== 'Paid' && isService ? 'editable' : '')}
                onClick={() => (isService ? setEditedCell('name') : null)}
            >
                <span>
                    {(item && `${item.name} ${isRedeemed(item)}`) ||
                        (item && item.item && `${item.item.name} ${isRedeemed(item.item)}`)}
                </span>
            </TableCell>
            <TableCell
                className={classNames(classes.tableBodyCell, invoiceStatus !== 'Paid' ? 'editable' : '')}
                onClick={() => setEditedCell('quantity')}
            >
                {invoiceStatus !== 'Paid' ? (
                    renderEditableCell('quantity', 'number')
                ) : (
                    <span>{item && item.quantity}</span>
                )}
            </TableCell>

            <TableCell
                className={classNames(classes.tableBodyCell, invoiceStatus !== 'Paid' && invoiceStatus !== 'Part paid' ? 'editable' : '')}
                onClick={() => {
                    setEditedCell('netPrice');
                }}
                align="right"
            >
                {invoiceStatus !== 'Paid' && invoiceStatus !== 'Part paid' ? (
                    renderEditableCell('netPrice', 'number')
                ) : (
                    <span>{toLocaleString(item ? roundTwoDecimals(item.netPrice * item.quantity) : 0)}</span>
                )}
            </TableCell>

            <TableCell
                className={classNames(classes.tableBodyCell, invoiceStatus !== 'Paid' && invoiceStatus !== 'Part paid' && item.type !== 'Account' ? 'editable' : '')}
                onClick={() => setEditedCell('tax')}
                align="right"
            >
                {invoiceStatus !== 'Paid' && item.type !== 'Account' && invoiceStatus !== 'Part paid' ? (
                    <span>{renderEditableCell('tax', 'tax')}</span>
                ) : (
                    <span>{`${item.tax}%`}</span>
                )}
            </TableCell>

            <TableCell
                className={classNames(classes.tableBodyCell, invoiceStatus !== 'Paid' && invoiceStatus !== 'Part paid' ? 'editable' : '')}
                align="right"
                onClick={() => {
                    setEditedCell('grossPrice');
                }}
            >
                {invoiceStatus !== 'Paid' && invoiceStatus !== 'Part paid' ? (
                    <span>{renderEditableCell('grossPrice', 'number', [], calculateGrossPrice)}</span>
                ) : (
                    <span>{toLocaleString(item && (item.grossPrice || calculateGrossPrice()))}</span>
                )}
            </TableCell>

            <TableCell
                className={classNames(classes.tableBodyCell, invoiceStatus !== 'Paid' && invoiceStatus !== 'Part paid'  ? 'editable' : '')}
                onClick={() => setEditedCell('discount')}
                align="right"
            >
                {invoiceStatus !== 'Paid' && invoiceStatus !== 'Part paid' ? (
                    <span>{renderEditableCell('discount', 'number')}</span>
                ) : (
                    <span>{toLocaleString(item && roundTwoDecimals(item.discount))}</span>
                )}
            </TableCell>

            <TableCell
                className={classNames(classes.tableBodyCell)}
                onClick={() => {
                    setEditedCell('_totalPrice');
                }}
                align="right"
            >
                {invoiceStatus !== 'Paid' ? (
                    <span>{total()}</span>
                ) : (
                    <span>{toLocaleString(item && item._totalPrice)}</span>
                )}
            </TableCell>
            <TableCell className={classNames(classes.tableBodyCell, classes.tableActionsColumns)}>
                {(!invoice || invoice.paymentStatus === 'Unpaid') && <CloseBtn onClick={() => removeItem(index)} />}
            </TableCell>
        </TableRow>
    );
};

SaleInvoiceTableRow.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    editItem: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    isFromCalendar: PropTypes.bool,
    finalPrice: PropTypes.number,
    invoiceStatus: PropTypes.string,
    allTaxes: PropTypes.array.isRequired,
    clinic: PropTypes.string.isRequired
};

export default withStyles(styles)(SaleInvoiceTableRow);
