import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { isEqual } from 'lodash';

import {
    INVOICE_ORIGIN,
    INVOICE_PAYMENT_TYPES,
    TEMPORARY_DISCOUNTS,
    INVOICE_PAYMENT_STATUS
} from '../../collums-constants/index';

import { saleViewStyles as styles } from './styles';

import { calculateTaxValue } from '../../services/invoice';
import { capitalizeFirstLetter, roundTwoDecimals } from '../../services/helpers';
import { CURRENT_CLINIC } from '../../collums-constants/storageKeys';

import SaleHeader from './SaleHeader';
import SaleInvoiceTable from './SaleInvoiceTable';
import SalePayment from './SalePayment';
import SaleInvoiceNote from './SaleInvoiceNote';
import SaleInvoiceDiscount from './SaleInvoiceDiscount';
import SaleInvoiceSummary from './SaleInvoiceSummary';
import AddInvoiceItemTab from './AddInvoiceItemTab';
import SaveDraftButton from './SaveDraftButton';
import PaymentTable from './PaymentTable';

import { PaidStamp } from '../../assets/icons';
import { Typography, withStyles } from '@material-ui/core';
import Button from '../common/Button';

import TaxesApi from '../../collums-components/api/TaxesApi';
import GiftcardApi from '../../api/GiftcardApi';
import { UpdateXeroItem } from '../../api/xeroApi';

import { getManyTempDiscountsById } from '../../api/temporaryDiscountApi';
import { updateInvoice, updateItem, updateInvoiceItems, getInvoicePaymentStatus } from '../../api/invoiceApi';
import { getProduct } from '../../api/productHistory';

import { setCurrentCustomer } from '../../redux/actions/currentCustomerActions';
import {
    loadInvoiceItems,
    addInvoiceItem,
    addMultipleInvoiceItems,
    removeInvoiceItem,
    updateInvoiceItem,
    setSelectedTemporaryDiscount,
    setCurrentInvoiceDiscount,
    setInvoice,
    setAppointment
} from '../../redux/actions/invoiceActions';

import { getLocationItem } from '../../collums-constants/utils';
import { setTaxesPayload } from '../../redux/actions/taxActions';
import { useHistory } from 'react-router-dom';
import { setOrganisation } from '../../redux/actions/organisationActions';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';

const discountUnits = {
    currency: 'currency',
    percentage: 'percentage'
};

const initialDiscountState = {
    unit: discountUnits.percentage,
    value: 0,
    itemsToApply: []
};

function SaleView({ classes }) {
    const dispatch = useDispatch();
    const tipValue = 0;
    const history = useHistory();
    const [invoiceId, setInvoiceId] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [amount, setAmount] = useState(0);
    const [items, setItems] = useState([]);
    const [itemsLoaded, setItemsLoaded] = useState(false);
    const [isLoadingInvoice, setIsLoadingInvoice] = useState(true);
    const [invoiceNote, setInvoiceNote] = useState('');
    const [discount, updateDiscount] = useState(initialDiscountState);
    const [invoiceValue, setInvoiceValue] = useState(0);
    const [total, setTotal] = useState(0);
    const [discountValue, setDiscountValue] = useState(0);
    const [allTaxes, setAllTaxes] = useState([]);
    const wasPaid = useRef(false);
    const shouldApplyDiscount = useRef(true);
    const [preInvoiceDiscounts, setPreInvoiceDiscounts] = useState([]);
    const customerId = useSelector((state) => state.currentCustomer.customerId);
    const organisation = useSelector((state) => state.organisation.organisation);
    const invoiceState = useSelector((state) => state.invoice);
    const invoiceUrl = new URL(window.location.href).searchParams.get('invoice');
    const appointmentUrl = new URL(window.location.href).searchParams.get('appointment');
    const currentClinic = localStorage.getItem(CURRENT_CLINIC);
    const [isLoading, setIsLoading] = useState(false);
    const shouldRenderPaymentsTable = (() => {
        if (!invoiceState.invoice) return false;
        return (
            invoiceState.invoice.paymentStatus === INVOICE_PAYMENT_STATUS.PAID ||
            invoiceState.invoice.paymentStatus === INVOICE_PAYMENT_STATUS.PART_PAID
        );
    })();

    const pastDiscountValue = useRef();
    const oldItemsLength = useRef(items.length || 0);

    const setDiscount = async (newDiscount, productChange = false) => {
        const canChange = await validateInvoiceChange();
        if (!canChange) return;

        if (!productChange && Object.prototype.hasOwnProperty.call(newDiscount, 'productChange')) {
            delete newDiscount.productChange;
        }

        pastDiscountValue.current = discount;
        UpdateXeroItem({ invoiceId, updateDiscount: !!newDiscount, commonDiscount: newDiscount }).then((value) => {
            if (value?.data?.statusCode) {
                toastr.error('Error on update the Xero Discount');
            }
        });
        updateDiscount(newDiscount);
    };

    const handleCancelUpdates = () => {
        if (appointmentUrl) {
            history.push('/sale');
        }

        window.location.reload();
    };

    const setCustomerId = (id) => {
        dispatch(setCurrentCustomer(id));
    };

    /**
     * It checks has payment status changed and is current payment status updated, if not refresh page.
     *
     * @returns {Promise<boolean>}
     */
    const validateInvoiceChange = async (forceChange = false) => {
        if (!customerId || !invoiceUrl) {
            return true;
        }

        if (forceChange) {
            return true; // do not check payment status as it can be saved during payment.
        }

        const isPayed = (status) => {
            return status === INVOICE_PAYMENT_STATUS.PAID || status === INVOICE_PAYMENT_STATUS.PART_PAID;
        };

        const status = await getInvoicePaymentStatus(invoiceState.invoice.id);

        // Current invoice status is not same as in DB refresh page.
        if (status !== invoiceState.invoice.paymentStatus && isPayed(status)) {
            window.location.reload();
            return false;
        }

        return true;
    };

    useEffect(() => {
        if (invoiceState?.invoice?.noXero) {
            toastr.error('Error on Get the invoice on Xero');
        }
        // eslint-disable-next-line
    }, [invoiceState?.invoice?.noXero]);

    useEffect(() => {
        if (!customerId) {
            setInvoiceId(null);
            setInvoice(null);
            setItems([]);
            setInvoiceNote('');
            setInvoiceValue(0);
        }
        TaxesApi.listTaxes({ archived: true, active: false }).then((res) => {
            setAllTaxes(res);
            dispatch(setTaxesPayload(res));
        });
    }, [customerId, dispatch]);

    useEffect(() => {
        (async () => {
            if (itemsLoaded) {
                const newTemporaryDiscounts = await getManyTempDiscountsById(
                    items.map((item) => item.referenceId || item.id).join(','),
                    currentClinic
                );
                setPreInvoiceDiscounts(newTemporaryDiscounts);
            }
        })();
    }, [itemsLoaded, discount, items, invoiceState.invoice, currentClinic]);

    useEffect(() => {
        const amountValue = items.reduce((acc, item) => acc + item.grossPrice - item.discount, 0);
        setAmount(amountValue);
        setInvoiceValue(amountValue);
    }, [items]);

    const formatTemporaryDiscount = (temporaryDiscount) => {
        if (!temporaryDiscount) return {};
        const { discountType, discount, applyTo, eligableItems, discountValid } = temporaryDiscount;
        return {
            label: temporaryDiscount.name,
            value: temporaryDiscount.id,
            discountType,
            discount,
            applyTo,
            eligableItems,
            discountValid
        };
    };

    useEffect(() => {
        if (invoiceState.invoice) {
            setInvoiceValue(invoiceState.invoice.amount);
            return;
        }
        const reduceItems = items.reduce((acc, currValue) => (acc += currValue.grossPrice - currValue.discount), 0);
        setInvoiceValue(reduceItems);
    }, [invoiceState.invoice, items]);

    useEffect(() => {
        async function loadDefaultValues() {
            if (invoiceState.invoice && !wasPaid.current) {
                const temporaryDiscount = formatTemporaryDiscount(invoiceState.invoice.temporaryDiscount);

                dispatch(setSelectedTemporaryDiscount(temporaryDiscount));
                const itemsToApply = (() => {
                    if (temporaryDiscount.eligableItems && temporaryDiscount.eligableItems.length)
                        return temporaryDiscount.eligableItems.map((item) => item.item);
                    return [];
                })();
                const unit = (() => {
                    if (temporaryDiscount.discountType === TEMPORARY_DISCOUNTS.DISCOUNT_TYPES.PERCENTAGE)
                        return discountUnits.percentage;
                    return discountUnits.currency;
                })();
                const value = (() => {
                    if (temporaryDiscount.discount) return temporaryDiscount.discount;
                    return invoiceState.invoice.discount;
                })();

                setItems(invoiceState.invoice.items);
                setItemsLoaded(true);
                if (isLoadingInvoice) {
                    oldItemsLength.current = invoiceState.invoice.items.length;
                }
                if (
                    temporaryDiscount &&
                    !Object.keys(temporaryDiscount).length &&
                    invoiceState.invoice.discountProperties
                ) {
                    const newDiscount = {
                        unit: invoiceState.invoice.discountProperties.type || 'percentage',
                        value: invoiceState.invoice.discountProperties.value,
                        itemsToApply: []
                    };
                    updateDiscount(newDiscount);
                } else {
                    updateDiscount({ unit, value, itemsToApply });
                }
            }
            setIsLoadingInvoice(false);
        }

        loadDefaultValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsLoaded, dispatch, invoiceState.invoice]);

    useEffect(() => {
        const invoiceId = new URL(window.location.href).searchParams.get('invoice');
        const appointmentId = new URL(window.location.href).searchParams.get('appointment');
        if (!invoiceId) {
            setItemsLoaded(true);
        }
        if (invoiceId) {
            dispatch(loadInvoiceItems(invoiceId));
            setInvoiceId(invoiceId);
        } else if (appointmentId) {
            dispatch(setAppointment(appointmentId)).then((data) => {
                setItems(data?.payload?.items);
            });
        }
    }, [dispatch]);

    useEffect(() => {
        async function applyDiscount() {
            if (!items.length) return;

            items.forEach((item) => {
                if (isNaN(item.discount)) {
                    item.discount = 0;
                }
            });
            const getTotalDiscount = async (invoiceTotal) => {
                if (discount.unit === discountUnits.percentage) {
                    const totalDiscount = +(invoiceTotal * (discount.value / 100)).toFixed(3);
                    if (invoiceId)
                        await updateInvoice(invoiceId, {
                            discount: totalDiscount,
                            discountProperties: { value: discount.value, type: 'percentage' }
                        });
                    dispatch(setCurrentInvoiceDiscount(totalDiscount));
                    return totalDiscount;
                }
                if (invoiceId) {
                    await updateInvoice(invoiceId, {
                        discount: +discount.value ? discount.value.toFixed(3) : 0,
                        discountProperties: { value: discount.value, type: 'currency' }
                    });
                }

                dispatch(setCurrentInvoiceDiscount(discount.value));
                return discount.value ? +discount.value.toFixed(3) : 0;
            };

            const getInvoiceTotal = (itemsToDiscount) => {
                return itemsToDiscount.reduce((acc, current) => {
                    const grossPrice = current.netPrice * current.quantity + current.taxValue;
                    return acc + grossPrice;
                }, 0);
            };

            if (discount.itemsToApply.length) {
                const filteredItems = items.filter((item) =>
                    discount.itemsToApply.includes(item.referenceId || item.id)
                );
                const invoiceTotal = getInvoiceTotal(filteredItems);
                const totalDiscount = await getTotalDiscount(invoiceTotal);

                const newItems = await Promise.all(
                    items.map(async (item) => {
                        if (discount.itemsToApply.includes(item.referenceId || item.id)) {
                            const newItemDiscount = (() => {
                                if (!invoiceTotal && !totalDiscount) return 0;

                                const x = isNaN(item.discount) ? 0 : item.discount;
                                const y = item.grossPrice - (item.discount || 0);

                                return +(((y + x) * totalDiscount) / invoiceTotal).toFixed(3);
                            })();
                            const data = { ...item, discount: newItemDiscount, isTempDiscountApplied: true };
                            if (invoiceId) await updateItem(data);
                            return data;
                        }
                        const data = { ...item, discount: 0 };
                        if (invoiceId) await updateItem(data);
                        return data;
                    })
                );
                setItems(newItems);
                return;
            }
            const invoiceTotal = getInvoiceTotal(items);
            const totalDiscount = await getTotalDiscount(invoiceTotal);

            // Get discount amount for item
            const getItemDiscount = (item) => {
                if (!invoiceTotal && !totalDiscount) {
                    return 0;
                }
                const grossPrice = item.netPrice * item.quantity + item.taxValue;
                return roundTwoDecimals((grossPrice * totalDiscount) / invoiceTotal);
                // return +((grossPrice * totalDiscount) / invoiceTotal).toFixed(2);
            };

            // Sum items discount
            const itemsSumDiscount = roundTwoDecimals(
                items?.reduce((acc, current) => {
                    return acc + getItemDiscount(current) ?? 0;
                }, 0)
            );

            const discountDiffController = roundTwoDecimals(totalDiscount - itemsSumDiscount);
            let itemToCorrect = null;
            let valueToCorrect = null;

            // If sum of discounts (per item) is not same as total discount.
            if (discountDiffController !== 0) {
                // Get first not free item index.
                const notFreeItem = items.findIndex((item) => {
                    const grossPrice = item.netPrice * item.quantity + item.taxValue;
                    return grossPrice > 0;
                });

                // Set item and value to add discount diff.
                if (notFreeItem !== -1) {
                    itemToCorrect = notFreeItem;
                    valueToCorrect = discountDiffController;
                }
            }

            const newItems = await Promise.all(
                items.map(async (item, key) => {
                    let newItemDiscount = getItemDiscount(item);

                    // Add the missing amount to the product
                    if (itemToCorrect !== null && valueToCorrect !== null && key === itemToCorrect) {
                        newItemDiscount += valueToCorrect;
                    }

                    const data = {
                        ...item,
                        discount: roundTwoDecimals(newItemDiscount),
                        isTempDiscountApplied: !invoiceState.selectedDiscount.value
                    };

                    if (invoiceId) {
                        await updateItem({
                            ...data,
                            tax: item.tax > 1 ? item.tax / 100 : item.tax
                        });
                    }
                    if (!data.discount && data.discount !== 0 && item.discount) {
                        data.discount = item.discount;
                    }
                    return data;
                })
            );
            setItems(newItems);
        }

        const updateAndClearInvoiceDiscount = async () => {
            if (invoiceId) {
                await updateInvoice(invoiceId, {
                    discount: 0,
                    discountProperties: { value: 0, type: 'currency' },
                    temporaryDiscount: null
                });
            }

            dispatch(setCurrentInvoiceDiscount(0));
        };

        if (pastDiscountValue.current?.eligableItems && !pastDiscountValue.current.eligableItems?.length) {
            delete pastDiscountValue.current.eligableItems;
        }
        const discCompared = {
            ...discount
        };

        if (discCompared.eligableItems && !discCompared.eligableItems.length) {
            delete discCompared.eligableItems;
        }

        const isDifferentDiscountLogic = !isEqual(pastDiscountValue.current, discCompared);
        const wasAddedOrRemovedItems = items.length !== oldItemsLength.current;

        oldItemsLength.current = items.length;

        const canRecalculate = wasAddedOrRemovedItems && discount.value && !discount.itemsToApply?.length;

        if (
            pastDiscountValue.current &&
            (isDifferentDiscountLogic || canRecalculate) &&
            !isLoadingInvoice &&
            shouldApplyDiscount.current
        ) {
            pastDiscountValue.current = discount;
            applyDiscount();
            return;
        } else if (!shouldApplyDiscount.current && discount.productChange && isDifferentDiscountLogic) {
            updateAndClearInvoiceDiscount();
            dispatch(setSelectedTemporaryDiscount(false));

            updateDiscount((discount) => {
                if (Object.prototype.hasOwnProperty.call(discount, 'productChange')) {
                    delete discount.productChange;
                    shouldApplyDiscount.current = false;
                }

                return discount;
            });
        }
        shouldApplyDiscount.current = true;
        if (!isEqual(discount, initialDiscountState)) {
            pastDiscountValue.current = discount;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items.length, discount]);

    const removePromotionDiscount = async () => {
        const canChange = await validateInvoiceChange();
        if (!canChange) return;

        const invoiceTotal = items
            .filter((item) => {
                return discount.itemsToApply.includes(item.referenceId || item.id) || !discount.itemsToApply.length;
            })
            .reduce((counter, item) => counter + item.finalPrice + (item.discount || 0), 0);

        const isInTolerance = (value1, value2) => {
            return value1 === value2 || value1 + 0.001 === value2 || value1 - 0.001 === value2;
        };

        const newItems = await Promise.all(
            items.map(async (item) => {
                if (discount.itemsToApply.includes(item.referenceId || item.id) || !discount.itemsToApply.length) {
                    const oldDiscount = (item.discount || 0).toFixed(3);
                    const expectedDiscount = (() => {
                        if (discount.unit === discountUnits.currency) {
                            return (
                                ((item.finalPrice + (item.discount || 0)) * (discount.value || 0)) /
                                invoiceTotal
                            ).toFixed(3);
                        }
                        return ((item.netPrice + item.taxValue) * ((discount.value || 0) / 100)).toFixed(3);
                    })();
                    if (
                        expectedDiscount === oldDiscount ||
                        isInTolerance(Number(expectedDiscount), Number(oldDiscount))
                    ) {
                        const data = { ...item, discount: 0, isTempDiscountApplied: false };
                        if (invoiceId) await updateItem(data);
                        return data;
                    }
                }
                return item;
            })
        );

        setDiscount({
            value: null,
            itemsToApply: [],
            unit: discountUnits.currency
        });

        dispatch(setSelectedTemporaryDiscount(false));
        if (invoiceId) {
            await updateInvoice(invoiceId, {
                temporaryDiscount: null,
                discountProperties: { value: 0, type: 'percentage' }
            });
        }

        setItems(newItems);
    };

    const handleUpdateItem = async (data, index) => {
        if (!invoiceState.invoice || invoiceState.invoice.paymentStatus === INVOICE_PAYMENT_STATUS.UNPAID) {
            if (invoiceId) {
                const canChange = await validateInvoiceChange();
                if (!canChange) return;

                dispatch(updateInvoiceItem(data, invoiceId, discount));
            } else {
                const newItems = items.map((item, itemIndex) => {
                    if (itemIndex === index)
                        return {
                            ...data
                        };
                    return item;
                });

                setItems(newItems);
            }
        } else {
            const canChange = await validateInvoiceChange();
            if (!canChange) return;

            handleChangeOnItems(data, index);
        }
    };

    const handleAddInvoiceItem = async (data, isLoadingFunction) => {
        const canChange = await validateInvoiceChange();
        if (!canChange) return;

        const tax = data.tax;

        const commonDiscount = (() => {
            if (!discount.itemsToApply.length) {
                return discount;
            }
            if ((discount.itemsToApply || []).includes(data.id)) {
                if (discount.unit === 'percentage') {
                    return data.finalPrice * (discount.value / 100);
                }
                return discount;
            }
            return 0;
        })();

        const setNextVoucherCode = () => {
            if (
                data?.type &&
                data.type === INVOICE_PAYMENT_TYPES.GIFTCARD &&
                organisation &&
                organisation?.nextVoucherCode &&
                data.code === organisation?.nextVoucherCode.toString()
            ) {
                const organisationCopy = { ...organisation };
                organisationCopy.nextVoucherCode = organisationCopy.nextVoucherCode + 1;
                dispatch(setOrganisation(organisationCopy));
            }
        };

        if (invoiceUrl) {
            const item = {
                referenceId: data.id,
                discount: commonDiscount.value,
                invoice: invoiceUrl,
                type: capitalizeFirstLetter(data.type || data.item.type),
                tax: tax / 100,
                listPrice: data.listPrice,
                finalPrice: data.finalPrice,
                quantity: data.quantity,
                name: data.name,
                netPrice: data.netPrice,
                origin: INVOICE_ORIGIN.SALE,
                soldBy: data.practitioner.id
            };

            if (data.type === INVOICE_PAYMENT_TYPES.GIFTCARD) {
                const newGiftcard = {
                    code: data.code,
                    purchaser: invoiceState.invoice.customer,
                    value: data.netPrice,
                    universalUse: data.universalUse
                };

                const gift = await GiftcardApi.create(newGiftcard);
                item.referenceId = gift.id;

                setNextVoucherCode();
            }
            await dispatch(addInvoiceItem(item, invoiceUrl, commonDiscount));
            if (isLoadingFunction) {
                isLoadingFunction(false);
            }
        } else {
            if (data.type === INVOICE_PAYMENT_TYPES.GIFTCARD) {
                const hasCode = items.some((item) => {
                    return item?.type === INVOICE_PAYMENT_TYPES.GIFTCARD && item.code === data.code;
                });

                if (hasCode) {
                    return toastr.error('This code is already on list');
                } else {
                    setNextVoucherCode();
                }
            }

            setItems([...items, data]);
            if (isLoadingFunction) {
                isLoadingFunction(false);
            }
        }
    };

    const handleAddMultipleProducts = async (data) => {
        const canChange = await validateInvoiceChange();
        if (!canChange) return;

        data = await Promise.all(
            data.map(async (product) => {
                if (product.addedOn && product.addedOn === 'saleHistory') {
                    const productFromApi = await getProduct(product.referenceId);
                    return {
                        ...productFromApi,
                        netPrice: product.netPrice,
                        addQuantity: product.addQuantity,
                        soldBy: product.soldBy,
                        referenceId: product.referenceId,
                        discount: product.discount,
                        isFromHistory: true
                    };
                }
                return product;
            })
        );
        if (invoiceUrl) {
            const newItems = data.map((product) => {
                const localItem = getLocationItem(product, currentClinic);
                const tax = allTaxes.find((tax) => tax.id === localItem.tax);

                return {
                    referenceId: product.referenceId,
                    netPrice: product.isFromHistory ? product.netPrice : Number(localItem.netPrice),
                    type: 'Product',
                    name: product.name,
                    listPrice: Number(localItem.netPrice),
                    soldBy: product.soldBy.id,
                    quantity: parseInt(product.addQuantity),
                    // it is final price per product
                    finalPrice: roundTwoDecimals(calculateTaxValue(localItem.netPrice, tax.rate)),
                    discount: Number(product.discount) ? product.discount : 0,
                    tax: tax.rate / 100,
                    origin: 'SALE',
                    invoice: invoiceUrl
                };
            });
            dispatch(addMultipleInvoiceItems(newItems, invoiceUrl));
        } else {
            const newItems = data.map((product) => {
                const localItem = getLocationItem(product, currentClinic);
                const tax = allTaxes.find((tax) => tax.id === localItem.tax);
                return {
                    id: product.referenceId,
                    netPrice: product.isFromHistory ? product.netPrice : Number(localItem.netPrice),
                    type: 'Product',
                    name: localItem.name || product.name,
                    listPrice: localItem.netPrice,
                    soldBy: product.soldBy,
                    quantity: parseInt(product.addQuantity),
                    finalPrice: localItem.grossPrice,
                    discount: product.discount ? product.discount : 0,
                    tax: tax.rate,
                    taxValue: calculateTaxValue(product.netPrice, tax)
                };
            });
            setItems([...newItems, ...items]);
        }
    };

    const handleDeleteItem = async (index) => {
        setIsLoading(true);
        const canChange = await validateInvoiceChange();
        if (!canChange) return;

        if (invoiceId) {
            const isCourse = items[index].type === 'Course' ? items[index].id : undefined;

            if (isCourse) {
                const service = items.find((item) => item.type === 'Service' && item.linkedInvoiceItem === isCourse);
                if (service) {
                    const newService = {
                        ...service,
                        listPrice: service.pricingBeforeRedemption.listPrice,
                        netPrice: service.pricingBeforeRedemption.netPrice,
                        discount: service.pricingBeforeRedemption.discount,
                        linkedInvoiceItem: undefined
                    };

                    await dispatch(updateInvoiceItem(newService, invoiceId, discount));
                }
            }

            await dispatch(removeInvoiceItem(items[index].id, invoiceId, discount));
        } else {
            const invoiceItemsCopy = [...items];
            invoiceItemsCopy.splice(index, 1);

            setItems(invoiceItemsCopy);
        }
        setIsLoading(false);
    };

    let totalPrice = 0;
    items.forEach((el) => (totalPrice += el.finalPrice || 0));

    const handleChangeOnItems = async (data, index) => {
        const canChange = await validateInvoiceChange();
        if (!canChange) return;

        const oldItem = items[index];

        data.soldBy = data.practitioner || data.soldBy;

        if (!data.soldBy) {
            toastr.warning('Sold By cannot be empty');
            return;
        }

        const verifyItemChange = () => {
            const objKeys = Object.keys(data);
            let haveInconsistency = false;

            objKeys.forEach((element) => {
                if (element === 'soldBy' || element === 'practitioner') return;
                else if (data[element] !== oldItem[element]) {
                    if (typeof data[element] === 'object' && typeof oldItem[element] === 'object') {
                        if (!isEqual(data[element], oldItem[element])) haveInconsistency = true;
                    } else haveInconsistency = true;
                }
            });

            return haveInconsistency;
        };

        if (verifyItemChange()) {
            toastr.error('Can only change Sold By');
            return;
        }

        dispatch(updateInvoiceItem(data, invoiceId, discount));
    };

    const modifyInvoice = async ({ takingPayment = false, invoiceId = null }) => {
        const canChange = await validateInvoiceChange(true);
        if (!canChange) return;

        if (!invoiceState.invoice?.id && !invoiceId) {
            // toastr.error("Doesn't have an invoice");
            return;
        }
        if (!invoiceNote) {
            if (!takingPayment) {
                toastr.error('You must enter invoice notes to save changes');
            }
            return;
        }
        try {
            await updateInvoiceItems(invoiceState.invoice?.id || invoiceId, {
                invoiceItems: [],
                newInvoiceNote: invoiceNote
            });

            if (!takingPayment) dispatch(loadInvoiceItems(invoiceUrl));

            if (!takingPayment) toastr.success('Invoice successfully updated');

            setInvoiceNote('');
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    };

    return (
        (itemsLoaded || !invoiceUrl) && (
            <div className={classes.container}>
                <div className={classes.leftContainer}>
                    <SaleHeader customerId={customerId} onCustomerChange={setCustomerId} />
                    <SaleInvoiceTable
                        clinic={currentClinic}
                        allTaxes={allTaxes}
                        customerId={customerId}
                        invoiceItems={items || []}
                        handleUpdateItem={handleUpdateItem}
                        handleDeleteItem={handleDeleteItem}
                        invoiceStatus={invoiceState.invoice?.paymentStatus}
                        setDiscount={setDiscount}
                        shouldApplyDiscount={shouldApplyDiscount}
                        discountUnits={discountUnits}
                    />
                    <div className={classes.row}>
                        <SaleInvoiceNote
                            showSavedNote={invoiceState.invoice?.description}
                            customerId={customerId}
                            invoiceNote={invoiceNote}
                            setInvoiceNote={setInvoiceNote}
                            lastSavedNote={
                                invoiceState.invoice?.modifies
                                    ? invoiceState.invoice.modifies[invoiceState.invoice.modifies.length - 1]
                                          ?.description
                                    : ''
                            }
                            defaultPlaceholder={'Enter invoice notes'}
                            invoiceStatus={invoiceState.invoice?.paymentStatus}
                        />
                        <SaleInvoiceDiscount
                            preInvoiceDiscounts={preInvoiceDiscounts}
                            invoice={invoiceState.invoice}
                            invoiceItems={items}
                            handleUpdateItem={handleUpdateItem}
                            customerId={customerId}
                            discount={discount}
                            setDiscount={setDiscount}
                            discountUnits={discountUnits}
                            formatTemporaryDiscount={formatTemporaryDiscount}
                            discountValue={discountValue}
                            setDiscountValue={setDiscountValue}
                            shouldApplyDiscount={shouldApplyDiscount}
                            setIsProcessing={setIsProcessing}
                            isProcessing={isProcessing}
                            total={total}
                            removePromotionDiscount={removePromotionDiscount}
                            validateInvoiceChange={validateInvoiceChange}
                        />
                        <SaleInvoiceSummary
                            allTaxes={allTaxes}
                            customerId={customerId}
                            invoiceItem={items}
                            discount={discount}
                            total={total}
                            discountValue={discountValue}
                            setDiscountValue={setDiscountValue}
                            setTotal={setTotal}
                            paymentStatus={invoiceState.invoice ? invoiceState.invoice.paymentStatus : undefined}
                        />
                    </div>
                    {!invoiceUrl ? (
                        <div className={classnames(classes.row, classes.alignLeft)}>
                            <Button
                                type="secondary"
                                variant="outlined"
                                className={classnames(classes.btn, classes.whiteBtn)}
                                onClick={handleCancelUpdates}
                            >
                                Cancel
                            </Button>
                            <SaveDraftButton
                                customerId={customerId}
                                clinic={currentClinic}
                                invoiceItems={items}
                                origin={INVOICE_ORIGIN.SALE}
                                amount={invoiceValue || amount}
                                discount={discount}
                                description={invoiceNote}
                                allTaxes={allTaxes}
                            />
                        </div>
                    ) : (
                        <div className={classnames(classes.row, classes.paymentsTable)}>
                            {shouldRenderPaymentsTable ? <PaymentTable /> : <div></div>}
                            {invoiceState.invoice?.paymentStatus === 'Refund' && (
                                <Typography className={classes.invoicePaymentStatusText}>Refunded</Typography>
                            )}
                            {invoiceState.invoice?.paymentStatus === 'Void' && (
                                <Typography className={classes.invoicePaymentStatusText}>Voided</Typography>
                            )}
                            {invoiceState.invoice?.paymentStatus === 'Paid' && <PaidStamp />}
                        </div>
                    )}
                    {(!invoiceState.invoice ||
                        invoiceState.invoice.paymentStatus === INVOICE_PAYMENT_STATUS.UNPAID) && (
                        <div className={classes.row}>
                            <AddInvoiceItemTab
                                clinic={currentClinic}
                                allTaxes={allTaxes}
                                customerId={customerId}
                                handleUpdateItem={handleUpdateItem}
                                invoiceItems={items}
                                setInvoiceItems={handleAddInvoiceItem}
                                handleAddMultipleProducts={handleAddMultipleProducts}
                            />
                        </div>
                    )}
                </div>
                <div className={classes.rightContainer}>
                    <SalePayment
                        customerId={customerId}
                        invoiceValue={invoiceValue}
                        tipValue={tipValue}
                        isExchange={invoiceState.isExchange}
                        items={items}
                        setItems={setItems}
                        wasPaid={wasPaid}
                        discount={discount}
                        isProcessing={isProcessing}
                        setIsProcessing={setIsProcessing}
                        saveInvoiceNote={modifyInvoice}
                    />
                </div>

                {isLoading && <LoadingScreen />}
            </div>
        )
    );
}

SaleView.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SaleView);
