import { LOAD_PRACTITIONERS } from '../constants/practitioners';
import { getPractitioners } from '../../api/practitionerApi';

export const loadPractitioners = () => async (dispatch) => {
    const response = await getPractitioners();

    dispatch({
        type: LOAD_PRACTITIONERS,
        payload: response
    });
};
