import api from './api';

class GiftcardApi {
    static create(data) {
        return api.request({
            method: 'POST',
            url: 'giftcard',
            data
        });
    }

    static getGiftcard(code) {
        return api.request({
            method: 'GET',
            url: `giftcard/find-by-code/${code}`
        });
    }

    static checkAvailable(code) {
        return api.request({
            method: 'GET',
            url: `giftcard/available/${code}`
        });
    }

    static getNextAvailableCode(code) {
        return api.request({
            method: 'GET',
            url: `giftcard/next-available/${code}`
        });
    }

    static findByCustomer(id) {
        return api.request({
            method: 'GET',
            url: `giftcard/${id}`
        });
    }

    static findAnonymousGiftCards() {
        return api.request({
            method: 'GET',
            url: 'giftcard/anonymous'
        });
    }

    static redeemGiftCard(giftCardId, customerId) {
        return api.request({
            method: 'PUT',
            url: `giftcard/${giftCardId}/redeem`,
            data: { customerId }
        });
    }
}

export default GiftcardApi;
