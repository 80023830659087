export const modalStyles = theme => ({
    formContainer: {
        flexFlow: 'column',
        marginLeft: theme.spacing(),
        marginRight: 90,
        marginTop: 10,
        marginBottom: 10,
        width: 200,
        display: 'flex',
        flexWrap: 'wrap',
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: '14px',
        '& > *': {
            marginTop: theme.spacing(),
            marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
            marginBottom: theme.spacing()
        }
    },
    font: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: '14px',
        '& > * > *, & > * > * > *, & > *': {
            fontFamily: 'Roboto, Arial, Sans-serif',
            fontSize: '14px'
        }
    },
    label: {
        '& > span': {
            lineHeight: 1.5,
            fontFamily: 'Roboto, Arial, Sans-serif',
            fontSize: '14px'
        }
    },
    autocomplete: {
        margin: 0,
        fontSize: 14,
        width: '100%',
        '& > * > *, & > *': {
            lineHeight: '10px'
        },
        '& input': {
            fontSize: 14
        }
    },
    noteField: {
        width: 'calc(100% - 10px)',
        '& > div > textarea': {
            lineHeight: '1.1'
        },
        '& .MuiOutlinedInput-root': {
            height: 'auto'
        }
    },
    whitespace: {
        height: 48,
        marginTop: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        marginBottom: theme.spacing()
    },
    inputIcon: {
        color: '#bfbfbf'
    },
    hiddenCalendarIcon: {
        '& .MuiIconButton-root': {
            display: 'none'
        }
    },
    select: {
        '& .MuiSelect-root': {
            boxSizing: 'border-box'
        }
    },
    keyboardDatePicker: {
        border: '1px solid rgba(0, 0, 0, 0.25)',
        height: '38px',
        position: 'relative',
        lineHeight: '10px',
        borderRadius: '4px',

        '&:hover': {
            border: '1px solid rgba(0, 0, 0, 0.75)'
        },

        '& label': {
            marginLeft: '14px',
            marginTop: '-3px',
            backgroundColor: 'white'
        },
        '& label.MuiInputLabel-shrink': {
            transform: 'scale(0.75) !important',
            marginTop: '-1px',
            padding: '0px 5px',
            height: '2px'
        },
        '& .MuiInput-underline:after, & .MuiInput-underline:before': {
            display: 'none'
        },
        '& .MuiInputBase-input': {
            padding: '2px 14px',
            marginTop: '-3px'
        },
        '& .MuiFormHelperText-root.Mui-error': {
            fontSize: '10px'
        }
    }
});
