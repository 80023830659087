import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const getCurrentTimezonedDate = (date, arrivedFromServer = true) => {
    let currentDate = new Date();

    const startDate = currentDate;
    const startTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const endDate = currentDate;
    const endTimeZone = process.env.REACT_APP_TIMEZONE || 'Europe/London';

    const startUtcDate = zonedTimeToUtc(startDate, startTimeZone);
    const endUtcDate = zonedTimeToUtc(endDate, endTimeZone);

    const timeDifferenceMilliseconds = endUtcDate - startUtcDate;
    const timeDifferenceHours = timeDifferenceMilliseconds / (1000 * 60 * 60);
    if (!date) {
        currentDate = zonedTimeToUtc(currentDate, Intl.DateTimeFormat().resolvedOptions().timeZone);

        currentDate.setHours(currentDate.getHours() - timeDifferenceHours);
        return currentDate;
    }
    if (arrivedFromServer) {
        return utcToZonedTime(date, process.env.REACT_APP_TIMEZONE || 'Europe/London');
    }
    date = utcToZonedTime(date, process.env.REACT_APP_TIMEZONE || 'Europe/London');
    date.setHours(date.getHours() + timeDifferenceHours);
    return date;
};

export const getUTCISOString = date => {
    return zonedTimeToUtc(date, process.env.REACT_APP_TIMEZONE || 'Europe/London').toISOString();
};

export const getDateNoTimezone = date => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${date.getFullYear()}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
};

export const timezonesDiffer = () => {
    let currentDate = new Date();

    const startDate = currentDate;
    const startTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const endDate = currentDate;
    const endTimeZone = process.env.REACT_APP_TIMEZONE || 'Europe/London';

    const startUtcDate = zonedTimeToUtc(startDate, startTimeZone);
    const endUtcDate = zonedTimeToUtc(endDate, endTimeZone);

    const timeDifferenceMilliseconds = endUtcDate - startUtcDate;
    return timeDifferenceMilliseconds / (1000 * 60 * 60);
};

export const timezonesDifferUTC = () => {
    const timeZone1 = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZone2 = 'Etc/UTC';

    const now = new Date();
    const now1 = utcToZonedTime(now, timeZone1);
    const now2 = utcToZonedTime(now, timeZone2);

    const timeDifferenceMilliseconds = now2 - now1;

    return -timeDifferenceMilliseconds / (1000 * 3600);
};
