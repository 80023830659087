import React from 'react';
import { Button as MaterialButton, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { buttonStyles as styles } from './styles';

const Button = ({ type, onClick, className, classes, children, isDisabled }) => {
    const classesMap = {
        primary: {
            button: classes.primaryButton,
            text: classes.primaryText
        },
        secondary: {
            button: classes.secondaryButton,
            text: classes.secondaryText
        },
        cancel: {
            button: classes.cancelButton,
            text: classes.cancelText
        }
    };
    return (
        <MaterialButton
            className={classNames(classesMap[type].button, className)}
            variant={'cancel' === type ? 'outlined' : 'text'}
            onClick={onClick}
            disabled={isDisabled}
        >
            <Typography className={classesMap[type].text}>{children}</Typography>
        </MaterialButton>
    );
};

Button.propTypes = {
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    children: PropTypes.any.isRequired,
    isDisabled: PropTypes.bool.isRequired
};

export default withStyles(styles)(Button);
