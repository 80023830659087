import { SET_NOTIFICATIONS } from '../constants/notifications';

const initialValue = {
    notifications: { email: true, SMS: false }
};

const invoiceReducer = (state = initialValue, action) => {
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            };

        default:
            return state;
    }
};

export default invoiceReducer;
