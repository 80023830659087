import React, { useState, useEffect } from 'react';
import { MenuItem, FormControl, Select } from '@material-ui/core';
import PropTypes from 'prop-types';

import { listActiveClinics } from '../../api/ClinicApi';
import AuthApi from '../../api/AuthApi';
import CancelContinueModal from './CancelContinueModal';
import LoadingScreen from './loadingScreen';

const ClinicSelector = ({ clinicId, onChange, disabled, showConfirm, fontSize }) => {
    const [clinicState, setClinicState] = useState({ current: {}, clinics: [] });
    const [confirmModal, setConfirmModal] = useState({ show: false, onConfirm: null });

    useEffect(() => {
        const fetchClinics = async () => {
            const clinics = await listActiveClinics();
            const user = await AuthApi.getMe();
            const allowedClinics = clinics.filter(c => user.locations?.some(loc => loc.clinic === c.id));

            const newClinicState = {
                clinics: allowedClinics,
                current: {}
            };

            if (clinicId && !['undefined', 'null'].includes(clinicId)) {
                newClinicState.current = allowedClinics.find(c => c.id === clinicId) ?? {};
                if (!user.locations?.some(loc => loc.clinic === clinicId)) {
                    newClinicState.current = allowedClinics.find(c => c.id === clinicId) ?? {};
                    if (Object.keys(newClinicState.current).length) {
                        onChange(newClinicState.current);
                    }
                }
            } else {
                newClinicState.current = allowedClinics[0] ?? {};
                if (allowedClinics.length && Object.keys(newClinicState.current).length) {
                    onChange(newClinicState.current);
                }
            }

            setClinicState(newClinicState);
        };

        fetchClinics();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setClinicState({ ...clinicState, current: clinicState.clinics?.find(c => c.id === clinicId) });
        // eslint-disable-next-line
    }, [clinicId]);

    if ((clinicId && clinicState.clinics?.length <= 1) || disabled) return <></>;

    return (
        <>
            {!clinicId && <LoadingScreen />}
            <FormControl>
                <Select
                    value={clinicState.current?.id}
                    onChange={event => {
                        const selectedClinic = clinicState.clinics.find(e => e.id === event.target.value);
                        if (showConfirm) setConfirmModal({ show: true, onConfirm: () => onChange(selectedClinic) });
                        onChange(selectedClinic);
                    }}
                    disableUnderline
                >
                    {clinicState.clinics.map(c => (
                        <MenuItem key={c.id} value={c.id}>
                            <span style={{ fontSize: fontSize || '0.9rem' }}>{c.accountName}</span>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <CancelContinueModal
                open={confirmModal.show}
                setOpen={show => setConfirmModal({ ...confirmModal, show })}
                content="Unsaved data will be lost"
                title={'Change clinic'}
                onContinue={confirmModal.onConfirm}
            />
        </>
    );
};

ClinicSelector.propTypes = {
    clinicId: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    showConfirm: PropTypes.bool,
    fontSize: PropTypes.string
};

export default ClinicSelector;
