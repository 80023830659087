import Axios from 'axios';
import Api from './config';

export function listCustomers(value, rowsPerPage, page, filterByLastUsed, sortByWalkIn) {
    return Api.request({
        method: 'GET',
        url: '/customers',
        params: { value, rowsPerPage, page, filterByLastUsed, sortByWalkIn }
    });
}

export async function uploadCustomerAvatar(customerId, file) {
    const uploadUrl = await Api.request({
        method: 'GET',
        url: `/customers/${customerId}/upload-url?type=${file.name.split('.')[1]}`
    });

    const signedRequest = uploadUrl.signedRequest;
    const url = uploadUrl.url;

    const options = {
        headers: {
            'Content-Type': file.name.split('.')[1],
            'x-amz-acl': 'public-read'
        }
    };

    // signedRequest, file, options
    await Axios({ url: signedRequest, method: 'PUT', data: file, options });
    return url;
}

export async function getCustomerHistoryLog(id, query) {
    return Api.request({
        method: 'GET',
        url: `/customers/history/${id}?${query}`
    });
}

export async function getMedicalTabsCount(id) {
    return Api.request({
        method: 'GET',
        url: `/customers/medical/${id}`
    });
}
export async function getCustomerById(id) {
    return Api.request({
        method: 'GET',
        url: `/customers/${id}`
    });
}
