import React, { useEffect, useState } from 'react';
import { withStyles, Typography, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';

import Autocomplete from '../common/Autocomplete';
import Select from '../common/Select';
import { saleInvoiceDiscountStyles as styles } from './styles';

import { setSelectedTemporaryDiscount } from '../../redux/actions/invoiceActions';

import { PaymentStatus } from '../../constants/Invoice';
import { TEMPORARY_DISCOUNTS } from '../../collums-constants/index';

import { updateInvoice, resetItemsTempDiscount } from '../../api/invoiceApi';

import _, { orderBy } from 'lodash';
import { getCurrencySymbol, cutNumericValue } from '../../collums-components/helpers';

const defaultPercentageOptions = Array.from(new Array(21), (_, i) => ({
    label: `${i * 5}%`.toString(),
    value: i * 5
}));

const SaleInvoiceDiscount = ({
    preInvoiceDiscounts,
    invoice,
    invoiceItems,
    customerId,
    discount,
    setDiscount,
    discountValue,
    formatTemporaryDiscount,
    removePromotionDiscount,
    discountUnits,
    classes,
    total,
    shouldApplyDiscount,
    validateInvoiceChange,
    setIsProcessing,
    isProcessing
}) => {
    const dispatch = useDispatch();
    const selectedTempDiscount = useSelector((state) => state.invoice.selectedDiscount);
    const [disable, setDisable] = useState(false);
    const [isChanging, setIsChanging] = useState(false);
    const [temporaryDiscountsOptions, setTemporaryDiscountsOptions] = useState([]);
    const [currencyValue, setCurrencyValue] = useState(discount?.value?.toFixed(2) || '0.00');
    const [percentageOptions, setPercentageOptions] = useState(defaultPercentageOptions);
    const getDiscountUnit = (item) => {
        if (item.discountType === TEMPORARY_DISCOUNTS.DISCOUNT_TYPES.PERCENTAGE) return discountUnits.percentage;
        return discountUnits.currency;
    };
    const setUpTemporaryDiscountsOptions = (discounts) => {
        const newPercentageOptions = [...defaultPercentageOptions];
        const iterator = [...discounts];
        const options = iterator.map((item) => {
            const formatedOption = formatTemporaryDiscount(item);
            const newOption = {
                label: `${formatedOption.discount}%`,
                value: formatedOption.discount
            };
            if (!newPercentageOptions.some((el) => el.value === newOption.value)) {
                newPercentageOptions.push(newOption);
            }
            return formatedOption;
        });
        setPercentageOptions(orderBy(newPercentageOptions, (o) => o.value, ['asc']));
        setTemporaryDiscountsOptions(options);
    };

    const resetInvoiceItemsDiscount = async () => {
        if (!invoice?.id) return;
        await resetItemsTempDiscount(invoice.id);
        return;
    };

    useEffect(() => {
        if (!invoice) {
            setDisable(!invoiceItems.length);
            return;
        }
        setDisable(invoice?.paymentStatus !== PaymentStatus.UNPAID);
    }, [invoiceItems, invoice]);

    useEffect(() => {
        setCurrencyValue(discount?.value?.toFixed(2) || '0.00');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discount]);

    useEffect(() => {
        setUpTemporaryDiscountsOptions(preInvoiceDiscounts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceItems, preInvoiceDiscounts]);

    const handleUpdateInvoice = async (id, data) => {
        return await updateInvoice(id, data);
    };

    const renderCurrency = () => {
        return (
            <input
                value={currencyValue}
                type="number"
                min={0}
                max={total}
                disabled={disable}
                className={classes.currencyInput}
                onChange={async (e) => {
                    // If there is an invoice (clicked on Advance Payment):
                    let value = e.target.value;
                    if (value < 0) {
                        toastr.error('Error', 'This value cannot be negative');
                        return;
                    }
                    if (value > total - discountValue) {
                        toastr.error('Error', 'Value cannot exceed the invoice amount');
                        return;
                    }

                    value = _.isEmpty(value) ? parseFloat(value) : cutNumericValue(value, 2);
                    setCurrencyValue(value);
                }}
                onBlur={async () => {
                    const canChange = await validateInvoiceChange();
                    if (!canChange) return;

                    const value = parseFloat(currencyValue || '0');
                    if (isNaN(value) || value < 0 || value > total - discountValue) {
                        setCurrencyValue(0);
                        return;
                    }
                    if (Object.keys(selectedTempDiscount).length) {
                        await resetInvoiceItemsDiscount();
                        dispatch(setSelectedTemporaryDiscount(false));
                        if (invoice) {
                            await handleUpdateInvoice(invoice.id, {
                                temporaryDiscount: null
                            });
                        }
                    }
                    const newDiscountValue = cutNumericValue(value, 2);
                    setCurrencyValue(newDiscountValue);
                    shouldApplyDiscount.current = true;
                    setDiscount({
                        ...discount,
                        value: newDiscountValue,
                        eligableItems: [],
                        itemsToApply: []
                    });
                }}
            />
        );
    };
    const renderInput = () => {
        return discountUnits.percentage === discount.unit ? (
            <Autocomplete
                disable={disable}
                className={classes.percentageInput}
                options={percentageOptions}
                value={+discount.value || percentageOptions[0].value}
                onChange={async (option) => {
                    const canChange = await validateInvoiceChange();
                    if (!canChange) return;

                    // If there is an invoice (clicked on Advance Payment):
                    if (Object.keys(selectedTempDiscount).length) {
                        await resetInvoiceItemsDiscount();
                        dispatch(setSelectedTemporaryDiscount(false));
                        if (invoice) {
                            await handleUpdateInvoice(invoice.id, {
                                temporaryDiscount: null
                            });
                        }
                    }

                    shouldApplyDiscount.current = true;
                    setDiscount({
                        ...discount,
                        eligableItems: [],
                        itemsToApply: [],
                        value: option ? parseFloat(option.value) : 0
                    });
                }}
            />
        ) : (
            renderCurrency()
        );
    };

    if (!customerId) {
        return null;
    }
    return (
        <Box>
            <div className={classes.container}>
                <div>
                    <Typography className={classes.label}>Invoice Discount:</Typography>
                </div>
                <Select
                    disable={disable}
                    className={classes.initInput}
                    options={[
                        { value: discountUnits.percentage, label: '%' },
                        { value: discountUnits.currency, label: getCurrencySymbol() }
                    ]}
                    value={discount.unit}
                    onChange={async (value) => {
                        const canChange = await validateInvoiceChange();
                        if (!canChange) return;

                        // If there is an invoice (clicked on Advance Payment):
                        if (Object.keys(selectedTempDiscount).length && invoice) {
                            await handleUpdateInvoice(invoice.id, {
                                temporaryDiscount: null
                            });
                        }

                        dispatch(setSelectedTemporaryDiscount(false));

                        shouldApplyDiscount.current = true;
                        setDiscount({
                            ...discount,
                            unit: value,
                            value: 0,
                            eligableItems: [],
                            itemsToApply: []
                        });
                    }}
                />
                {renderInput()}
            </div>
            <Box className={classes.container} style={{ marginTop: '18px' }}>
                <Box>
                    <Typography className={classes.label}>Promotion Discount:</Typography>
                </Box>
                <Autocomplete
                    key={selectedTempDiscount?.value || Math.random() * 1000}
                    disable={disable}
                    className={classes.discountInput}
                    value={selectedTempDiscount?.value || null}
                    inputValue={selectedTempDiscount?.value || null}
                    options={temporaryDiscountsOptions}
                    onChange={async (option) => {
                        if (isChanging || isProcessing) {
                            return;
                        }
                        setIsChanging(true);
                        setIsProcessing(true);
                        const canChange = await validateInvoiceChange();
                        if (!canChange) {
                            setIsChanging(false);
                            setIsProcessing(false);
                            return;
                        }

                        // If there is an invoice (clicked on Advance Payment):
                        if (!option) {
                            removePromotionDiscount();
                            setIsChanging(false);
                            setIsProcessing(false);
                            return;
                        }

                        dispatch(setSelectedTemporaryDiscount(option));

                        if (invoice) {
                            await updateInvoice(invoice.id, {
                                temporaryDiscount: option ? option.value : null,
                                discountProperties: { value: 0, type: 'percentage' }
                            });
                        }

                        shouldApplyDiscount.current = true;
                        if (option.applyTo === TEMPORARY_DISCOUNTS.APPLY.TOTAL) {
                            setDiscount({
                                ...discount,
                                itemsToApply: [],
                                eligableItems: [],
                                unit: getDiscountUnit(option),
                                value: option.discount
                            });
                            setIsChanging(false);
                            setIsProcessing(false);
                            return;
                        }
                        setDiscount({
                            ...discount,
                            unit: getDiscountUnit(option),
                            value: option.discount,
                            itemsToApply: option.eligableItems.map((item) => item.item)
                        });
                        setIsChanging(false);
                        setIsProcessing(false);
                    }}
                />
            </Box>
        </Box>
    );
};

SaleInvoiceDiscount.propTypes = {
    preInvoiceDiscounts: PropTypes.array,
    discountUnits: PropTypes.object.isRequired,
    customerId: PropTypes.string,
    invoice: PropTypes.object,
    invoiceItems: PropTypes.array,
    discount: PropTypes.object.isRequired,
    setDiscount: PropTypes.func.isRequired,
    formatTemporaryDiscount: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
    discountValue: PropTypes.number.isRequired,
    removePromotionDiscount: PropTypes.func.isRequired,
    shouldApplyDiscount: PropTypes.object.isRequired,
    validateInvoiceChange: PropTypes.func.isRequired,
    setIsProcessing: PropTypes.func.isRequired,
    isProcessing: PropTypes.bool.isRequired
};

export default withStyles(styles)(SaleInvoiceDiscount);
