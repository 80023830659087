export const autocompleteStyles = (theme) => ({
    formControl: {
        '& .MuiInputBase-root': {
            height: 38,
            padding: 0,
            paddingLeft: 12
        },
        '& .MuiInputBase-input': {
            paddingTop: '8px !important'
        },
        '& fieldset': {
            borderColor: theme.palette.black.full
        },
        '& input': {
            paddingLeft: '2px !important'
        }
    },
    placeholder: {
        fontFamily: 'Gilmer Medium',
        fontSize: 12,
        marginTop: -5,
        marginLeft: 17
    },
    boldOption: {
        fontFamily: 'Gilmer Bold'
    }
});

export const confirmModalStyles = () => ({
    modal: {
        '& .MuiDialog-paper': {
            backgroundColor: 'white',
            alignItems: 'center',
            '& .MuiDialogTitle-root h2 p': {
                fontWeight: '600 !important',
                fontSize: '18px !important'
            }
        }
    }
});

export const buttonStyles = () => ({
    primaryButton: {
        height: 44,
        margin: '0px 10px 10px 0px',
        padding: '6px 14px',
        borderRadius: 0,
        backgroundColor: '#b41778',
        '&:hover': {
            backgroundColor: '#912d6b'
        },
        '&:active': {
            backgroundColor: '#6e2251'
        }
    },
    primaryText: {
        fontSize: 14,
        fontFamily: 'Gilmer Medium',
        color: '#ffffff',
        textTransform: 'none'
    },
    secondaryButton: {
        height: 44,
        margin: '0px 10px 10px 0px',
        padding: '6px 14px',
        borderRadius: 0,
        backgroundColor: '#2b78e4',
        '&:hover': {
            backgroundColor: '#286cd3'
        },
        '&:active': {
            backgroundColor: '#215bad'
        }
    },
    secondaryText: {
        fontSize: 14,
        fontFamily: 'Gilmer Medium',
        color: '#ffffff',
        textTransform: 'none'
    },
    cancelButton: {
        height: 44,
        margin: '0px 10px 10px 0px',
        padding: '6px 14px',
        borderRadius: 0,
        borderColor: '#575A64',
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#f0f0f0'
        },
        '&:active': {
            backgroundColor: '#d8d8d8'
        }
    },
    cancelText: {
        fontSize: 14,
        fontFamily: 'Gilmer Medium',
        color: '#575A64',
        textTransform: 'none'
    }
});

export const closeBtnStyles = () => ({
    button: {
        padding: 8,
        '& .material-icons': {
            width: 18,
            height: 18
        },
        '& .img': {
            width: 18,
            height: 18
        }
    }
});

export const dateInputStyles = (theme) => ({
    root: {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0
        }
    },
    blackBorder: {
        '& fieldset': {
            borderColor: theme.palette.black.full
        }
    }
});

export const selectStyles = (theme) => ({
    formControl: {
        '& .MuiSelect-select:focus': {
            backgroundColor: 'inherit'
        },
        '& .MuiSelect-root': {
            paddingTop: 22
        },
        '& fieldset': {
            borderColor: theme.palette.black.full
        }
    }
});

export const testFieldStyles = (theme) => ({
    textField: {
        '& fieldset': {
            borderColor: theme.palette.black.full
        },
        '& input': {
            paddingTop: 17
        }
    }
});

export const badgeLoading = () => ({
    badgeWraper: {
        width: '5.2rem',
        height: 'fit-content',
        zIndex: '1',
        borderRadius: '5px',
        display: 'flex',
        placeContent: 'space-between',
        padding: '4px 7px',
        fontWeight: 'bold',
        fontSize: '12px',
        transition: 'visibility 0s, opacity 0.5s linear',
        '& .MuiCircularProgress-colorPrimary': {
            width: '14px !important',
            height: 'unset !important'
        },
        '& .MuiSvgIcon-root': {
            width: '18px !important',
            height: 'unset !important'
        }
    },
    badgeLoading: {
        backgroundColor: '#fbfb9d',
        width: '5rem'
    },
    badgeComplete: {
        backgroundColor: '#84e595',
        width: '5.5rem'
    },
    centerSpinner: {
        display: 'flex',
        alignItems: 'center'
    }
});

export const confirmExitModalStyles = (theme) => ({
    cancelModal: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center'
    },
    cancelModalContainer: {
        backgroundColor: 'white',
        margin: 'auto',
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    regularButtonText: {
        color: theme.palette.white.main,
        textTransform: 'none'
    },
    mdSpacer: {
        marginBottom: '1rem'
    },
    smSpacer: {
        marginBottom: '0.3rem'
    },
    regularButton: {
        backgroundColor: theme.palette.gray.main,
        borderRadius: 0,
        height: 44,
        margin: '0px 10px 10px 0px',
        padding: '6px 14px',
        '&:hover': {
            backgroundColor: theme.palette.gray.bold
        },
        '& p': {
            fontFamily: 'Gilmer Medium',
            fontSize: 14
        }
    },
    saveButton: {
        backgroundColor: theme.palette.wine.main,
        '&:hover': {
            backgroundColor: theme.palette.wine.hover
        }
    },
    cancelButton: {
        borderColor: theme.palette.lightGrey.main,
        backgroundColor: theme.palette.white.main,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.06)'
        }
    },
    textButton: {
        color: theme.palette.lightGrey.main,
        textTransform: 'none',
        opacity: 1
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    }
});
