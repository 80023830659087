import { SET_TAXES } from '../constants/taxes';

const initialState = [];

const taxesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TAXES:
            return [...state, ...action.payload];
        default:
            return state;
    }
};

export default taxesReducer;
