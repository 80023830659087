import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Modal, Typography, Button } from '@material-ui/core';

import { stockLimitModalStyles } from './styles';
import CloseBtn from '../common/CloseBtn';

function StockLimitModal({ setIsVisible, productStock, classes }) {
    const closeModal = () => {
        setIsVisible(false);
    };

    return (
        <Modal open={true} onClose={closeModal} className={classes.modal}>
            <div className={classes.root}>
                <div className={classes.closeBtn}>
                    <CloseBtn onClick={closeModal} />
                </div>
                <div className={classes.content}>
                    <Typography variant="h4">Limit stock warning</Typography>
                    <Typography>
                        There are {productStock && productStock > 0 ? `only ${productStock}` : 'no'} items in stock.
                    </Typography>
                    <Typography>Please change the quantity you wish to sell</Typography>
                    <div className={classes.actions}>
                        <Button onClick={closeModal}>Continue</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

StockLimitModal.propTypes = {
    productStock: PropTypes.number,
    setIsVisible: PropTypes.func.isRequired,
    classes: PropTypes.object
};

export default withStyles(stockLimitModalStyles)(StockLimitModal);
