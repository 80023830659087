import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const FieldContainer = ({ children, styles }) => (
    <Grid container item lg={8} spacing={2} alignItems="center" justify="flex-start" style={styles}>
        {children}
    </Grid>
);

FieldContainer.propTypes = {
    children: PropTypes.node,
    styles: PropTypes.object
};

export { FieldContainer };
