import axios from 'axios';

class PostCodeApi {
    static async searchForAddress(zipcode) {
        const postcodesAPI = axios.create({
            baseURL: 'https://postcodes.io/postcodes/'
        });

        try {
            const response = await postcodesAPI.get(`${zipcode}`);

            return { city: response.data.result.admin_district, region: response.data.result.region };
        } catch (error) {
            return { city: '', region: '', error: error.response && error.response.data.error };
        }
    }
}

export default PostCodeApi;
