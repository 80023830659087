export const saleHeader = () => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16
    },
    loadingIcon: {
        display: 'flex',
        marginLeft: '14px',
        width: '20px',
        alignItems: 'center',
        justifyContent: 'center'
    },
    clinicSelector: {
        margin: '1rem 1rem 0'
    },
    customerContainer: {
        display: 'flex'
    },
    customerInput: {
        width: 300,
        '& .MuiInputBase-root': {
            height: 38,
            padding: '0 0.5rem'
        }
    },
    addCustomerBtn: {
        background: '#2b78e4',
        padding: 0,
        height: 34,
        marginTop: 2,
        marginLeft: 14,
        '&:hover': {
            backgroundColor: '#286cd3'
        },
        '&:active': {
            backgroundColor: '#215bad'
        },
        '& svg': {
            width: 34,
            height: 34,
            fill: '#ffffff'
        }
    },
    balanceBox: {
        backgroundColor: '#cf2a27',
        color: '#ffffff',
        padding: '8px 20px',
        borderRadius: 0,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#cf2a27'
        }
    },
    mediumText: {
        fontFamily: 'Gilmer Medium'
    },
    mediumLink: {
        fontFamily: 'Gilmer Medium',
        color: '#2b78e4',
        cursor: 'pointer',
        textTransform: 'none',
        '&:hover': {
            textDecoration: 'underline',
            color: '#286cd3'
        }
    },
    headerWrapper: {
        width: '100%',
        margin: '0 1rem',
        display: 'flex',
        justifyContent: 'space-evenly'
    }
});

export const addInvoiceItemStyles = () => ({
    quantityField: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        width: '60%',
        flexWrap: 'wrap',
        '& p': {
            marginLeft: '15px'
        }
    }
});

export const confirmationModalStyles = () => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '5vh 25%',
        alignItems: 'center'
    },
    root: {
        width: '80%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'min-content',
        paddingBottom: '3vh',
        outline: 'none'
    },
    content: {
        overflowY: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& p': {
            width: '70%',
            textAlign: 'center'
        },
        '& h4': {
            marginBottom: '14px'
        }
    },
    closeBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '40%',
        margin: '3vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14
        },
        '& button:nth-child(2)': {
            backgroundColor: '#b41778',
            color: 'white'
        }
    }
});

export const stockLimitModalStyles = () => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '5vh 25%',
        alignItems: 'center'
    },
    root: {
        width: '80%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'min-content',
        paddingBottom: '3vh',
        outline: 'none'
    },
    content: {
        overflowY: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& p': {
            width: '70%',
            textAlign: 'center'
        },
        '& h4': {
            marginBottom: '14px'
        }
    },
    closeBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '40%',
        margin: '3vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14,
            backgroundColor: '#b41778',
            color: 'white'
        }
    }
});

export const saleInvoiceDiscountStyles = () => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 12px'
    },
    label: {
        fontFamily: 'Gilmer Medium',
        fontSize: 14,
        marginRight: 12
    },
    initInput: {
        marginRight: 12
    },
    percentageInput: {
        width: 100
    },
    discountInput: {
        width: '100%'
    },
    currencyInput: {
        width: 100,
        height: 38,
        boxSizing: 'border-box',
        padding: 5,
        borderRadius: 5,
        border: '1px solid black',
        fontFamily: 'Roboto, Arial, Sans-serif'
    }
});

export const saleInvoiceNoteStyles = (theme) => ({
    noteInput: {
        width: 528,
        '& .MuiInputBase-root': {
            height: 120
        },
        '& fieldset': {
            borderColor: theme.palette.black.full,
            height: 120
        },
        '& textarea': {
            height: 100,
            lineHeight: '18px'
        },
        '& textarea::placeholder': {
            color: `${theme.palette.black.full} !important`
        }
    },
    invoiceNotesTitle: {
        marginBottom: 5
    }
});

export const saleInvoiceSummaryStyles = () => ({
    nowrap: {
        whiteSpace: 'nowrap'
    },
    container: {
        width: '25%'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 8,
        paddingRight: '70px'
    },
    summaryLabel: {
        fontFamily: 'Gilmer Medium',
        fontSize: 14
    },
    summaryAmount: {
        fontFamily: 'Gilmer Medium',
        fontSize: 14
    },
    bold: {
        fontFamily: 'Gilmer Bold'
    }
});

export const saleCardPaymentModal = () => ({
    warningBox: {
        backgroundColor: '#fbf5ae',
        borderColor: '#ecec34',
        borderStyle: 'solid',
        borderWidth: 1,
        padding: '10px 20px',
        borderRadius: 10,
        marginTop: 16
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        margin: '3vh 25%'
    },
    root: {
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'min-content',
        paddingBottom: '3vh',
        outline: 'none'
    },
    closeBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        '& p': {
            fontFamily: 'Gilmer Bold',
            fontSize: 16,
            margin: '0 0 14px 0'
        }
    },
    content: {
        overflowY: 'hidden',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    table: {
        width: '85%',
        '& td, th': {
            padding: 0
        },
        '& thead tr': {
            '& th:nth-child(1), th:nth-child(3), th:nth-child(4)': {
                width: '10%'
            },
            '& th:nth-child(2)': {
                width: '30%'
            },
            '& th:nth-child(5)': {
                width: '40%'
            }
        }
    },
    userAgreementCheckbox: {
        marginTop: '2vh'
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '40%',
        margin: '1vh 3vh 3vh 3vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14
        },
        '& button:nth-child(2)': {
            backgroundColor: '#b41778',
            color: 'white'
        }
    },
    form: {
        width: '85%',
        marginTop: '10px',
        '& h4': {
            margin: '2vh 0'
        }
    },
    selectRoot: {
        width: '100%',
        height: 38,
        '& .MuiOutlinedInput-root': {
            lineHeight: '4px'
        },
        '& label.MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(0px, -10px) scale(0.75)'
        },
        '& .MuiSelect-selectMenu': {
            height: 'inherit',
            boxSizing: 'border-box'
        }
    },
    waitingTerminalWrapper: {
        marginBottom: 20,

        '& .MuiCircularProgress-root': {
            marginTop: '14px',
            position: 'relative',
            top: '6px',
            left: '5px',
            width: '20px !important',
            height: '20px !important'
        }
    },
    inputContainer: {
        padding: '1vh 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& label': {
            fontFamily: 'Gilmer Medium, Roboto',
            fontSize: 14,
            width: '25%'
        },
        '& .MuiTextField-root': {
            width: '70%',
            '& input': {
                padding: '12px 20px'
            }
        }
    },
    squarePaymentForm: {
        marginTop: '8px',
        width: '85%',
        '& #web-payment-sdk-form': {
            width: '100%',
            '& .sq-card-iframe-container': {
                height: '50px'
            }
        }
    },
    cardBrand: {
        border: '1px solid rgba(0, 0, 0, 0.87)',
        padding: '3px',
        borderRadius: '3px'
    },
    checkoutMessage: {
        fontWeight: 500,
        fontSize: '12px',
        paddingTop: '6px'
    },
    paymentLinkOptionWrapper: {
        justifyContent: 'space-evenly',
        margin: '10px 0'
    },
    coherentPaymentLinkMessage: {
        color: 'red',
        marginTop: '-10px',
        marginBottom: '10px'
    }
});

export const saleProductHistory = () => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '3vh 10%'
    },
    smallModal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '3vh 25%'
    },
    root: {
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'min-content',
        paddingBottom: '3vh',
        outline: 'none'
    },
    closeBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        '& p': {
            fontFamily: 'Gilmer Bold',
            fontSize: 16,
            margin: 0
        },
        '& p:nth-child(2)': {
            margin: '0 0 14px 0'
        }
    },
    content: {
        width: '95%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    table: {
        width: '80%'
    },
    invoice: {
        '& p': {
            fontFamily: 'Gilmer Bold',
            fontSize: '0.75rem',
            margin: '14px 0 20px 0',
            textAlign: 'center'
        }
    },
    formControl: {
        display: 'flex',
        flexDirection: 'column',
        height: '50px',
        justifyContent: 'center',
        '& .MuiOutlinedInput-input': {
            padding: '11px 32px 11px 14px'
        }
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '40%',
        margin: '3vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14
        },
        '& button:nth-child(2)': {
            backgroundColor: '#b41778',
            color: 'white'
        }
    },
    quantitySelector: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    voucherTab: {
        display: 'flex',
        padding: '5%',
        width: '70%'
    },
    submitButton: {
        height: '100%',
        padding: '6px 14px',
        borderRadius: 0,
        fontSize: 14,
        fontFamily: 'Gilmer Medium',
        color: '#ffffff',
        textTransform: 'none',
        backgroundColor: '#2b78e4',

        '&:hover': {
            backgroundColor: '#286cd3'
        }
    }
});

export const saleInvoiceTableStyles = () => ({
    topLabel: {
        marginTop: 24,
        marginBottom: 16
    },
    tableHeader: {
        fontSize: 12,
        opacity: 1,
        fontWeight: 'bold',
        borderColor: 'black',
        borderWidth: 2
    },
    tableActionsColumns: {
        width: 70
    },
    emptyTable: {
        height: '7rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

export const saleInvoiceTableRowStyles = () => ({
    tableBodyCell: {
        borderColor: 'black',
        opacity: 1,
        fontSize: 12,
        height: 50,
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
        '&.editable': {
            cursor: 'pointer',
            color: '#2b78e4'
        },
        '&> span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            display: '-webkit-box'
        },
        '&.MuiTableCell-alignRight .MuiTextField-root': {
            marginLeft: 'auto'
        }
    },
    tableActionsColumns: {
        width: 70,
        paddingTop: 0,
        paddingBottom: 0
    },
    editCellInput: {
        marginRight: 4,
        '&.small': {
            width: 80
        },
        '& .MuiInputBase-root': {
            margin: 'auto'
        },
        '& input': {
            padding: '18.5px 0px',
            textAlignLast: 'center'
        }
    },
    editCellBtn: {
        padding: 7
    },
    editCellAutocomplete: {
        display: 'inline-block',
        padding: '7px',
        width: '70%',
        paddingLeft: 0
    },
    editableCellWrapper: {
        display: 'flex',

        '& > .MuiIconButton-root': {
            margin: '.4rem 0'
        }
    }
});

export const saleNumericPadStyles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
        margin: 'auto',
        height: 268,
        backgroundColor: '#cbced5',
        paddingBottom: 2,
        paddingRight: 2
    },
    row: {
        display: 'flex',
        flex: 1
    },
    outerBtn: {
        flex: 1,
        paddingTop: 2,
        paddingLeft: 2,
        cursor: 'pointer'
    },
    innerBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        userSelect: 'none'
    },
    blueBtn: {
        backgroundColor: '#2b78e4',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#276fd3'
        },
        '&:active': {
            backgroundColor: '#2365bf'
        }
    },
    brightBtn: {
        backgroundColor: '#f8f8f8',
        color: '#000000',
        '&:hover': {
            backgroundColor: '#eaeaea'
        },
        '&:active': {
            backgroundColor: '#dbdbdb'
        }
    },
    darkBtn: {
        backgroundColor: '#cbced5',
        color: '#000000',
        '&:hover': {
            backgroundColor: '#bcbfc5'
        },
        '&:active': {
            backgroundColor: '#b5b8be'
        }
    },
    label: {
        fontFamily: 'Gilmer Medium',
        fontSize: 22
    },
    smallLabel: {
        fontFamily: 'Gilmer Medium',
        fontSize: 16
    },
    backspaceIcon: {
        fontSize: 18,
        fill: '#505050'
    }
});

export const salePaymentStyles = (theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: 16,
        borderLeft: '2px black solid'
    },
    methodsContainer: {
        flex: 1,
        overflow: 'auto'
    },
    topLabel: {
        marginBottom: 32
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8
    },
    payBtn: {
        width: 156,
        height: 36,
        margin: 0,
        marginRight: 14,
        '&:disabled': {
            color: '#69767E',
            backgroundColor: '#cccccc'
        },
        '& p': {
            fontSize: '13px'
        }
    },
    takePayment: {
        marginLeft: '164px',
        flexDirection: 'row-reverse',
        padding: '0px 25px',
        display: 'flex'
    },
    amount: {
        display: 'inline',
        fontFamily: 'Gilmer Medium',
        fontSize: 14,
        marginRight: 8
    },
    editedAmount: {
        fontFamily: 'Gilmer Bold',
        fontStyle: 'italic',
        textDecoration: 'underline'
    },
    calculatorBtn: {
        padding: 8
    },
    closeBtn: {
        marginBottom: 2
    },
    voucherNo: {
        width: 156,
        fontFamily: 'Gilmer Medium',
        marginBottom: 28,
        '& input::placeholder': {
            fontFamily: 'Gilmer Medium !important'
        }
    },
    summaryLabel: {
        width: 102,
        fontFamily: 'Gilmer Medium',
        fontSize: 14,
        marginLeft: 164
    },
    summaryAmount: {
        fontFamily: 'Gilmer Medium',
        fontSize: 14,
        marginRight: 44
    },
    bold: {
        fontFamily: 'Gilmer Bold'
    },
    red: {
        color: 'red'
    },
    invoiceSettingBtn: {
        margin: '1px .8rem',
        borderRadius: '4px',
        padding: 8,

        '&.active': {
            margin: '0 calc(.8rem - 1px)',
            border: `1px solid ${theme.palette.black.full}`
        }
    },
    sendEmailTemplateInput: {
        width: '60%'
    },
    submitButton: {
        height: 44,
        padding: '6px 14px',
        borderRadius: 0,
        fontSize: 14,
        fontFamily: 'Gilmer Medium',
        color: '#ffffff',
        textTransform: 'none',
        backgroundColor: '#2b78e4',

        '&:hover': {
            backgroundColor: '#286cd3'
        }
    },
    paymentNotifications: {
        fontFamily: 'Gilmer Medium',
        fontSize: 14,
        marginLeft: '164px',
        marginTop: 30,
        '& label': {
            width: '100%'
        }
    },
    notificationsCheckboxes: {
        display: 'flex',
        flexDirection: 'row'
    },
    voucherSearchIcon: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
});

export const addCardStyles = (theme) => ({
    field: {
        width: '100%',
        marginBottom: 4,
        '& p': {
            fontSize: '14px'
        }
    },
    inputField: {
        '& div': {
            width: '100%',
            '& div': {
                width: 'auto'
            }
        }
    },
    creditCardInput: {
        border: `1px solid ${theme.palette.gray.border}`
    },
    makeDefaultCheckbox: {
        padding: '9px 9px 9px 0'
    },
    squareRoot: {
        display: 'flex',
        width: '80%',
        '& .sq-payment-form': {
            width: '100% !important',
            display: 'flex',
            flexDirection: 'column',
            '& .sq-fieldset': {
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
            }
        }
    },
    submitButton: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        margin: '3vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14,
            width: '35%'
        },
        '& div': {
            width: '35%',
            '& .sq-creditcard': {
                backgroundColor: '#b41778',
                color: 'white',
                width: '100%',
                fontWeight: 400,
                marginTop: 0,
                height: '100%'
            }
        }
    }
});

export const paymentTable = () => ({
    tableTitle: {
        marginBottom: '8px',
        marginTop: '16px',
        '& .MuiTypography-root': {
            fontWeight: 900
        }
    },
    flexBoxBoldText: {
        display: 'flex',
        marginTop: '10px',
        marginRight: '32px',
        justifyContent: 'flex-end',
        '& .MuiTypography-root': {
            fontWeight: 900
        },
        '& .MuiTypography-root:nth-child(2)': {
            marginLeft: '24px'
        }
    },
    tableHeader: {
        backgroundColor: '#dbd8ce'
    }
});

export const saleViewStyles = () => ({
    container: {
        display: 'flex',
        height: '100%'
    },
    leftContainer: {
        flex: 1,
        height: '100%',
        overflow: 'auto'
    },
    rightContainer: {
        width: 400,
        height: '100%'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: 16,
        paddingTop: 0,
        marginTop: 20
    },
    alignLeft: {
        justifyContent: 'flex-start'
    },
    paymentsTable: {
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginRight: 57
    },
    invoicePaymentStatusText: {
        fontSize: 35,
        alignSelf: 'right'
    },
    btn: {
        height: 44,
        margin: '0px 10px 10px 0px',
        padding: '6px 14px',
        borderRadius: 0,
        fontSize: 14,
        fontFamily: 'Gilmer Medium',
        color: '#ffffff',
        textTransform: 'none',
        '&:disabled': {
            color: '#69767E',
            backgroundColor: '#cccccc'
        }
    },
    whiteBtn: {
        backgroundColor: '#fff',
        border: '1px solid #000',

        '& span, & p': {
            color: '#000'
        },

        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, .2)'
        }
    },
    redBtn: {
        backgroundColor: '#b41778',
        border: 'none',

        '& span, & p': {
            color: 'white'
        }
    },
    footerBtn: {
        marginTop: '8px'
    }
});

export const addInvoiceItemTabStyles = (theme) => ({
    addInvoiceItemTitle: {
        transform: 'none'
    },
    addInvoiceItemTabsWrapperStyles: {
        border: `1px solid ${theme.palette.black.full}`
    }
});

export const saleOutstandingAmountModalStyles = (theme) => ({
    dialogCard: {
        '& .MuiPaper-root': {
            padding: '1.4rem',
            border: '1px solid rgba(0, 0, 0, .)'
        },
        '& .MuiDialogTitle-root': {
            paddingBottom: 0
        }
    },
    dialogTitle: {
        fontSize: '1rem',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    dialogContent: {
        color: theme.palette.black.full
    }
});

export const modalStyles = (theme) => ({
    formContainer: {
        flexFlow: 'column',
        marginLeft: theme.spacing(),
        marginRight: 90,
        marginTop: 10,
        marginBottom: 10,
        width: 200,
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginTop: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        marginBottom: theme.spacing()
    },
    noteField: {
        width: 'calc(100% - 10px)'
    },
    whitespace: {
        height: 48,
        marginTop: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        marginBottom: theme.spacing()
    },
    inputIcon: {
        color: '#bfbfbf'
    },
    hiddenCalendarIcon: {
        '& .MuiIconButton-root': {
            display: 'none'
        }
    }
});
