import api from './config';

class TaxesApi {
    static listTaxes(query) {
        const queryString =
            `archived=${query?.archived || false}` +
            `${query?.inactive === true ? `&inactive=${query.inactive}` : ''}` +
            `${query?.name ? `&name=${encodeURIComponent(query.name || '')}` : ''}` +
            `${query?.rate ? `&rate=${encodeURIComponent(query.rate || 0)}` : ''}`;
        return api.request({
            method: 'GET',
            url: `/taxes?${queryString}`
        });
    }
    static create(organisationId, data) {
        return api.request({
            method: 'POST',
            url: `/taxes?organisationId=${organisationId}`,
            data
        });
    }
    static update(taxId, data) {
        return api.request({
            method: 'PUT',
            url: `/taxes/${taxId}`,
            data
        });
    }
    static getItemsUsingTax(taxId) {
        return api.request({
            method: 'GET',
            url: `/taxes/${taxId}/items`
        });
    }

    static getTaxesForItem(item) {
        return api.request({
            method: 'GET',
            url: `/taxes/${item}`
        });
    }
}

export default TaxesApi;
