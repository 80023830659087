import { useEffect, useCallback, useState } from 'react';

/**
 * Extract a number from `digit{number}` or `numpad{number}`.
 *
 * @param {String} code is a lower case event.code from event listener.
 */
export const getDigitFromCode = (code) => {
    const digit = code.replace('digit', '').replace('numpad', '');
    const intDigit = parseInt(digit, 10);

    return intDigit;
};

/**
 * Hook to handle keyboard events.
 *
 * If the key pressed is a number keydownEvent will have `isDigit` property
 * set to `true` and `number` set to the number pressed.
 *
 * @param {Function} fn is a callback that will be called every time a key is
 * pressed.
 */
export const useKeyboard = (fn) => {
    const [keydownEvent, setKeydownEvent] = useState(null);

    /**
     * Process a `keydown` event and set the state `keydownEvent` to the result.
     *
     * @param {Object} event Event object from `addEventListener`.
     */
    const keydownListener = useCallback((event) => {
        const { code, repeat } = event;
        const loweredCode = code.toLowerCase();
        const output = {
            code: loweredCode,
            isDigit: false,
            number: undefined
        };

        if (repeat) return;

        if (loweredCode.startsWith('digit')) {
            output.isDigit = true;
            output.number = getDigitFromCode(loweredCode);
        } else if (loweredCode.startsWith('numpad')) {
            const digit = getDigitFromCode(loweredCode);

            if (isNaN(digit)) {
                output.isDigit = false;
            }
        }

        setKeydownEvent(output);
    }, []);

    /**
     * Execute the callback using keydownEvent as argument, after that set
     * keydownEvent to null.
     */
    useEffect(() => {
        if (keydownEvent) {
            fn(keydownEvent);
            setKeydownEvent(null);
        }
    }, [keydownEvent, fn]);

    /**
     * Add the event listener to the window and remove it when the component
     * using this hook unmounts.
     */
    useEffect(() => {
        window.addEventListener('keydown', keydownListener, true);

        return () => {
            window.removeEventListener('keydown', keydownListener, true);
        };
    }, [keydownListener]);
};
