import { SET_CURRENT_CUSTOMER, SET_CURRENT_CUSTOMER_DATA } from '../constants/currentCustomer';

const initialState = {
    customerId: '',
    customerData: {}
};

const currentCustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_CUSTOMER:
            return {
                ...state,
                customerId: action.payload
            };
        case SET_CURRENT_CUSTOMER_DATA:
            return {
                ...state,
                customerData: action.payload
            };
        default:
            return state;
    }
};

export default currentCustomerReducer;
