import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const FieldLabel = ({ children }) => (
    <Grid item lg={2}>
        <Typography>{children}</Typography>
    </Grid>
);

FieldLabel.propTypes = {
    children: PropTypes.node
};

export { FieldLabel };
