import { toastr } from 'react-redux-toastr';
import customerApi from '../../api/customerApi';
import { LOAD_CUSTOMERS } from '../constants/customers';
import { setCurrentCustomerData } from './currentCustomerActions';

export const loadCustomers = () => async (dispatch) => {
    const filterByLastUsed = true;
    const rowsPerPage = 10;
    const sortByWalkIn = true;
    const response = await customerApi.listCustomers(null, rowsPerPage, 0, filterByLastUsed, sortByWalkIn);
    const payload = response.customers.map((item) => ({
        ...item,
        value: item.id,
        label: `${item.firstName} ${item.surname}`
    }));

    dispatch({
        type: LOAD_CUSTOMERS,
        payload
    });
};

export const createCustomer = (data) => async (dispatch) => {
    try {
        const newCustomer = await customerApi.postCustomer(data);
        dispatch(setCurrentCustomerData(newCustomer));
        dispatch(loadCustomers());
        return false;
    } catch (e) {
        toastr.error(e.data.message ? e.data.message : 'Something went wrong');
        return true;
    }
};
