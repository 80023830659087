import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { FieldContainer, FieldLabel } from '../common/FieldContainer';
import { PerformedByField } from '../common/FieldContainer/fields';
import { getServices } from '../../api/invoiceApi';
import { toLocaleString } from '../../collums-components/helpers/index';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { searchFieldStyles } from '../common/FieldContainer/styles';
import { withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setServicesSelected } from '../../redux/actions/selectedServices';
import { getServicePrice } from '../../services/helpers';
import { SERVICE_AVAILABILITIES } from '../../collums-constants';
import { getLocationItem } from '../../collums-constants/utils';
import { toastr } from 'react-redux-toastr';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';

function AddInvoiceItemServicePanel({ addInvoiceItem, classes, clinic, allTaxes, loggedStaff }) {
    const [service, setService] = useState(undefined);
    const [soldBy, setSoldBy] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const dispatch = useDispatch();
    const serviceList = useSelector((store) => store.selectedServices) || [];
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setSoldBy(loggedStaff);
    }, [loggedStaff]);

    useEffect(() => {
        if (serviceOptions.length !== 0) setLoading(false);
    }, [serviceOptions]);

    useEffect(() => {
        try {
            getServices(searchValue, 50, false, true, clinic).then((res) =>
                setServiceOptions(
                    res.items.filter((service) => service.availability !== SERVICE_AVAILABILITIES.BOOK_ONLY)
                )
            );
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        }
    }, [searchValue, clinic]);

    const getServicePrices = useCallback(() => {
        if (!service) return 0;
        if (!soldBy) return getLocationItem(service, clinic).grossPrice;
        getLocationItem(service, clinic).itemTax = (allTaxes || []).find(
            (el) => el.id === getLocationItem(service, clinic).taxType
        ).rate;
        return getServicePrice(getLocationItem(service, clinic), soldBy.id);
        // eslint-disable-next-line
    }, [soldBy, service, clinic]);

    /**
     * Create an object that contains the values to submit to an API
     *
     * @param {Object} event Event object received from `onSubmit` present
     * on the form
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        if (!service) {
            setIsLoading(false);
            return toastr.error('Missing service', 'Select a service to proceed');
        }
        if (!soldBy) {
            setIsLoading(false);
            return toastr.error('Missing performed by', 'Select Performed By to proceed');
        }

        const { taxType, name, discount, id } = service;

        const serviceValues = getServicePrices();
        const defaultNetPrice = serviceValues.netPrice;
        const defaultGrossPrice = serviceValues.grossPrice;

        const item = {
            id: id,
            netPrice: defaultNetPrice,
            type: 'Service',
            name: name,
            listPrice: defaultNetPrice,
            practitioner: soldBy,
            soldBy: soldBy,
            quantity: 1,
            finalPrice: defaultGrossPrice,
            discount: discount || 0,
            tax:
                allTaxes.find((tax) => {
                    return tax.id === (getLocationItem(service, clinic)?.taxType || taxType);
                })?.rate || 0
        };

        addInvoiceItem(item, setIsLoading);
        setService(null);
        if (serviceList.some((el) => el.id === service.id)) {
            dispatch(
                setServicesSelected(
                    serviceList.map((el) => {
                        if (el.id === service.id) return { ...service };
                        return el;
                    })
                )
            );
        } else {
            dispatch(setServicesSelected([...serviceList, service]));
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {/** Service field */}
                    <FieldContainer>
                        <FieldLabel>Service *</FieldLabel>

                        <Autocomplete
                            id={'Service-autocomplete-field'}
                            options={serviceOptions}
                            noOptionsText={'No results'}
                            loading={loading}
                            getOptionLabel={(option) =>
                                `${option.name}, ${toLocaleString(getLocationItem(option, clinic).grossPrice)}`
                            }
                            onInputChange={(e, value) => setSearchValue(value)}
                            onChange={(e, value) => setService(value)}
                            className={`${classes.root} ${classes.autocomplete}`}
                            renderInput={(params) => {
                                if (loading) {
                                    return <CircularProgress color="inherit" size={20} />;
                                }
                                return <TextField {...params} variant="outlined" />;
                            }}
                        />
                    </FieldContainer>
                    {/* <Grid item>
                    <Checkbox checked={redeem} onChange={() => setRedeem(!redeem)} />
                    <Typography component="span">Redeem today&apos;s treatment from course?</Typography>
                </Grid> */}

                    {/** Price field */}
                    <FieldContainer>
                        <FieldLabel>Price</FieldLabel>
                        <Grid item>
                            <TextField
                                disabled
                                className={classes.inputPrice}
                                value={
                                    getServicePrices().grossPrice || getServicePrices().grossPrice === 0
                                        ? toLocaleString(getServicePrices().grossPrice)
                                        : toLocaleString(getServicePrices())
                                }
                            />
                        </Grid>
                    </FieldContainer>

                    {/** Performed By field */}
                    <PerformedByField value={soldBy} onChange={(value) => setSoldBy(value)} />
                </Grid>
            </form>
            {isLoading && <LoadingScreen />}
        </>
    );
}

AddInvoiceItemServicePanel.propTypes = {
    addInvoiceItem: PropTypes.func.isRequired,
    classes: PropTypes.object,
    loggedStaff: PropTypes.object,
    clinic: PropTypes.string.isRequired,
    allTaxes: PropTypes.array
};

export default withStyles(searchFieldStyles)(AddInvoiceItemServicePanel);
