import { createMuiTheme } from '@material-ui/core';
const theme = createMuiTheme({
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl', 'ipadPro', 'desktop'],
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
            ipadPro: 1355,
            desktop: 1500
        }
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
            disableTouchRipple: true
        }
    },
    palette: {
        primary: {
            main: '#3c3c3d'
        },
        secondary: {
            main: '#69767E'
        },
        white: {
            main: '#ffffff'
        },
        black: {
            main: '#343232',
            full: '#000000'
        },
        blue: {
            main: '#2b78e4'
        },
        red: {
            main: '#FB4336'
        },
        gray: {
            main: '#69767E',
            bold: '#565A65',
            light: '#cccccc',
            lighter: '#eeeeee'
        },
        wine: {
            main: '#b41778',
            hover: '#912d6b'
        },
        event: {
            primary: '#3c3c3d',
            secondary: '#9d9d9f',
            checkedIn: '#3da768',
            checkedOut: '#2d3548',
            late: '#ef4842',
            missed: '#8a211f',
            block: '#ffffff',
            break: '#aadcf3'
        },
        appointment: '#32384b',
        break: '#aadcf3',
        block: '#6bcbd9',
        lightGrey: {
            main: '#575A64'
        }
    },
    typography: {
        fontFamily: ['Gilmer Regular', 'Roboto'],
        fontSize: 12,
        h6: {
            fontSize: '0.67rem',
            fontFamily: 'Gilmer Medium'
        },
        h5: {
            fontSize: '0.83rem',
            fontFamily: 'Gilmer Medium'
        },
        h4: {
            fontSize: '1rem',
            fontFamily: 'Gilmer Medium'
        },
        h3: {
            fontSize: '1.17rem',
            fontFamily: 'Gilmer Medium'
        },
        h2: {
            fontSize: '1.5rem',
            fontFamily: 'Gilmer Medium'
        },
        h1: {
            fontSize: '2rem',
            fontFamily: 'Gilmer Medium'
        }
    },
    overrides: {
        MuiButton: {
            root: {
                fontSize: 10
            }
        },
        MuiOutlinedInput: {
            root: {
                height: 38,
                lineHeight: '10px',
                fontSize: 12,
                fontFamily: 'Gilmer Medium'
            }
        },
        MuiInputLabel: {
            root: {
                lineHeight: '1px',
                color: '#333'
            }
        },
        MuiSelect: {
            root: {
                fontSize: 12,
                fontFamily: 'Gilmer Medium'
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: 12
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: 24
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 10
            }
        },
        MuiDialogActions: {
            root: {
                justifyContent: 'center'
            }
        },
        MuiAutocomplete: {
            inputRoot: {
                fontSize: 12,
                fontFamily: 'Gilmer Medium',
                height: 'auto'
            },
            option: {
                fontSize: 12,
                fontFamily: 'Gilmer Regular'
            }
        },
        MuiTableCell: {
            head: {
                fontFamily: 'Gilmer Bold'
            }
        }
    },
    zIndex: {
        spinner: 2000,
        appBar: 1201,
        // defaults
        mobileStepper: 1000,
        speedDial: 1050,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500
    }
});
export default theme;
