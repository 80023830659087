import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, TextField, Typography, Grid } from '@material-ui/core';
import { SoldByField } from '../common/FieldContainer/fields';
import { FieldContainer } from '../common/FieldContainer';
import { toastr } from 'react-redux-toastr';
import { INVOICE_PAYMENT_TYPES, ORGANISATION_VOUCHER_DEFAULT } from '../../collums-constants/index';
import GiftcardApi from '../../api/GiftcardApi';
import { useDispatch, useSelector } from 'react-redux';
import { setOrganisation } from '../../redux/actions/organisationActions';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';

function CreateGifctcardPanel({ addInvoiceItem, loggedStaff }) {
    const [value, setValue] = useState('');
    const [soldBy, setSoldBy] = useState(undefined);
    const [code, setCode] = useState(undefined);
    const [universalUse, setUniversalUse] = useState(true);
    const organisationState = useSelector((state) => state.organisation.organisation);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const setNextAvailableCode = async () => {
            const organisationCopy = { ...organisationState };

            const code = await GiftcardApi.getNextAvailableCode(ORGANISATION_VOUCHER_DEFAULT);

            if (code) {
                organisationCopy.nextVoucherCode = code;
                dispatch(setOrganisation(organisationCopy));
            }
        };

        if (organisationState && organisationState?.nextVoucherCode) {
            setCode(organisationState?.nextVoucherCode.toString());
        } else {
            setNextAvailableCode();
        }
    }, [dispatch, organisationState]);

    const handleCreateGiftcard = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!code) {
            setIsLoading(false);
            return toastr.error('Missing code', 'Insert Code to proceed');
        }

        if (!value) {
            setIsLoading(false);
            return toastr.error('Missing gift voucher price', 'Insert Price to proceed');
        }

        if (!soldBy) {
            setIsLoading(false);
            return toastr.error('Missing sold by', 'Select Sold By to proceed');
        }

        if (value !== 0 && !!soldBy && !!code) {
            try {
                await GiftcardApi.checkAvailable(code);

                const newGiftcard = {
                    code: code,
                    universalUse,
                    type: INVOICE_PAYMENT_TYPES.GIFTCARD,
                    practitioner: soldBy,
                    soldBy: soldBy,
                    discount: 0,
                    tax: 0,
                    taxValue: 0,
                    listPrice: Number(value),
                    finalPrice: Number(value),
                    quantity: 1,
                    name: `Gift voucher ${code}`,
                    netPrice: Number(value)
                };
                addInvoiceItem(newGiftcard, setIsLoading);
                setValue('');
            } catch (err) {
                if (typeof err === 'object') {
                    if (err.data && err.data.message) {
                        toastr.error(err.data.message);
                        return;
                    }
                }
                toastr.error('Something went wrong');
            }
        }
    };

    useEffect(() => {
        setSoldBy(loggedStaff);
    }, [loggedStaff]);

    return (
        <>
            <form onSubmit={handleCreateGiftcard}>
                <Grid display="flex" flexDirection="column" justifyContent="start" container>
                    <FieldContainer>
                        <Grid item lg={3}>
                            <Typography>Gift voucher no *</Typography>
                        </Grid>
                        <Grid lg>
                            <TextField
                                style={{ width: '60%', marginBottom: '3%' }}
                                onChange={(e) => setCode(e.target.value)}
                                value={code}
                                variant="outlined"
                                type="text"
                            />
                        </Grid>
                    </FieldContainer>
                    <FieldContainer>
                        <Grid item lg={3}>
                            <Typography>Price *</Typography>
                        </Grid>
                        <Grid lg>
                            <TextField
                                style={{ width: '60%' }}
                                onChange={(e) => setValue(e.target.value)}
                                value={value}
                                variant="outlined"
                                type="number"
                                inputProps={{
                                    min: 0
                                }}
                            />
                        </Grid>
                    </FieldContainer>

                    <br />
                    <br />

                    <FieldContainer>
                        <Grid item style={{ display: 'none' }}>
                            <Box display="flex" justifyContent="start" alignItems="center">
                                <Checkbox
                                    name="isUniversalUse"
                                    defaultChecked={universalUse}
                                    onChange={(e, value) => setUniversalUse(value)}
                                />
                                <Typography>Multi Location Use</Typography>
                            </Box>
                        </Grid>
                    </FieldContainer>
                    <SoldByField value={soldBy} onChange={(value) => setSoldBy(value)} />
                </Grid>
            </form>
            {isLoading && <LoadingScreen />}
        </>
    );
}

CreateGifctcardPanel.propTypes = {
    addInvoiceItem: PropTypes.func,
    loggedStaff: PropTypes.object
};

export default CreateGifctcardPanel;
