import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import SaleView from './components/sale/SaleView';
import AuthApi from './collums-components/api/AuthApi';
import { setSentryUser } from './collums-components/helpers/sentry';
import GenericErrorBoundaryFallback from './collums-components/components/common/GenericErrorBoundaryFallback';
import * as Sentry from '@sentry/react';

function Routes() {
    const [sentryDialogOptions, setSentryDialogOptions] = useState({});

    useEffect(() => {
        (async () => {
            const currentUser = await AuthApi.getMe();
            const dialogOpts = setSentryUser(currentUser);
            setSentryDialogOptions(dialogOpts);
        })();
    }, []);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const param = new URL(window.location.href).searchParams.get('invoice');
        if (location.pathname === '/') {
            if (param) {
                history.push('sale?invoice=' + param);
            } else {
                history.push('sale');
            }
        }
    }, [history, location]);

    return (
        <Switch>
            <Sentry.ErrorBoundary
                showDialog
                dialogOptions={sentryDialogOptions}
                fallback={<GenericErrorBoundaryFallback />}
            >
                <Route exact path="/sale" component={SaleView} />
            </Sentry.ErrorBoundary>
        </Switch>
    );
}

export default Routes;
