import { toastr } from 'react-redux-toastr';
import { addInvoicePayment, saveDraft } from '../api/invoiceApi';
// import { getLatestCheckout } from '../api/SellerApi';
import { INVOICE_ORIGIN } from '../collums-constants/index';
import { assignInvoiceWithItems } from '../api/appointmentApi';

const errorHandler = (error) => {
    switch (true) {
        case error.startsWith('Invalid card number'):
            return 'Card number is not correct';
        case error.startsWith('Invalid card expiration date'):
            return 'Expiry date is not correct';
        case error.startsWith('Card verification code check failed'):
            return ' CVV is not correct';
        case error.startsWith('Postal code check failed'):
            return 'Postal code is not correct';
        default:
            return error || 'Unhandled request';
    }
};
export default async function processPayments(
    payments,
    invoiceData,
    { invoice, paymentsPayload = [], customerId },
    isFromCreditCard = false,
    notifications,
    currentClinic,
    appointment = null
) {
    if (!(payments || []).length) return;

    const { items, invoiceDiscount, invoicePrice, temporaryDiscount, discountProperties } = invoiceData;

    if (!customerId) return;

    let data;

    if (isFromCreditCard) {
        payments = [...payments, ...paymentsPayload];
    }

    if (!invoice) {
        const discountProps = (() => {
            if (discountProperties && !temporaryDiscount?.value) {
                return {
                    value: discountProperties.value,
                    type: discountProperties.type || 'percentage'
                };
            }
        })();

        items.forEach((item) => (item.location = currentClinic));

        const res = await saveDraft({
            customer: customerId,
            items,
            discount: invoiceDiscount,
            discountProperties: discountProps,
            description: 'Invoice from POS',
            origin: INVOICE_ORIGIN.SALE,
            amount: invoicePrice,
            temporaryDiscount: temporaryDiscount?.value || undefined,
            clinic: currentClinic
        });

        if (res && appointment) {
            // Assign appointment to invoice with invoice items
            await assignInvoiceWithItems(appointment, res.id, items);
        }

        data = {
            id: res.id,
            customer: res.customer,
            code: res.code
        };
    } else {
        data = {
            id: invoice.id,
            customer: invoice.customer,
            code: invoice.code
        };
        if (invoice.taxPercentages !== undefined) data.taxPercentages = invoice.taxPercentages;
    }

    // setTimeout(async () => {
    //     const invoice = await getLatestCheckout(data.id);
    //     return invoice.id;
    // }, 12000);

    try {
        const response = await addInvoicePayment({
            invoice: data,
            payments,
            notifications
        });
        return {
            id: data.id,
            coherentUrl: response?.coherentUrl,
            coherentTransactionId: response?.coherentTransactionId
        };
    } catch (e) {
        toastr.error(errorHandler(e?.data?.message));
        return;
    }
}
