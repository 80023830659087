import {
    INVOICE_FETCHED,
    SET_IS_EXCHANGE,
    SET_INVOICE_PRICE,
    SET_INVOICE_DISCOUNT,
    SET_TEMPORARY_DISCOUNT,
    SET_APPOINTMENT
} from '../constants/invoice';

const initialValue = {
    invoice: null,
    isExchange: false,
    invoicePrice: 0,
    invoiceDiscount: 0,
    selectedDiscount: {},
    appointment: null
};

const invoiceReducer = (state = initialValue, action) => {
    switch (action.type) {
        case INVOICE_FETCHED:
            return {
                ...state,
                invoice: action.payload
            };

        case SET_IS_EXCHANGE:
            return {
                ...state,
                isExchange: action.payload
            };

        case SET_INVOICE_PRICE:
            return {
                ...state,
                invoicePrice: action.payload
            };

        case SET_INVOICE_DISCOUNT:
            return {
                ...state,
                invoiceDiscount: action.payload
            };

        case SET_TEMPORARY_DISCOUNT:
            return {
                ...state,
                selectedDiscount: action.payload
            };
        case SET_APPOINTMENT:
            return {
                ...state,
                appointment: action.payload
            };
        default:
            return state;
    }
};

export default invoiceReducer;
