import React from 'react';
import { withStyles, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { saleInvoiceNoteStyles as styles } from './styles';

const SaleInvoiceNote = ({
    customerId,
    invoiceNote,
    setInvoiceNote,
    classes,
    defaultPlaceholder,
    showSavedNote,
    lastSavedNote,
    invoiceStatus
}) => {
    if (!customerId) {
        return null;
    }
    return (
        <div style={{ width: '40%' }}>
            <Typography className={classes.invoiceNotesTitle}>Invoice Notes</Typography>
            {showSavedNote && <Typography>{showSavedNote}</Typography>}
            {lastSavedNote && <Typography>{lastSavedNote}</Typography>}
            {invoiceStatus !== 'Paid' && (
                <TextField
                    className={classes.noteInput}
                    variant="outlined"
                    multiline
                    rows="1"
                    disabled={invoiceStatus === 'Paid' || invoiceStatus === 'Refund' || invoiceStatus === 'Void'}
                    value={invoiceNote}
                    onChange={(event) => setInvoiceNote(event.target.value)}
                    placeholder={defaultPlaceholder}
                    style={{ width: '100%' }}
                />
            )}
        </div>
    );
};

SaleInvoiceNote.propTypes = {
    customerId: PropTypes.string,
    invoiceNote: PropTypes.string,
    setInvoiceNote: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    defaultPlaceholder: PropTypes.string,
    showSavedNote: PropTypes.string,
    lastSavedNote: PropTypes.string,
    invoiceStatus: PropTypes.string
};

export default withStyles(styles)(SaleInvoiceNote);
