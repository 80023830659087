import api from './api';

export const UpdateXeroItem = async (payload) => {
    return api
        .request({
            method: 'POST',
            url: '/xero/update/items',
            data: payload
        })
        .then((res) => res)
        .catch((err) => err);
};
