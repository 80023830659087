import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles, Select, MenuItem } from '@material-ui/core';
import { FieldContainer, FieldLabel } from '../common/FieldContainer';
import { SoldByField } from '../common/FieldContainer/fields';
import { toLocaleString, useDebounce } from '../../collums-components/helpers/index';

import { getCourses } from '../../api/invoiceApi';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { searchFieldStyles } from '../common/FieldContainer/styles';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import { getApptByInvoiceId } from '../../api/appointmentApi';
import { addLinkedCourseItem } from '../../redux/actions/invoiceActions';
import { INVOICE_ORIGIN } from '../../collums-constants/index';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';
import { getLocationItem } from '../../collums-constants/utils';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';

function AddInvoiceItemCoursePanel({ addInvoiceItem, classes, clinic, allTaxes, loggedStaff }) {
    const [courseOptions, setCourseOptions] = useState([]);
    const [course, setCourse] = useState(undefined);
    const [soldBy, setSoldBy] = useState(undefined);
    const [value, setValue] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [service, setService] = useState([]);
    const debounce = useDebounce(value, 600);
    const currentInputValueRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const { invoice } = useSelector((state) => state.invoice);

    const dispatch = useDispatch();

    /**
     * Create an object that contains the values to submit to an API
     *
     * @param {Object} event Event object received from `onSubmit` present
     * on the form
     */

    const getServices = () => {
        const items = invoice?.items;
        const services = (items || []).filter(
            (item) => item.type === 'Service' && item.linkedInvoiceItem === undefined
        );
        if (course) {
            const filteredServices = services.filter(
                (service) => service.referenceId === course.service.id && !service.isRedemption
            );
            return filteredServices;
        }

        return services;
    };

    useEffect(() => {
        const services = getServices();
        if (Array.isArray(services)) {
            setService(services);
        } else if (services) {
            setService([services]);
        }
        // eslint-disable-next-line
    }, [course]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!course) return toastr.error('Missing course', 'Select a course to proceed');
        if (!soldBy) {
            return toastr.error('Missing sold by', 'Select Sold By to proceed');
        }

        if (invoice) {
            const services = getServices();
            const hasService = services.find((service) => service.referenceId === course.service.id);
            const appointment = await getApptByInvoiceId(invoice.id);

            if (hasService && appointment) setIsModalOpen(true);
            else handleAddInvoiceItem();
        } else {
            handleAddInvoiceItem();
        }
    };

    const handleAddInvoiceItem = () => {
        setIsLoading(true);
        const { label, discount, id } = course;

        const tax = getLocationItem(course, clinic).tax ?? course.tax;

        const taxValue = allTaxes.find((_tax) => _tax.id === tax)?.rate;

        // Remove Tax from net price
        const salePrice = getLocationItem(course, clinic).salePrice ?? course.salePrice;
        const netPrice = salePrice / ((taxValue + 100) / 100);

        const item = {
            id: id,
            netPrice,
            type: 'Course',
            name: label,
            listPrice: netPrice,
            practitioner: soldBy,
            soldBy: soldBy,
            quantity: 1,
            finalPrice: salePrice,
            discount: discount,
            tax: taxValue,
            taxValue: allTaxes.find((_tax) => _tax.id === getLocationItem(course, clinic).tax)?.rate
        };

        addInvoiceItem(item, setIsLoading);
        setCourse(null);
    };

    const handleRedeemCourse = async () => {
        setIsLoading(true);
        const { label, discount, id } = course;

        const tax = getLocationItem(course, clinic).tax || course.tax;

        const taxValue = allTaxes.find((_tax) => _tax.id === tax)?.rate || 0;
        // Remove Tax from net price
        const salePrice = getLocationItem(course, clinic).salePrice ?? course.salePrice;
        const netPrice = salePrice / ((taxValue + 100) / 100);

        const newCourse = {
            referenceId: id,
            discount: discount,
            invoice: invoice.id,
            type: 'Course',
            tax: invoice.id ? taxValue / 100 : taxValue,
            listPrice: netPrice,
            finalPrice: salePrice,
            quantity: 1,
            name: label,
            netPrice: netPrice,
            origin: INVOICE_ORIGIN.SALE,
            soldBy: soldBy.id
        };
        await dispatch(addLinkedCourseItem(newCourse, service, invoice.id));
        setIsLoading(false);

        setService([]);
    };

    useEffect(() => {
        async function fetch() {
            const pageSize = 50;
            const filterByLastUsed = false;
            const data = await getCourses(value, pageSize, filterByLastUsed, clinic, false, true);
            if (value !== currentInputValueRef.current) {
                return;
            }
            const parseData = data.map((course) => {
                const salePrice = getLocationItem(course, clinic).salePrice || course.salePrice;
                return {
                    ...course,
                    label: `${course.name}, ${toLocaleString(salePrice)}`,
                    value: course.id,
                    discount: course.discount || 0
                };
            });
            setCourseOptions(parseData);
        }

        fetch();
        // eslint-disable-next-line
    }, [debounce, clinic]);

    useEffect(() => {
        setSoldBy(loggedStaff);
    }, [loggedStaff]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {/** Course field */}
                    <FieldContainer>
                        <FieldLabel>Course *</FieldLabel>
                        <Autocomplete
                            id={'Course-autocomplete-field'}
                            options={courseOptions}
                            noOptionsText={'No results'}
                            getOptionLabel={(option) => {
                                return option.label;
                            }}
                            onInputChange={(e, value) => {
                                currentInputValueRef.current = value;
                                setValue(value);
                            }}
                            onChange={(e, value) => {
                                setCourse(value);
                            }}
                            className={`${classes.root} ${classes.autocomplete}`}
                            renderInput={(params) => {
                                return <TextField {...params} variant="outlined" />;
                            }}
                        />
                    </FieldContainer>

                    {/** Price field */}
                    <FieldContainer>
                        <FieldLabel>Price</FieldLabel>
                        <Grid item>
                            <TextField
                                className={classes.inputPrice}
                                value={
                                    course
                                        ? toLocaleString(getLocationItem(course, clinic).salePrice || course.salePrice)
                                        : ''
                                }
                                disabled={true}
                            />
                        </Grid>
                    </FieldContainer>

                    {/** Sold by field */}
                    <SoldByField value={soldBy} onChange={(value) => setSoldBy(value)} />
                </Grid>
            </form>

            {isModalOpen && (
                <CancelContinueModal
                    setOpen={setIsModalOpen}
                    onContinue={() => {
                        if (service.length > 0) {
                            handleRedeemCourse();
                            setIsModalOpen(false);
                        } else {
                            toastr.error(
                                'Something went wrong',
                                'You need to select at least one service before confirming this action'
                            );
                        }
                    }}
                    closeOnBlur={false}
                    title="Redeem today's treatment from course?"
                    content={
                        <Select
                            style={{
                                width: 220
                            }}
                            labelId="service"
                            id="service"
                            value={service}
                            multiple
                            onChange={(e) => {
                                setService(e.target.value);
                            }}
                            variant="outlined"
                        >
                            {invoice &&
                                getServices().map((service, index) => (
                                    <MenuItem key={index} value={service} className={classes.select}>
                                        {service.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    }
                />
            )}
            {isLoading && <LoadingScreen />}
        </>
    );
}

AddInvoiceItemCoursePanel.propTypes = {
    addInvoiceItem: PropTypes.func.isRequired,
    classes: PropTypes.object,
    loggedStaff: PropTypes.object,
    clinic: PropTypes.string.isRequired,
    allTaxes: PropTypes.array.isRequired
};

export default withStyles(searchFieldStyles)(AddInvoiceItemCoursePanel);
