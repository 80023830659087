import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '../common/TextField';
import { FieldContainer, FieldLabel } from '../common/FieldContainer';
import { SoldByField } from '../common/FieldContainer/fields';
import { useSelector } from 'react-redux';
import { INVOICE_PAYMENT_TYPES } from '../../collums-constants/index';
import { toastr } from 'react-redux-toastr';
import { getCurrencySymbol } from '../../collums-components/helpers';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';

function AddInvoiceItemAccountPanel({ addInvoiceItem, loggedStaff }) {
    const [price, setPrice] = useState('');
    const [soldBy, setSoldBy] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const currentUser = useSelector((state) => state.currentCustomer.customerId);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (!price) {
            setIsLoading(false);
            return toastr.error('Missing price', 'Insert Price to proceed');
        }

        if (!soldBy) {
            setIsLoading(false);
            return toastr.error('Missing sold by', 'Select Sold By to proceed');
        }

        const formattedPrice = price ? price.trim().replace(',', '') : price;
        const item = {
            netPrice: Number(formattedPrice),
            type: INVOICE_PAYMENT_TYPES.ACCOUNT_BALANCE,
            name: 'Account',
            listPrice: Number(formattedPrice),
            practitioner: soldBy,
            soldBy: soldBy,
            finalPrice: Number(formattedPrice),
            discount: 0,
            tax: 0,
            taxValue: 0,
            id: currentUser,
            quantity: 1
        };

        addInvoiceItem(item, setIsLoading);
        setPrice('');
    };

    useEffect(() => {
        setSoldBy(loggedStaff);
    }, [loggedStaff]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {/** Value field */}
                    <FieldContainer>
                        <FieldLabel>Price ({getCurrencySymbol()}) *</FieldLabel>
                        <Grid item>
                            <TextField value={price} onChange={(value) => setPrice(value)} />
                        </Grid>
                    </FieldContainer>

                    {/** Sold By field */}
                    <SoldByField value={soldBy} onChange={(value) => setSoldBy(value)} />
                </Grid>
            </form>
            {isLoading && <LoadingScreen />}
        </>
    );
}

AddInvoiceItemAccountPanel.propTypes = {
    addInvoiceItem: PropTypes.func.isRequired,
    loggedStaff: PropTypes.object
};

export default AddInvoiceItemAccountPanel;
