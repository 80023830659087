import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { toLocaleString } from '../../collums-components/helpers/index';

import { Box, Typography, Table, TableHead, TableBody, TableRow, TableCell, withStyles } from '@material-ui/core';
import { paymentTable as styles } from './styles';

import { getInvoicePayments } from '../../api/invoiceApi';

const tableHeaders = [
    { id: 'createdAt', label: 'Date/Item' },
    { id: 'takenBy', label: 'Taken By' },
    { id: 'method', label: 'Method' },
    { id: 'amount', label: 'Amount' }
];

function PaymentTable({ classes }) {
    const [payments, setPayments] = useState([]);
    const [paymentsTotal, setPaymentsTotal] = useState(0);
    const { invoice } = useSelector((state) => state.invoice);

    useEffect(() => {
        const fetchPaymentsForInvoice = async () => {
            if (!invoice?.id) {
                setPayments([]);
                setPaymentsTotal(0);
            }
            const fetchedPayments = await getInvoicePayments(invoice.id);
            const paymentSum = fetchedPayments.reduce((accumulator, payment) => (accumulator += payment.amount), 0);
            setPayments(fetchedPayments);
            setPaymentsTotal(paymentSum);
        };

        fetchPaymentsForInvoice();
    }, [invoice]);

    return (
        <div>
            <Box className={classes.tableTitle}>
                <Typography>Payment</Typography>
            </Box>
            <Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableHeaders.map((column) => (
                                <TableCell key={column.id} className={classes.tableHeader}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payments.map((payment) => (
                            <TableRow key={payment.id}>
                                <TableCell>{format(new Date(payment.createdAt), 'yyyy-MM-dd HH:mm')}</TableCell>
                                <TableCell>{payment.takenBy ? payment.takenBy.displayName : ''}</TableCell>
                                <TableCell>
                                    {payment.method} {payment.cardBrand && `${payment.cardBrand} ${payment.cardLast4}`}
                                </TableCell>
                                <TableCell>{toLocaleString(payment.amount)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            <Box className={classes.flexBoxBoldText}>
                <Typography>Total</Typography>
                <Typography>{toLocaleString(paymentsTotal)}</Typography>
            </Box>
        </div>
    );
}

PaymentTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PaymentTable);
