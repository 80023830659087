import api from './config';

class ListApi {
    static getAll() {
        return api.request({
            method: 'GET',
            url: '/lists'
        });
    }

    static updateOrganisation(data) {
        return api.request({
            method: 'PUT',
            url: '/lists',
            data
        });
    }
}

export default ListApi;
