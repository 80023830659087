import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import enGbLocale from 'date-fns/locale/en-GB/index';

import App from './components/App';
import Routes from './routes';
import theme from './theme';
import * as serviceWorker from './serviceWorker';
import './styles/index.css';
import ReduxToastr from 'react-redux-toastr';
import { Provider } from 'react-redux';
import store from './redux/configureStore';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { AccessTierProvider } from './collums-components/hooks/accessTier';
import moment from 'moment-timezone';
import { initSentry } from './collums-components/helpers/sentry';

initSentry();
moment.tz.setDefault(process.env.REACT_APP_TIMEZONE || 'Europe/London');

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <CssBaseline />
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGbLocale}>
                    <BrowserRouter>
                        <AccessTierProvider>
                            <App>
                                <ReduxToastr
                                    timeOut={3000}
                                    newestOnTop={false}
                                    preventDuplicates
                                    position="bottom-left"
                                    getState={(state) => state.toastr}
                                    transitionIn="fadeIn"
                                    transitionOut="fadeOut"
                                    progressBar
                                    closeOnToastrClick
                                />
                                <Routes />
                            </App>
                        </AccessTierProvider>
                    </BrowserRouter>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </React.StrictMode>
    </Provider>,

    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
