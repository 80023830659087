import React from 'react';
import PropTypes from 'prop-types';
import { coherentIframeStyles as styles } from './styles';
import { Button, withStyles } from '@material-ui/core';

const CoherentCreditCardForm = ({ classes, coherentUrl, resetHandler }) => {
    return (
        <div>
            {coherentUrl && (
                <>
                    <iframe id="coherent-iframe" className={classes.iframe} src={coherentUrl} title="coherent-iframe" />
                    <div className={classes.actions}>
                        <Button
                            onClick={() => {
                                resetHandler();
                            }}
                            variant="outlined"
                        >
                            Start again
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

CoherentCreditCardForm.propTypes = {
    resetHandler: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    coherentUrl: PropTypes.string.isRequired
};
export default withStyles(styles)(CoherentCreditCardForm);
