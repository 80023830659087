import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '../common/Select';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { FieldContainer, FieldLabel } from '../common/FieldContainer';
import { SoldByField } from '../common/FieldContainer/fields';
import withStyles from '@material-ui/core/styles/withStyles';
import { searchFieldStyles } from '../common/FieldContainer/styles';

import { validateAddProduct } from '../../services/helpers';
import { calculateTaxValue, calculateTax } from '../../services/invoice';
import { getProducts } from '../../api/invoiceApi';
import { getCustomerStockHistory } from '../../api/productHistory';
import SaleProductHistory from './SaleProductHistory';
import ConfirmBelowStockModal from './ConfirmBelowStockModal';
import StockLimitModal from './StockLimitModal';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { setIsExchange } from '../../redux/actions/invoiceActions';
import { toLocaleString } from '../../collums-components/helpers';
import { toastr } from 'react-redux-toastr';
import { useDebounce } from '../../services/helpers.js';
import { getLocationItem } from '../../collums-constants/utils';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';

function AddInvoiceItemProductPanel({
    customerId,
    addInvoiceItem,
    verifyInvoiceQuantity,
    classes,
    handleAddMultipleProducts,
    clinic,
    allTaxes,
    loggedStaff
}) {
    // const barcode = useRef('');
    // const [isScanning, setIsScanning] = useState(false);
    const [productOptions, setProductOptions] = useState([]);
    const [product, setProduct] = useState(null);
    const [quantity, setQuantity] = useState('');
    const [soldBy, setSoldBy] = useState(undefined);
    const [isVisible, setIsVisible] = useState(false);
    const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
    const [stockLimitModal, setStockLimitModal] = useState(false);
    const [confirmAddItem, setConfirmAddItem] = useState(() => {});
    const [value, setValue] = useState(null);
    const currentSearchValue = useRef(null);
    const searchDebounced = useDebounce(value, 500);
    const [isLoading, setIsLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const dispatch = useDispatch();

    // Array for a limited number of quantity options
    const quantityOptions = Array.from(new Array(5), (_, i) => ({
        label: `${i + 1}`,
        value: `${i + 1}`
    }));

    useEffect(() => {
        setSoldBy(loggedStaff);
    }, [loggedStaff]);

    /**
     * Create an object that contains the values to submit to an API
     *
     * @param {Object} event Event object received from `onSubmit` present
     * on the form
     */
    const handleSubmit = (event) => {
        event.preventDefault();

        if (!product) {
            return toastr.error('Missing product', 'Select Product to proceed');
        }

        if (!quantity) {
            return toastr.error('Missing quantity', 'Select Quantity to proceed');
        }

        if (!soldBy) {
            return toastr.error('Missing sold by', 'Select Sold By to proceed');
        }

        if (validateAddProduct(product, soldBy, quantity)) {
            const { name, stock, id, autoStockControl, allowNegative } = product;

            /**
             * Location Values
             */

            const { netPrice, grossPrice, tax } = getLocationItem(product, clinic);
            const itemTax = allTaxes.find((localTax) => localTax.id === (tax || product.tax));
            const taxValue = calculateTaxValue(netPrice, itemTax.rate);

            const item = {
                id,
                netPrice: netPrice,
                type: 'Product',
                name: name,
                listPrice: netPrice,
                practitioner: soldBy,
                soldBy: soldBy,
                quantity: parseInt(quantity),
                finalPrice: grossPrice,
                discount: 0,
                tax: itemTax.rate,
                taxValue
            };
            const stockResult = stock - verifyInvoiceQuantity(product);
            if (autoStockControl && allowNegative && quantity > stockResult) {
                setConfirmAddItem(() => {
                    return () => {
                        setIsSending(true);
                        addInvoiceItem(item, setIsSending);
                        dispatch(setIsExchange(true));
                        setProduct(null);
                        setSoldBy(null);
                        setQuantity(0);
                    };
                });
                setIsConfirmationVisible(true);
            } else if (autoStockControl && !allowNegative && quantity > stockResult) {
                setStockLimitModal(true);
            } else {
                setProduct(null);
                setSoldBy(null);
                setQuantity(0);
                setIsSending(true);
                addInvoiceItem(item, setIsSending);
                dispatch(setIsExchange(true));
            }
        }
    };

    const dataParser = (data) => {
        return data.map((product) => {
            const { netPrice, tax } = getLocationItem(product, clinic);
            const itemTax = allTaxes.find((localTax) => localTax.id === tax);

            return {
                ...product,
                label: [
                    product.name,
                    `${toLocaleString(calculateTaxValue(netPrice, calculateTax(itemTax)).toFixed(2))}`
                ].join(', '),
                value: product.id
            };
        });
    };

    // const barcodeEvent = async (e) => {
    //     // Check is focused on giftvoucher input field and skip.
    //     if (e?.target.nodeName === 'INPUT' && e.target.id === 'giftvoucher') {
    //         return;
    //     }
    //
    //     if (e.key === 'Enter') {
    //         try {
    //             const data = await getProducts(value, 50, false, barcode.current, clinic);
    //             const parsedData = dataParser(data);
    //             setProduct(parsedData[0]);
    //             setProductOptions(parsedData);
    //             barcode.current = '';
    //         } catch (err) {
    //             return toastr.error(err?.data?.message || 'Something went wrong');
    //         }
    //     } else {
    //         if (e.key !== 'Backspace') barcode.current += e.key;
    //     }
    //
    //     if (!isScanning) {
    //         setIsScanning(true);
    //         setTimeout(() => {
    //             barcode.current = '';
    //             setIsScanning(false);
    //         }, 5000);
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('keydown', barcodeEvent);
    //     return () => {
    //         window.removeEventListener('keydown', barcodeEvent);
    //     };
    //     /* eslint-disable-next-line */
    // }, []);

    useEffect(() => {
        async function fetch() {
            // setProductOptions([]);
            setIsLoading(true);

            try {
                currentSearchValue.current = value;
                const data = await getProducts(
                    value === '' ? null : value,
                    value ? 20 : 50,
                    false,
                    '',
                    clinic,
                    'Retail,Prescription',
                    true,
                    true
                ).finally(() => {});
                const parseData = dataParser(data);

                if (value === currentSearchValue.current) {
                    setProductOptions(parseData);
                    if (parseData.length === 1 && /^\d+$/.test(currentSearchValue.current)) {
                        handleSetProduct(parseData[0]);
                        document.getElementById('product-autocomplete').click();
                    }
                }
            } catch (err) {
                return toastr.error(err?.data?.message || 'Something went wrong');
            } finally {
                setIsLoading(false);
            }
        }

        fetch();
        // eslint-disable-next-line
    }, [searchDebounced]);

    /* const getAvailabilityText = () => {
        return `${formatCurrencyFromValue(product.netPrice)} ${
            product.tax !== 0
                ? `(VAT: ${toLocaleString(calculateTaxValue(product.retailPrice, product.tax).toFixed(2))})`
                : ''
        }`;
    }; */
    const getProductAmount = () => {
        const productStock = (() => {
            if (!product.autoStockControl) {
                return 'N/A';
            }
            return product.stock || 0;
        })();
        return `Available: ${productStock} ${
            verifyInvoiceQuantity(product) > 0 ? `(Added: ${verifyInvoiceQuantity(product)})` : ''
        }`;
    };

    const handleSetProduct = async (value) => {
        setProduct(value);
        if (value) {
            const customerProductHistory = await getCustomerStockHistory(customerId, clinic);
            const lastSeller = await customerProductHistory.find((el) => el.referenceId === value.id);
            if (lastSeller) {
                setSoldBy(lastSeller.soldBy);
            }
            setQuantity(1);
        } else {
            setQuantity('');
            setSoldBy(undefined);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {/** Product search field */}
                    <FieldContainer>
                        <FieldLabel>Product *</FieldLabel>
                        <Autocomplete
                            id="product-autocomplete"
                            options={productOptions}
                            noOptionsText={'No results'}
                            getOptionLabel={(option) => {
                                return option.label;
                            }}
                            value={product}
                            onInputChange={(e, value) => setValue(value)}
                            onChange={(e, value) => handleSetProduct(value)}
                            style={{ marginLeft: '8px' }}
                            className={classes.autocomplete}
                            renderInput={(params) => <TextField {...params} onKeyDown={handleKeyDown} variant="outlined" />}
                            filterOptions={(x) => x}
                            loading={isLoading}
                        />
                        <Grid item lg={3}>
                            <Link href="#" onClick={() => setIsVisible(true)}>
                                <Typography style={{ color: '#386CD3' }}>Product history</Typography>
                            </Link>
                        </Grid>
                    </FieldContainer>
                    {/** Price field */}
                    <FieldContainer>
                        <FieldLabel>Price</FieldLabel>
                        <Grid item>
                            <TextField
                                className={classes.inputPrice}
                                value={product ? toLocaleString(getLocationItem(product, clinic).grossPrice) : ''}
                                disabled={true}
                            />
                        </Grid>
                    </FieldContainer>

                    {/** Quantity field */}
                    <FieldContainer>
                        <FieldLabel>Quantity *</FieldLabel>
                        <Grid item className={classes.quantityField}>
                            <Select
                                value={quantity}
                                onChange={(value) => {
                                    setQuantity(value);
                                }}
                                options={quantityOptions}
                            />
                            <Typography>{product ? `${getProductAmount()}` : ''}</Typography>
                        </Grid>
                    </FieldContainer>

                    {/** Sold By field */}
                    <SoldByField value={soldBy} onChange={(value) => setSoldBy(value)} />
                </Grid>
            </form>
            <SaleProductHistory
                clinic={clinic}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                handleAddMultipleProducts={handleAddMultipleProducts}
            />
            <ConfirmBelowStockModal
                isVisible={isConfirmationVisible}
                setIsVisible={setIsConfirmationVisible}
                addToInvoice={confirmAddItem}
            />
            {product && stockLimitModal && (
                <StockLimitModal
                    setIsVisible={setStockLimitModal}
                    productStock={product.stock - verifyInvoiceQuantity(product)}
                />
            )}
            {isSending && <LoadingScreen />}
        </div>
    );
}

AddInvoiceItemProductPanel.propTypes = {
    customerId: PropTypes.any,
    addInvoiceItem: PropTypes.func.isRequired,
    verifyInvoiceQuantity: PropTypes.func.isRequired,
    classes: PropTypes.object,
    handleAddMultipleProducts: PropTypes.func,
    clinic: PropTypes.string.isRequired,
    allTaxes: PropTypes.array.isRequired,
    loggedStaff: PropTypes.object
};

export default withStyles(searchFieldStyles)(AddInvoiceItemProductPanel);
