import api from './api';

class CustomerApi {
    static findCustomer(id) {
        return api.request({
            method: 'GET',
            url: `customers/${id}`
        });
    }

    static listCustomers(value, rowsPerPage, page, filterByLastUsed, sortByWalkIn) {
        return api.request({
            method: 'GET',
            url: '/customers',
            params: { value, rowsPerPage, page, filterByLastUsed, sortByWalkIn }
        });
    }

    static listAppointmentsByCustomer(id, params) {
        if (!id) return;

        return api.request({
            method: 'GET',
            url: `appointments/customer/${id}`,
            params
        });
    }

    static update(id, data) {
        if (!id) return;
        const customer = {
            title: data.title,
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            akaName: data.akaName,
            referalSource: data.referalSource,
            referredBy: data.referredBy,
            notes: data.notes,
            gender: data.gender,
            address1: data.address1,
            address2: data.address2,
            postal: data.postal,
            street: data.street,
            city: data.city,
            phone: data.phone,
            secondPhoneType: data.secondPhoneType,
            secondPhone: data.secondPhone,
            marketingTypes: data.marketingTypes,
            notificationTypes: data.notificationTypes,
            tags: data.tags,
            medicalHistory: data.medicalHistory,
            drugHistory: data.drugHistory,
            allergies: data.allergies
        };
        return api.request({
            method: 'PUT',
            url: `customers/${id}`,
            data: customer
        });
    }

    static fetchMessages(id, params) {
        return api.request({
            method: 'GET',
            url: `messages?customerId=${id}`,
            params
        });
    }

    static createMessage(data) {
        return api.request({
            method: 'POST',
            url: 'messages',
            data
        });
    }

    //----------
    static postCustomer(customer) {
        const getMarketingTypes = () => {
            const marketingTypes = [];
            if (customer.receiveMarketingEmails) marketingTypes.push('Email');
            if (customer.receiveMarketingSms) marketingTypes.push('SMS');

            return marketingTypes;
        };

        const getNotificationTypes = () => {
            const notificationTypes = [];
            if (customer.receiveNotificationEmails) notificationTypes.push('Email');
            if (customer.receiveNotificationSms) notificationTypes.push('SMS');

            return notificationTypes;
        };

        const getOtherPhones = () => {
            if (customer.otherPhoneNumber && customer.otherPhoneType) {
                return {
                    otherPhone: {
                        type: customer.otherPhoneType,
                        phone: customer.otherPhoneNumber
                    }
                };
            } else {
                return {};
            }
        };

        return api.request({
            method: customer.id ? 'PUT' : 'POST',
            url: `customers${customer.id ? `/user/${customer.id}` : ''}`,
            data: {
                title: customer.title,
                firstName: customer.firstName,
                middleName: customer.middleName,
                surname: customer.surname,
                prevSurname: customer.prevSurname,
                aka: customer.aka,
                dateOfBirth: customer.dateOfBirth,
                gender: customer.gender,
                mobilePhone: customer.mobilePhone,
                ...getOtherPhones(),
                email: (customer.email || '').toLowerCase(),
                postal: customer.postal,
                notes: customer.notes,
                address1: customer.address1,
                address2: customer.address2,
                city: customer.city,
                country: customer.country,
                state: customer.state,
                county: customer.county,
                marketingTypes: getMarketingTypes(),
                notificationTypes: getNotificationTypes(),
                referralSource: customer.referralSource,
                referredBy: customer.referredBy?.id,
                clinics: [localStorage.getItem('currentClinic')]
            }
        });
    }

    static async getCustomerBalance(customerId) {
        return api.request({
            method: 'GET',
            url: `/customers/account-balance/${customerId}`
        });
    }
}

export default CustomerApi;
