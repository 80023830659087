import React, { useState } from 'react';
import { FormControl, InputLabel, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

import { autocompleteStyles as styles } from './styles';

const AutocompleteInput = ({
    value,
    options,
    disable = false,
    onChange,
    onInputChange,
    placeholder,
    className,
    classes,
    noOptionsText
}) => {
    const [inputValue, setInputValue] = useState('');

    return (
        <div className={className}>
            <FormControl
                fullWidth
                classes={{
                    root: classes.formControl
                }}
                disabled={disable}
            >
                {placeholder && !value && !inputValue && (
                    <InputLabel className={classes.placeholder}>{placeholder}</InputLabel>
                )}
                <Autocomplete
                    disabled={disable}
                    value={options.find((option) => option.value === value) || null}
                    options={options}
                    noOptionsText={noOptionsText}
                    getOptionLabel={(option) => option.label || ''}
                    renderOption={(option) => (
                        <span className={option.bold ? classes.boldOption : ''}>{option.label}</span>
                    )}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    onChange={(event, value) => onChange && value && onChange(value)}
                    onInputChange={(event, value) => {
                        setInputValue(value);
                        if (!value) {
                            onChange && onChange(null);
                        }
                        if (onInputChange && event && 'change' === event.type) {
                            onInputChange(value);
                        }
                    }}
                />
            </FormControl>
        </div>
    );
};

AutocompleteInput.propTypes = {
    value: PropTypes.string,
    noOptionsText: PropTypes.string,
    disable: PropTypes.bool,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AutocompleteInput);
