import { LOAD_SERVICES } from '../constants/services';

const initialState = [];

const servicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_SERVICES:
            return [...state, ...action.payload];
        default:
            return state;
    }
};

export default servicesReducer;
