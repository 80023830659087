import { roundTwoDecimals } from './helpers';

export const createInvoiceItem = (data) => {
    const invoiceItemSchema = {
        practitioner: undefined,
        item: {
            type: undefined,
            name: undefined
        },
        amount: 0,
        listPrice: 0,
        netPrice: 0,
        tax: 0,
        taxValue: 0,
        discount: 0,
        finalPrice: 0
    };

    const newInvoiceItem = {
        ...invoiceItemSchema,
        ...data
    };

    return newInvoiceItem;
};

export const calculateTotalPrice = (netPrice, taxValue = 0, quantity = 1, discount = 0) => {
    const value = (+netPrice + taxValue) * quantity - +discount;
    return value;
};

export const calculateTax = (tax) => {
    if (tax && parseFloat(tax.rate, 10)) return parseFloat(tax.rate, 10);
    return 0;
};

export const calculateTaxValue = (netPrice, tax) => {
    const taxNumber = parseFloat(tax, 10);

    return roundTwoDecimals((taxNumber / 100 + 1) * netPrice);
};
