import React, { useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeConfirmationModalInner from './StripeConfirmationModalInner';

const StripeConfirmationModal = (props) => {
    const stripePromise = useMemo(() => {
        return loadStripe(process.env.REACT_APP_STRIPE_PUSHABLE_KEY);
    }, []);

    return (
        <Elements stripe={stripePromise}>
            <StripeConfirmationModalInner {...props} />
        </Elements>
    );
};

export default StripeConfirmationModal;
