import React from 'react';
import { FormControl, MenuItem, Select, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { selectStyles as styles } from './styles';

const SelectInput = ({ value, options, onChange, className, classes, disable = false }) => {
    return (
        <FormControl className={classNames(classes.formControl, className)} disabled={disable} variant="outlined">
            <Select value={value} onChange={(event) => onChange(event.target.value)}>
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

SelectInput.propTypes = {
    value: PropTypes.string.isRequired,
    disable: PropTypes.bool,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SelectInput);
