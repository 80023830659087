import api from './api';

export const getApptByInvoiceId = async (invoiceId) => {
    return api
        .request({
            method: 'GET',
            url: `/appointments/invoice/${invoiceId}`
        })
        .then((res) => res);
};

export const getInvoiceDraft = async (appointmentId) => {
    return api
        .request({
            method: 'GET',
            url: `/appointments/invoice-draft/${appointmentId}`
        })
        .then((res) => res);
};

export const assignInvoiceWithItems = async (appointmentId, invoiceId, items) => {
    return api
        .request({
            method: 'POST',
            url: `/appointment/assign-invoice/${appointmentId}/${invoiceId}`,
            data: {
                items: items
            }
        })
        .then((res) => res);
};
