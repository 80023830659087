import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Moment from 'moment-timezone';

import CreateCustomerModal from '../../collums-components/components/common/CreateCustomerModal/CreateCustomerModal';

import { Button, TextField, Typography, IconButton, CircularProgress, withStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '../../collums-components/helpers';
import { toLocaleString } from '../../collums-components/helpers';
import ClinicSelector from '../../collums-components/components/common/ClinicSelector';
import { CURRENT_CLINIC } from '../../collums-constants/storageKeys';

import { loadPractitioners } from '../../redux/actions/practitionersActions';
import { setCurrentCustomerData } from '../../redux/actions/currentCustomerActions';
import { loadCustomers, createCustomer } from '../../redux/actions/customersActions';

import CustomerApi from '../../api/customerApi';
import config from '../../config';

import { saleHeader as styles } from './styles';

const SaleHeader = ({ onCustomerChange, classes }) => {
    const [currCustomer, setCurrCustomer] = useState(undefined);
    const [balance, setBalance] = useState(0);
    const [customers, setCustomers] = useState([]);
    const invoice = useSelector((state) => state.invoice.invoice);
    const [searchCustomers, setCustomerSearch] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const searchDebounced = useDebounce(searchCustomers, 800);
    const dispatch = useDispatch();

    const customer = useSelector((state) => state.currentCustomer.customerData);

    const [createCustomerModalOpen, setCreateCustomerModalOpen] = useState(false);
    const invoiceState = useSelector((state) => state.invoice) || [];

    const invoiceUrl = new URL(window.location.href).searchParams.get('invoice');
    const appointmentUrl = new URL(window.location.href).searchParams.get('appointment');

    const [clinicId, setClinicId] = useState(localStorage.getItem('currentClinic'));

    useEffect(() => {
        let customer;

        if (invoiceState.invoice && invoiceUrl) {
            customer = invoiceState.invoice.customer;
        } else if (invoiceState.appointment && appointmentUrl) {
            customer = invoiceState.appointment.customer;
        }

        if (customer)
            CustomerApi.findCustomer(customer).then((res) => {
                setCurrCustomer(res);
                dispatch(setCurrentCustomerData(res));
            });
        /* eslint-disable-next-line */
    }, [invoiceUrl, appointmentUrl, invoiceState]);

    useEffect(() => {
        dispatch(loadPractitioners());
    }, [dispatch]);

    useEffect(() => {
        if (customer && Object.keys(customer).length) {
            CustomerApi.getCustomerBalance(customer.id).then((res) => setBalance(res.balance));
            onCustomerChange(customer.id);
            setCustomerSearch(
                `${customer.firstName} ${customer.surname} - ${Moment(customer.dateOfBirth).format('DD/MM/YYYY')}`
            );
        }
        /* eslint-disable-next-line */
    }, [customer]);
    const firstFilter = useRef('');
    const customerOptions = async () => {
        const filter = searchCustomers || '';
        const filterByLastUsed = searchCustomers === null || searchCustomers === '' ? true : false;
        firstFilter.current = filter;
        await CustomerApi.listCustomers(filter, 20, 0, filterByLastUsed, true).then((res) => {
            if (firstFilter.current !== filter) {
                // Finished but the filter is not the same anymore
                return;
            } else {
                const customerList = res.customers.map((item) => ({
                    ...item,
                    value: item.id,
                    label: `${item.firstName} ${item.surname}`
                }));
                setCustomers(customerList);
            }
        });
    };

    const search = async () => {
        setIsSearching(true);
        await customerOptions();
        setIsSearching(false);
    };

    const onQueryChange = (event) => {
        if (event) {
            setCustomerSearch(event.target.value);
        }
    };

    const handleClinicSelectorOnChange = (clinic) => {
        localStorage.setItem(CURRENT_CLINIC, clinic.id);
        setClinicId(clinic.id);
        window.location.reload();
    };

    useEffect(() => {
        search();
        /*eslint-disable-next-line*/
    }, [searchDebounced]);

    useEffect(() => {
        if (customers.length > 0) {
            onCustomerChange(customers[0].id);
        }
        /* eslint-disable-next-line */
    }, []);

    useEffect(() => {
        if (!invoice) return;
        onCustomerChange(invoice.customer);
    }, [invoice, onCustomerChange]);

    const renderCustomerDetails = () => {
        if (!customer || !Object.keys(customer).length) {
            return;
        }
        return (
            <>
                <div className={classes.headerWrapper}>
                    <div>
                        <Typography className={classes.mediumText}>
                            {customer.dateOfBirth
                                ? Moment(customer.dateOfBirth).format('DD/MM/YYYY')
                                : 'Not registered'}
                        </Typography>
                        <a type="link" className={classes.mediumLink} href={`mailto:${customer.email}`}>
                            <Typography>{customer.email}</Typography>
                        </a>
                    </div>
                    <div>
                        <Typography className={classes.mediumText}>{customer.phone}</Typography>
                        <Button
                            type="link"
                            className={classes.mediumLink}
                            href={`${config.calendarUrl}/customer/${customer.id}/general`}
                            style={{ padding: '0' }}
                            target="_blank"
                        >
                            <Typography>View client card</Typography>
                        </Button>
                    </div>
                </div>
                <Button className={classes.balanceBox}>
                    <Typography className={classes.mediumText}>Account balance: {toLocaleString(balance)}</Typography>
                </Button>
            </>
        );
    };

    return (
        <>
            <div className={classes.clinicSelector}>
                <ClinicSelector
                    clinicId={clinicId}
                    onChange={handleClinicSelectorOnChange}
                    disabled={(invoiceState.invoice && invoiceUrl) || appointmentUrl}
                />
            </div>
            <div className={classes.container}>
                {currCustomer && (
                    <div className={classes.customerContainer}>
                        <Typography>{`${currCustomer.firstName} ${currCustomer.surname}`}</Typography>
                    </div>
                )}
                {!invoiceState.invoice && !invoiceUrl && !appointmentUrl && (
                    <div className={classes.customerContainer}>
                        <Autocomplete
                            id="product-autocomplete"
                            className={classes.customerInput}
                            options={customers}
                            getOptionLabel={(option) =>
                                `${option.label} - ${Moment(option.dateOfBirth).format('DD/MM/YYYY')}`
                            }
                            onInputChange={onQueryChange}
                            onChange={(e, value) => {
                                onCustomerChange(value ? value.id : null);
                                dispatch(setCurrentCustomerData(value));
                            }}
                            freeSolo
                            filterOptions={(options) => options}
                            placeholder="Search for customer"
                            renderInput={(params) => (
                                <TextField placeholder="Search for customer" {...params} variant="outlined" />
                            )}
                            inputValue={searchCustomers || ''}
                        />
                        <div className={classes.loadingIcon}>
                            {isSearching && <CircularProgress className={classes.searchIconProgress} size={18} />}
                        </div>
                        <IconButton className={classes.addCustomerBtn} onClick={() => setCreateCustomerModalOpen(true)}>
                            <Add />
                        </IconButton>
                    </div>
                )}
                {renderCustomerDetails()}
                {createCustomerModalOpen && (
                    <>
                        <CreateCustomerModal
                            isCreateCustomerModalOpen={createCustomerModalOpen}
                            persistCustomer={async (data) => dispatch(createCustomer(data))}
                            reloadCustomerList={async () => dispatch(loadCustomers())}
                            hideCreateCustomerModal={() => setCreateCustomerModalOpen(false)}
                        />
                    </>
                )}
            </div>
        </>
    );
};

SaleHeader.propTypes = {
    customerId: PropTypes.string,
    onCustomerChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SaleHeader);
