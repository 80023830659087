import React, { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import Button from '../../common/Button';
import { getByInvoice } from '../../../api/invoiceApi';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { stripeConfirmationStyles } from './styles';
import { INVOICE_PAYMENT_STATUS } from '../../../collums-constants';

const StripeConfirmationModalInner = ({ classes, clientSecret, closeModal, invoiceId }) => {
    const stripe = useStripe();
    const [message, setMessage] = useState('');
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!stripe) {
            return;
        }
        confirmPayment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe]);

    const isInvoicePaid = (response) =>
        response &&
        (response?.paymentStatus === INVOICE_PAYMENT_STATUS.PAID ||
            response?.paymentStatus === INVOICE_PAYMENT_STATUS.PART_PAID);

    const checkInvoiceStatus = async () => {
        if (invoiceId) {
            const response = await getByInvoice(invoiceId);
            if (!isInvoicePaid(response)) {
                setTimeout(() => {
                    checkInvoiceStatus();
                }, 1000);
            } else {
                window.location = '/sale?invoice=' + invoiceId;
            }
        }
    };

    const confirmPayment = async () => {
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
            setMessage(error.message);
            setShowCloseButton(true);
            setIsLoading(false);
        } else if (paymentIntent && paymentIntent.status === 'succeeded') {
            checkInvoiceStatus();
        }
    };

    return (
        <div className={classes.wrapper}>
            <p>Please wait, contacting Stripe</p>
            <p>{message}</p>

            {isLoading && (
                <div className={classes.progress}>
                    <CircularProgress />
                </div>
            )}
            <div>
                {showCloseButton && (
                    <Button type="primary" onClick={() => closeModal()}>
                        OK
                    </Button>
                )}
            </div>
        </div>
    );
};

StripeConfirmationModalInner.propTypes = {
    classes: PropTypes.object.isRequired,
    clientSecret: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    invoiceId: PropTypes.string
};

export default withStyles(stripeConfirmationStyles)(StripeConfirmationModalInner);
