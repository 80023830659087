import { LOAD_SERVICES } from '../constants/services';
import { getServices } from '../../api/invoiceApi';
import { SERVICE_AVAILABILITIES } from '../../collums-constants';

export const loadServices = () => async (dispatch) => {
    const clinic = localStorage.getItem('currentClinic');

    const data = await getServices(undefined, undefined, undefined, undefined, clinic);
    const payload = data.items
        .map((service) => ({
            ...service,
            value: service.id.toString(),
            label: service.name
        }))
        .filter((service) => service.availability !== SERVICE_AVAILABILITIES.BOOK_ONLY);
    dispatch({
        type: LOAD_SERVICES,
        payload
    });
};
