import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';

import AddInvoiceItemTabPanel from './AddInvoiceItemTabPanel';
import AddInvoiceItemProductPanel from './AddInvoiceItemProductPanel';
import AddInvoiceItemCoursePanel from './AddInvoiceItemCoursePanel';
import AddInvoiceItemAccountPanel from './AddInvoiceItemAccountPanel';
import AddInvoiceItemServicePanel from './AddInvoiceItemServicePanel';
import CreateGiftcardPanel from './CreateGiftcardPanel';
import authApi from '../../api/authApi';
import { addInvoiceItemTabStyles as styles } from './styles';

function AddInvoiceItemTab({
    classes,
    clinic,
    customerId,
    invoiceItems,
    allTaxes,
    setInvoiceItems,
    handleAddMultipleProducts
}) {
    const [value, setValue] = useState(0);
    const [loggedStaff, setLoggedStaff] = useState(undefined);
    const practitionersList = useSelector((store) => store.practitioners ) || [];

    useEffect(() => {
        if (practitionersList.length === 0) return;

        const mappedPractitioners = practitionersList.map((practitioner) => ({
            ...practitioner,
            ...practitioner.locations.filter(l => {
                return l.clinic === clinic;
            })[0],
            label: practitioner.displayName,
            value: practitioner.displayName
        }));

        if (mappedPractitioners.length === 1) {
            setLoggedStaff(mappedPractitioners[0]);
        } else if (mappedPractitioners.some(p => p.isSoloPractitioner)) {
            setLoggedStaff(mappedPractitioners.filter(p => p.isSoloPractitioner)[0]);
        } else {
            authApi.getMe().then((staff) => {
                setLoggedStaff(staff);
            });
        }
    }, [practitionersList, clinic, value]);

    if (!customerId) return null;

    const handleChangeTab = (_, newValue) => {
        setValue(newValue);
    };

    const verifyInvoiceQuantity = (product) => {
        let amount = 0;
        invoiceItems.forEach((item) => {
            if (item.id === product.id || (item.referenceId && product.id === item.referenceId)) {
                amount += item.quantity || 0;
            }
        });
        return amount;
    };

    const tabs = ['Product', 'Course', 'Gift Voucher', 'Account', 'Service'];

    return (
        <div className={classes.container}>
            <Typography className={classes.addInvoiceItemTitle}>Add invoice item</Typography>
            {allTaxes?.length && (
                <div className={classes.addInvoiceItemTabsWrapperStyles}>
                    <Tabs centered variant="fullWidth" value={value} onChange={handleChangeTab}>
                        {tabs.map((title, i) => (
                            <Tab label={title} key={i} />
                        ))}
                    </Tabs>
                    {/** Product panel */}
                    <AddInvoiceItemTabPanel index={0} value={value}>
                        <AddInvoiceItemProductPanel
                            clinic={clinic}
                            allTaxes={allTaxes}
                            customerId={customerId}
                            addInvoiceItem={setInvoiceItems}
                            invoiceItems={invoiceItems}
                            verifyInvoiceQuantity={verifyInvoiceQuantity}
                            handleAddMultipleProducts={handleAddMultipleProducts}
                            loggedStaff={loggedStaff}
                        />
                    </AddInvoiceItemTabPanel>

                    {/** Course panel */}
                    <AddInvoiceItemTabPanel index={1} value={value}>
                        <AddInvoiceItemCoursePanel
                            clinic={clinic}
                            allTaxes={allTaxes}
                            addInvoiceItem={setInvoiceItems}
                            loggedStaff={loggedStaff}
                        />
                    </AddInvoiceItemTabPanel>

                    {/** Gift Card panel */}
                    <AddInvoiceItemTabPanel index={2} value={value}>
                        <CreateGiftcardPanel addInvoiceItem={setInvoiceItems} loggedStaff={loggedStaff} />
                    </AddInvoiceItemTabPanel>

                    {/** Account panel */}
                    <AddInvoiceItemTabPanel index={3} value={value}>
                        <AddInvoiceItemAccountPanel addInvoiceItem={setInvoiceItems} loggedStaff={loggedStaff} />
                    </AddInvoiceItemTabPanel>

                    {/** Service panel */}
                    <AddInvoiceItemTabPanel index={4} value={value}>
                        <AddInvoiceItemServicePanel
                            clinic={clinic}
                            allTaxes={allTaxes}
                            addInvoiceItem={setInvoiceItems}
                            loggedStaff={loggedStaff}
                        />
                    </AddInvoiceItemTabPanel>
                </div>
            )}
        </div>
    );
}

AddInvoiceItemTab.propTypes = {
    classes: PropTypes.object.isRequired,
    customerId: PropTypes.any,
    invoiceItems: PropTypes.array,
    setInvoiceItems: PropTypes.func,
    handleAddMultipleProducts: PropTypes.func,
    allTaxes: PropTypes.array.isRequired,
    clinic: PropTypes.object.isRequired
};

export default withStyles(styles)(AddInvoiceItemTab);
