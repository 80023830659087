import _ from 'lodash';
import cardValidator from 'card-validator';
import Axios from 'axios';
import { useState, useRef, useEffect } from 'react';

export const validateCreditCardForm = (formData) => {
    return (
        formData.firstName &&
        formData.surname &&
        formData.cardNo &&
        formData.expiry &&
        formData.cvc &&
        formData.street1 &&
        formData.postCode &&
        formData.county &&
        formData.nickname &&
        cardValidator.number(formData.cardNo).isPotentiallyValid
    );
};

export const validateAddProduct = (product, soldBy, quantity) => {
    if (product && product.retailPrice !== null && product.name && soldBy && parseInt(quantity) > 0) {
        return true;
    }
    return false;
};

export const addressLookup = async (zipcode) => {
    const postcodesAPI = Axios.create({
        baseURL: 'https://postcodes.io/postcodes/'
    });

    try {
        const response = await postcodesAPI.get(`${zipcode}`);
        return response.data.result.admin_county
            ? response.data.result.admin_county
            : response.data.result.admin_district;
    } catch (error) {
        return '';
    }
};

export const copyAndSetValueInArray = (array, value, index, property) => {
    const arrayCopy = [...array];
    const objectCopy = { ...arrayCopy[index] };
    objectCopy[property] = value;
    arrayCopy[index] = objectCopy;
    return arrayCopy;
};

export const formatCurrencySpaceSeparators = (text) => {
    const parts = text.split('.');
    const formatRecursively = (text) => {
        if (3 < text.length) {
            return formatRecursively(text.substring(0, text.length - 3)) + ' ' + text.substring(text.length - 3);
        } else {
            return text;
        }
    };
    return formatRecursively(parts[0]) + (2 === parts.length ? '.' : '') + (parts[1] ? parts[1] : '');
};

export const formatCurrencyFromValue = (value) =>
    formatCurrencySpaceSeparators(
        `${_.floor(value)}.${_.padStart(_.floor(_.round(100 * (value - _.floor(value)))), 2, '0')}`
    );

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const getFormattedDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
};

export const getFormattedTime = (timestamp) => {
    const date = new Date(timestamp);
    return `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
};

export const getServicePrice = (service, soldBy) => {
    const staffs = service?.staffs || [];
    const selectedStaff = (staffs || []).find((el) => el.staff === soldBy);
    if (selectedStaff) {
        if (
            selectedStaff.netPrice !== undefined &&
            selectedStaff.grossPrice !== undefined &&
            selectedStaff.netPrice + selectedStaff.grossPrice > 0
        )
            return {
                netPrice: selectedStaff.netPrice,
                grossPrice: selectedStaff.grossPrice
            };
        else if (selectedStaff.grossPrice !== undefined && selectedStaff.grossPrice > 0) {
            const netPrice = (selectedStaff.grossPrice * service.itemTax) / 100;
            return {
                netPrice,
                grossPrice: selectedStaff.grossPrice
            };
        } else if (selectedStaff.netPrice !== undefined && selectedStaff.netPrice > 0) {
            const grossPrice = selectedStaff.netPrice * (1 + service.itemTax / 100);
            return {
                netPrice: selectedStaff.netPrice,
                grossPrice
            };
        } else {
            return {
                netPrice: service.netPrice,
                grossPrice: service.grossPrice
            };
        }
    }
    return {
        netPrice: service.netPrice,
        grossPrice: service.grossPrice
    };
};

export const roundTwoDecimals = (number) => {
    return Math.round(100 * number) / 100;
};

export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    const isFirstRender = useRef(true);

    useEffect(
        () => {
            if (isFirstRender.current) {
                isFirstRender.current = false;
            } else {
                const handler = setTimeout(() => {
                    setDebouncedValue(value);
                }, delay);

                return () => {
                    clearTimeout(handler);
                };
            }
        },
        // eslint-disable-next-line
        [value]
    );

    return debouncedValue;
}

export function createUniqId(length = 10) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
