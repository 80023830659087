import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { saleCardPaymentModal as styles } from './styles';
import {
    Modal as CoreModal,
    withStyles,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Typography,
    Box,
    FormControlLabel,
    Grid
} from '@material-ui/core';
import { saveDraft } from '../../api/invoiceApi';
import { INVOICE_ORIGIN, INVOICE_PAYMENT_TYPES } from '../../collums-constants/index';
import { toLocaleString } from '../../collums-components/helpers';
import { SET_CURRENT_INVOICE_PAYMENT } from '../../redux/constants/payment';
import StyledTableRow from '../common/StyledTableRow';
import processPayments from '../../services/processPayments';
import StripeConfirmationModal from './stripe/StripeConfirmationModal';
import ConfirmModal from '../common/ConfirmModal';

import CoherentApi from '../../api/coherentApi';
import CoherentCreditCardForm from './coherent/CoherentCreditCardForm';
import CloseBtn from '../common/CloseBtn';

let clientCards = [];
let coherentIntervalId = null;

const ROW_INDEX_TERMINAL = 0;
const ROW_INDEX_CREDIT_CARD = 1;
const ROW_INDEX_PAYMENT_LINK = 2;

const SaleCardPaymentModal = ({
    paymentValue,
    cardType,
    isVisible,
    setIsVisible,
    items,
    classes,
    showInvoicePayment,
    discount,
    isProcessingPayment,
    updateProcessPaymentStatus,
    saveInvoiceNote
}) => {
    const [selectedRow, setSelectedRow] = useState(0);
    const [devices, setDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState(false);
    const [waitingTerminal, setWaitingTerminal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [stripeConfirmation, setStripeConfirmation] = useState(false);
    const [showStripeConfirmation, setShowStripeConfirmation] = useState(false);
    const [disableTerminalCancelBtn, setDisableTerminalCancelBtn] = useState(false);
    const [disabledCancelBtn, setDisabledCancelBtn] = useState(false);
    const [coherentUrl, setCoherentUrl] = useState(null);
    const [coherentTransactionId, setCoherentTransactionId] = useState(null);
    const [coherentPaymentLinkMessage, setCoherentPaymentLinkMessage] = useState(null);
    const [saleInformationModal, setSaleInformationModal] = useState({
        isVisible: false,
        title: '',
        message: ''
    });

    const dispatch = useDispatch();
    const paymentsPayload = useSelector((state) => state.paymentsPayload) || [];
    const notifications = useSelector((state) => state.notifications?.notifications);
    const paymentInvoice = useSelector((state) => state.payment.currentPaymentInvoice);

    const { invoice, invoicePrice, invoiceDiscount, appointment } = useSelector((state) => state.invoice);
    const customerId = useSelector((state) => state.currentCustomer.customerId);
    const temporaryDiscount = useSelector((state) => state.invoice.selectedDiscount);

    const payInvoiceRef = useRef(null);

    const hideSaleInformationModal = () => {
        setSaleInformationModal({
            isVisible: false,
            title: '',
            message: ''
        });
    };

    useEffect(() => {
        (async () => {
            const result = await CoherentApi.terminalList();
            if (result) {
                const data = result
                    .filter((device) => device.active)
                    .map((device) => {
                        return { ...device, deviceId: device.id };
                    });
                setDevices(data);
                setSelectedDeviceId(data.length ? data[0] : false);
            }
        })();
    }, []);

    useEffect(() => {
        startCoherentInterval();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coherentTransactionId]);

    useEffect(() => {
        clearCoherentInterval();
        if (selectedRow === ROW_INDEX_CREDIT_CARD && coherentTransactionId) {
            startCoherentInterval();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow]);

    const clearCoherentInterval = () => {
        if (coherentIntervalId) {
            clearInterval(coherentIntervalId);
            coherentIntervalId = null;
        }
    };

    const startCoherentInterval = () => {
        if (coherentTransactionId) {
            coherentIntervalId = setInterval(async () => {
                const result = await CoherentApi.checkPayment(coherentTransactionId);
                if (result.isPaid) {
                    clearCoherentInterval();

                    setWaitingTerminal(false);
                    setDisabledCancelBtn(false);
                    closeModal();
                    setLoading(false);

                    const invoiceId = paymentInvoice?.id || invoice?.id;
                    if (invoiceId) {
                        showInvoicePayment(invoiceId);
                    }
                }
            }, 1000);
        }
    };

    const closeModal = () => {
        setIsVisible({
            isVisible: false,
            cardType: INVOICE_PAYMENT_TYPES.CREDIT_CARD,
            value: 0
        });
    };

    const modalCancelHandler = () => {
        closeModal();

        if (!invoice && payInvoiceRef?.current) {
            window.location = `/sale?invoice=${payInvoiceRef.current}`;
        }
    };

    const errorHandler = (error) => {
        switch (true) {
            case error.startsWith('Invalid card number'):
                return 'Card number is not correct';
            case error.startsWith('Invalid card expiration date'):
                return 'Expiry date is not correct';
            case error.startsWith('Card verification code check failed'):
                return ' CVV is not correct';
            case error.startsWith('Postal code check failed'):
                return 'Postal code is not correct';
            default:
                return error || 'Unhandled request';
        }
    };

    const sendForm = async (cardData) => {
        setDisabledCancelBtn(true);
        setDisableTerminalCancelBtn(false);
        try {
            let data;
            setLoading(true);
            const itemsList = items ? items : invoice.items;
            const taxes = itemsList.reduce((acc, item) => (acc += item.taxValue), 0);
            const taxPercentages = ((taxes * 100) / (invoicePrice - taxes)).toFixed(2);

            if (!invoice) {
                const invoiceDraft = {
                    customer: customerId,
                    items,
                    discount: invoiceDiscount,
                    description: 'Invoice from POS',
                    origin: INVOICE_ORIGIN.SALE,
                    discountProperties: temporaryDiscount?.value ? undefined : discount,
                    amount: invoicePrice,
                    temporaryDiscount: temporaryDiscount?.value || undefined,
                    clinic: localStorage.getItem('currentClinic') || ''
                };

                const isSamePayment = ['customer', 'amount', 'discount', 'clinic'].every(
                    (key) => invoiceDraft[key] === (paymentInvoice || {})[key]
                );

                if (isSamePayment) {
                    data = {
                        id: paymentInvoice.id,
                        customer: paymentInvoice.customer,
                        code: paymentInvoice.code,
                        taxPercentages: taxPercentages
                    };
                } else {
                    const res = await saveDraft(invoiceDraft);
                    data = {
                        id: res.id,
                        customer: res.customer,
                        code: res.code,
                        taxPercentages: taxPercentages
                    };
                    dispatch({ type: SET_CURRENT_INVOICE_PAYMENT, payload: res });
                }
            } else {
                data = {
                    id: invoice.id,
                    customer: invoice.customer,
                    code: invoice.code,
                    taxPercentages: taxPercentages
                };
            }

            payInvoiceRef.current = data.id;

            // terminal payment
            if (selectedRow === ROW_INDEX_TERMINAL) {
                if (!selectedDeviceId) {
                    setLoading(false);
                    setDisabledCancelBtn(false);
                    return toastr.error('Select card terminal');
                }

                setWaitingTerminal(true);

                const result = await processPayments(
                    [
                        {
                            value: Number(paymentValue),
                            type: INVOICE_PAYMENT_TYPES.CREDIT_CARD_TERMINAL
                        }
                    ],
                    { items, invoiceDiscount, invoicePrice, temporaryDiscount, discountProperties: discount },
                    { paymentsPayload, customerId, invoice: data },
                    true,
                    notifications,
                    appointment
                );

                if (!result) {
                    setLoading(false);
                    setWaitingTerminal(false);
                    setDisabledCancelBtn(false);
                    return false;
                }

                if (result?.coherentUrl) {
                    setCoherentUrl(result.coherentUrl);
                }
                if (result?.coherentTransactionId) {
                    setCoherentTransactionId(result.coherentTransactionId);
                    await CoherentApi.terminalCheckout({
                        deviceId: selectedDeviceId.deviceId,
                        transactionId: result.coherentTransactionId
                    });
                }

                return data;
            } else if (selectedRow === ROW_INDEX_CREDIT_CARD) {
                // card payment
                const result = await processPayments(
                    [
                        {
                            value: Number(paymentValue),
                            discount: Number(invoiceDiscount),
                            type: INVOICE_PAYMENT_TYPES.CREDIT_CARD,
                            cardData: cardData === null ? {} : cardData
                        }
                    ],
                    { items, invoiceDiscount, invoicePrice, temporaryDiscount, discountProperties: discount },
                    { paymentsPayload, customerId, invoice: data },
                    true,
                    notifications,
                    appointment?.id
                );

                if (result && saveInvoiceNote) {
                    await saveInvoiceNote({ takingPayment: true, invoiceId: result?.id });
                }

                if (!result) {
                    setLoading(false);
                    setWaitingTerminal(false);
                    setDisabledCancelBtn(false);
                    return false;
                }

                if (result?.coherentUrl) {
                    setCoherentUrl(result.coherentUrl);
                }
                if (result?.coherentTransactionId) {
                    setCoherentTransactionId(result.coherentTransactionId);
                }

                setLoading(false);
                setWaitingTerminal(false);
                setDisabledCancelBtn(false);
                return data;
            } else if (selectedRow === ROW_INDEX_PAYMENT_LINK) {
                const isEmailChecked = document.getElementById('emailPL').checked;
                const isSmsChecked = document.getElementById('smsPL').checked;

                if (!isEmailChecked && !isSmsChecked) {
                    setCoherentPaymentLinkMessage('Please choose an option');
                    setLoading(false);
                    setWaitingTerminal(false);
                    setDisabledCancelBtn(false);
                    return false;
                }

                const result = await processPayments(
                    [
                        {
                            value: Number(paymentValue),
                            discount: Number(invoiceDiscount),
                            type: INVOICE_PAYMENT_TYPES.CREDIT_CARD,
                            cardData: {
                                paymentLink: true,
                                email: isEmailChecked,
                                sms: isSmsChecked
                            }
                        }
                    ],
                    { items, invoiceDiscount, invoicePrice, temporaryDiscount, discountProperties: discount },
                    { paymentsPayload, customerId, invoice: data },
                    true,
                    notifications,
                    appointment?.id
                );

                if (result && saveInvoiceNote) {
                    await saveInvoiceNote({ takingPayment: true, invoiceId: result?.id });
                }

                if (!result) {
                    setLoading(false);
                    setWaitingTerminal(false);
                    setDisabledCancelBtn(false);
                    return false;
                }

                toastr.success('Payment link sent');
                setLoading(false);
                setWaitingTerminal(false);
                setDisabledCancelBtn(false);
                closeModal();
                return data;
            }
        } catch (e) {
            console.error(e);

            if (e?.data?.error === 'Internal Server Error') {
                toastr.error("There's a problem processing this payment right now. Please try later.");
            } else {
                toastr.error(errorHandler(e?.data?.message));
            }

            setLoading(false);
            setDisabledCancelBtn(false);
            return false;
        } finally {
            updateProcessPaymentStatus(false);
        }
    };

    const getPaymentButtonText = () => {
        if (selectedRow < clientCards.length || selectedRow === clientCards.length + 1) {
            return 'Pay with card';
        } else if (selectedRow === clientCards.length + 2) {
            return 'Save new card & pay';
        } else {
            return 'Pay Now';
        }
    };

    const cancelPayment = async () => {
        setDisableTerminalCancelBtn(true);
        await CoherentApi.clearTerminal({
            deviceId: selectedDeviceId.deviceId,
            transactionId: coherentTransactionId
        });
        setWaitingTerminal(false);
        setLoading(false);
        clearCoherentInterval();
        closeModal();

        if (!invoice && payInvoiceRef?.current) {
            window.location = `/sale?invoice=${payInvoiceRef.current}`;
        }

        toastr.error('Payment Cancelled');
    };

    const deviceSelector = () => {
        if (selectedRow !== clientCards.length) return null;

        return devices.length ? (
            <div style={{ width: '85%', marginTop: 32, marginBottom: 24 }}>
                <>
                    <FormControl variant="outlined" className={classes.selectRoot}>
                        <InputLabel>Select card terminal</InputLabel>
                        <Select value={selectedDeviceId} onChange={(e) => setSelectedDeviceId(e.target.value)}>
                            {devices.map((opt) => (
                                <MenuItem value={opt} key={opt.deviceId}>
                                    {opt.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {waitingTerminal && (
                        <>
                            <div className={classes.checkoutMessage}>
                                Please do not close your browser or tab. If you click cancel, wait for this pop-up to
                                close to be sure that the payment request has been cancelled.
                            </div>

                            <div className={classes.waitingTerminalWrapper}>
                                <Button onClick={cancelPayment} variant="outlined" disabled={disableTerminalCancelBtn}>
                                    Cancel
                                </Button>
                                <CircularProgress />
                            </div>
                        </>
                    )}
                </>
            </div>
        ) : (
            <Box>
                <Typography style={{ color: 'red', padding: '10px' }}>
                    No card readers attached. Please add card reader in admin panel.
                </Typography>
            </Box>
        );
    };

    return (
        <>
            <CoreModal open={isVisible} className={classes.modal} disableEnforceFocus>
                {!showStripeConfirmation ? (
                    <div className={classes.root}>
                        <div className={classes.closeBtn} style={{ height: '34px' }}>
                            <CloseBtn onClick={() => closeModal()} />
                        </div>
                        <div className={classes.content}>
                            <div className={classes.header}>
                                <p>{`Choose ${cardType?.toLowerCase()} payment method`}</p>
                                <p>{`To pay: ${toLocaleString(paymentValue || 0)}`}</p>
                            </div>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Type</TableCell>
                                        <TableCell>Ends</TableCell>
                                        <TableCell>Exp</TableCell>
                                        <TableCell>Is default?</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow onClick={() => setSelectedRow(ROW_INDEX_TERMINAL)}>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedRow === ROW_INDEX_TERMINAL}
                                                onClick={() => {
                                                    setSelectedRow(ROW_INDEX_TERMINAL);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="left" colSpan={4}>
                                            Use card terminal
                                        </TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow onClick={() => setSelectedRow(ROW_INDEX_CREDIT_CARD)}>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedRow === ROW_INDEX_CREDIT_CARD}
                                                onClick={() => setSelectedRow(ROW_INDEX_CREDIT_CARD)}
                                            />
                                        </TableCell>
                                        <TableCell align="left" colSpan={4}>
                                            Manually enter card details
                                        </TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow onClick={() => setSelectedRow(ROW_INDEX_PAYMENT_LINK)}>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedRow === ROW_INDEX_PAYMENT_LINK}
                                                onClick={() => setSelectedRow(ROW_INDEX_PAYMENT_LINK)}
                                            />
                                        </TableCell>
                                        <TableCell align="left" colSpan={4}>
                                            Send payment link
                                        </TableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                            {selectedRow === ROW_INDEX_CREDIT_CARD && (
                                <>
                                    <div className={classes.squarePaymentForm}>
                                        {coherentUrl && (
                                            <CoherentCreditCardForm
                                                coherentUrl={coherentUrl}
                                                resetHandler={() => {
                                                    setCoherentTransactionId(null);
                                                    clearCoherentInterval();
                                                    if (isProcessingPayment()) return;
                                                    updateProcessPaymentStatus(true);
                                                    sendForm(null);
                                                }}
                                            />
                                        )}
                                    </div>

                                    {!coherentUrl && (
                                        <>
                                            <div className={classes.actions}>
                                                <Button
                                                    onClick={modalCancelHandler}
                                                    variant="outlined"
                                                    disabled={disabledCancelBtn}
                                                >
                                                    Cancel
                                                </Button>
                                                {!loading && (
                                                    <Button
                                                        onClick={() => {
                                                            if (isProcessingPayment()) return;
                                                            updateProcessPaymentStatus(true);
                                                            sendForm(null);
                                                        }}
                                                    >
                                                        {getPaymentButtonText()}
                                                    </Button>
                                                )}
                                                {loading && <CircularProgress />}
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                            {deviceSelector()}
                            {selectedRow === ROW_INDEX_TERMINAL && !waitingTerminal && (
                                <>
                                    <div className={classes.actions}>
                                        <Button
                                            onClick={modalCancelHandler}
                                            variant="outlined"
                                            disabled={disabledCancelBtn}
                                        >
                                            Cancel
                                        </Button>
                                        {!loading && (
                                            <Button
                                                onClick={() => {
                                                    if (isProcessingPayment()) return;
                                                    updateProcessPaymentStatus(true);
                                                    sendForm(clientCards[selectedRow]);
                                                }}
                                            >
                                                {getPaymentButtonText()}
                                            </Button>
                                        )}
                                        {loading && <CircularProgress />}
                                    </div>
                                </>
                            )}
                            {selectedRow === ROW_INDEX_PAYMENT_LINK && (
                                <>
                                    <Grid container alignItems="center" className={classes.paymentLinkOptionWrapper}>
                                        <Grid item>
                                            <div className={classes.formContainer}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id="emailPL"
                                                            name="emailPL"
                                                            color="primary"
                                                            onChange={() => setCoherentPaymentLinkMessage(null)}
                                                        />
                                                    }
                                                    className={`${classes.label}`}
                                                    label="Email"
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div className={classes.formContainer}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id="smsPL"
                                                            name="smsPL"
                                                            color="primary"
                                                            onChange={() => setCoherentPaymentLinkMessage(null)}
                                                        />
                                                    }
                                                    className={`${classes.label}`}
                                                    label="SMS"
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {coherentPaymentLinkMessage && (
                                        <div className={classes.coherentPaymentLinkMessage}>
                                            {coherentPaymentLinkMessage}
                                        </div>
                                    )}
                                    <div className={classes.actions}>
                                        <Button
                                            onClick={modalCancelHandler}
                                            variant="outlined"
                                            disabled={disabledCancelBtn}
                                        >
                                            Cancel
                                        </Button>
                                        {!loading && (
                                            <Button
                                                onClick={() => {
                                                    if (isProcessingPayment()) return;
                                                    updateProcessPaymentStatus(true);
                                                    sendForm(null);
                                                }}
                                            >
                                                Send payment link
                                            </Button>
                                        )}
                                        {loading && <CircularProgress />}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className={classes.root}>
                        <StripeConfirmationModal
                            clientSecret={stripeConfirmation?.clientSecret}
                            paymentIntent={stripeConfirmation?.id}
                            invoiceId={paymentInvoice?.id || invoice.id}
                            closeModal={() => {
                                setShowStripeConfirmation(false);
                                setStripeConfirmation(false);
                                setLoading(false);
                            }}
                        />
                    </div>
                )}
            </CoreModal>
            <ConfirmModal
                hideCancelButton={true}
                hideCloseIcon={true}
                continueText="Continue"
                isOpen={saleInformationModal?.isVisible}
                title={saleInformationModal?.title || ''}
                onConfirm={hideSaleInformationModal}
                setIsOpen={hideSaleInformationModal}
                content={saleInformationModal?.message || ''}
            />
        </>
    );
};

SaleCardPaymentModal.propTypes = {
    paymentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    cardType: PropTypes.oneOf(['Credit Card', 'Credit Card 2']).isRequired,
    isVisible: PropTypes.bool.isRequired,
    setIsVisible: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    showInvoicePayment: PropTypes.func.isRequired,
    items: PropTypes.array,
    discount: PropTypes.object.isRequired,
    isProcessingPayment: PropTypes.func.isRequired,
    updateProcessPaymentStatus: PropTypes.func.isRequired,
    saveInvoiceNote: PropTypes.func
};

export default withStyles(styles)(SaleCardPaymentModal);
