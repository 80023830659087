import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Sidebar from '../collums-components/components/common/Sidebar';
import { validateJobPermissions } from '../collums-components/helpers/index';
import api from '../api/api';
import authApi from '../api/authApi';
import { getQueryParams } from '../services/locationHelper';
import config from '../config';
import ShowPINModal from './../collums-components/components/common/PINModal/index';
import { COLLUMS_APPS_ACCESS_TIER } from '../collums-constants';
import { CURRENT_CLINIC } from '../collums-constants/storageKeys';
import { isAvailableByPlan, isValidMongoIdString } from '../collums-constants/utils';
import EnvironmentDisabled from './../collums-components/components/common/EnvironmentDisabled';
import OrganisationApi from './../collums-components/api/organizationApi';
import InvoiceApi from '../collums-components/api/InvoiceApi';
import LogoutModal from '../collums-components/components/common/LogoutModal';
import Intercom from '../collums-components/helpers/Intercom';
import { useCookies } from 'react-cookie';

const App = ({ children }) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const location = useLocation();
    const [cookies] = useCookies(['token']);

    useEffect(() => {
        const doEffect = async () => {
            const currentClinic = getQueryParams(location).clinic;
            const currentInvoice = getQueryParams(location).invoice;

            if (currentClinic && isValidMongoIdString(currentClinic)) {
                localStorage.setItem(CURRENT_CLINIC, currentClinic);
            }
            const token = getQueryParams(location).token || cookies.token;
            let redirectionUrl = `${config.authUrl}?redirect=pos`;
            const param = new URL(window.location.href).searchParams.get('invoice');

            if (param) {
                redirectionUrl = redirectionUrl + '&invoice=' + param;
            }

            if (token) {
                try {
                    api.config({ headers: { Authorization: token } });
                    const me = await authApi.getMe();
                    localStorage.setItem('token', token);
                    await validateJobPermissions(token, me, process.env.REACT_APP_NAME);
                    const organisation = await OrganisationApi.getOrg();
                    localStorage.setItem('currency', organisation?.currency);

                    if (currentInvoice && isValidMongoIdString(currentInvoice)) {
                        const invoiceData = await InvoiceApi.getInvoice(currentInvoice);
                        localStorage.setItem(CURRENT_CLINIC, invoiceData.clinic);
                    }

                    await Intercom.updateUser(me);
                } catch (error) {
                    if (error && error.status === 401) {
                        window.location = redirectionUrl;
                    } else {
                        throw error;
                    }
                }
            } else {
                window.location = redirectionUrl;
            }
            setIsInitialized(true);
        };

        doEffect();

        // eslint-disable-next-line
    }, []);

    if (!isAvailableByPlan(COLLUMS_APPS_ACCESS_TIER.POS)) {
        return <EnvironmentDisabled />;
    }

    if (!isInitialized) {
        return null;
    }

    return (
        <>
            <ShowPINModal
                onEnterPin={async (user, token) => {
                    api.config({ headers: { Authorization: token } });
                }}
            />
            {cookies.logoutActive === 'true' && <LogoutModal />}
            <div className="content-flex">
                <div className="content-width">{children}</div>
                <Sidebar appName="pos" />
            </div>
        </>
    );
};

App.propTypes = {
    children: PropTypes.any.isRequired
};

export default App;
