export const stripeCardStyles = () => ({
    title: {
        '& h2': {
            fontSize: '18px',
            fontWeight: '500',
            textAlign: 'center',
            fontFamily: "Roboto, Arial, 'sans-serif'"
        }
    },
    root: {
        '& .MuiPaper-root': {
            width: '50%'
        }
    },
    content: {
        width: '100%'
    },
    redirectMessage: {
        width: '100%',
        fontSize: '20px',
        color: '#584d4d',
        textAlign: 'center',
        marginBottom: '15px'
    }
});

export const stripeConfirmationStyles = () => ({
    wrapper: {
        margin: '30px'
    },
    progress: {
        textAlign: 'center',
        padding: '10px'
    }
});

export const stripeCreditCardFormStyles = () => ({
    button: {
        border: 0,
        cursor: 'pointer',
        display: 'inline-flex',
        outline: 0,
        alignItems: 'center',
        verticalAlign: 'middle',
        justifyContent: 'center',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '10px',
        minWidth: '64px',
        boxSizing: 'border-box',
        transition:
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: 'Gilmer Regular, Roboto',
        fontWeight: 500,
        lineHeight: 1.75,
        textTransform: 'uppercase',
        height: '44px',
        margin: '20px 10px 10px 0px',
        padding: '6px 14px',
        backgroundColor: '#b41778'
    },
    buttonSpan: {
        cursor: 'pointer',
        boxSizing: 'inherit',
        margin: 0,
        fontWeight: 400,
        lineHeight: 1.5,
        color: '#ffffff',
        fontSize: '14px',
        fontFamily: 'Gilmer Medium',
        textTransform: 'none'
    },
    warning: {
        textAlign: 'center',
        padding: '10px',
        fontSize: '16px',
        fontWeight: 500,
        color: '#fff',
        marginTop: '10px',
        background: '#cf2a27'
    },
    actionsWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '40%',
        margin: '1vh 3vh 3vh 3vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14
        },
        '& button:nth-child(2)': {
            backgroundColor: '#b41778',
            color: 'white'
        }
    }
});
