export const searchFieldStyles = () => ({
    root: {
        width: '200px'
    },
    autocomplete: {
        width: '13rem',
        height: '38px',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black !important',
            height: '38px !important'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
            padding: '0 4px'
        }
    },
    inputPrice: {
        '& .Mui-disabled': {
            color: 'black'
        }
    },
    select: {
        '&.Mui-selected': {
            backgroundColor: '#ccc !important',
            '&:hover': {
                backgroundColor: '#aaa !important'
            }
        }
    }
});

export const submitButtonStyles = () => ({
    btn: {
        height: 44,
        padding: '6px 14px',
        borderRadius: 0,
        fontSize: 14,
        fontFamily: 'Gilmer Medium',
        color: '#ffffff',
        textTransform: 'none'
    },
    blueBg: {
        backgroundColor: '#2b78e4',

        '&:hover': {
            backgroundColor: '#286cd3'
        }
    }
});
