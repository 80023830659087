import api from './api';

export const getProducts = async (
    name,
    pageSize,
    filterByLastUsed,
    barcode,
    clinic,
    type,
    isActive,
    skipMeta = false
) => {
    return api
        .request({
            method: 'GET',
            url: `/stocks${barcode ? `?barcode=${barcode}` : ''}`,
            params: {
                name,
                pageSize,
                filterByLastUsed,
                type,
                clinic,
                isActive,
                source: 'pos',
                skipMeta
            }
        })
        .then((res) => res.data);
};

export const getCourses = async (value, pageSize, filterByLastUsed, clinic, isFromOrg, available) => {
    return api
        .request({
            method: 'GET',
            url: `/course?isFromOrg=${isFromOrg}&clinic=${clinic}`,
            params: {
                value,
                pageSize,
                filterByLastUsed,
                available
            }
        })
        .then((res) => res.courses);
};

export const getServices = async (value, count, filterByLastUsed, active, clinic) => {
    return api.request({
        method: 'GET',
        url: `/services?isFromOrg=false&clinic=${clinic}`,
        params: {
            value: value,
            count: count || 10,
            filterByLastUsed,
            active
        }
    });
};

export const getInvoicePayments = async (invoiceId) => {
    return api.request({
        method: 'GET',
        url: `/invoices/payments/${invoiceId}`
    });
};

export const saveDraft = async (data) => {
    return api
        .request({
            method: 'POST',
            url: '/invoice/create',
            data
        })
        .then((res) => res);
};

export const getByInvoice = async (invoiceId) => {
    return api
        .request({
            method: 'GET',
            url: `/invoices/${invoiceId}`
        })
        .then((res) => res);
};

export const addItemInvoice = async (invoiceId, data) => {
    return api
        .request({
            method: 'POST',
            url: '/invoice-item',
            data
        })
        .then((res) => res);
};

export const removeById = async (id) => {
    return api
        .request({
            method: 'DELETE',
            url: `/invoice-item/${id}`
        })
        .then((res) => res);
};

export const addInvoicePayment = async (data) => {
    return await api
        .request({
            method: 'POST',
            url: `/invoice/${data.invoice.id}/pay`,
            data
        })
        .then((res) => res);
};

export const updateItem = async (data) => {
    data.soldBy = (() => {
        if (!data.soldBy) return 'online';
        if (data.soldBy.id) return data.soldBy.id;
        return data.soldBy;
    })();
    return api
        .request({
            method: 'PUT',
            url: `/invoice-item/${data.id}`,
            data
        })
        .then((res) => res);
};

export const updateInvoice = async (invoiceId, data) => {
    return api
        .request({
            method: 'PUT',
            url: `/invoice/update/${invoiceId}`,
            data
        })
        .then((res) => res);
};

export const resetItemsTempDiscount = (invoiceId) => {
    return api
        .request({
            method: 'PUT',
            url: `/invoice-item/reset-discount/from-invoice/${invoiceId}`
        })
        .then((res) => res);
};

export const updateInvoiceItems = (invoiceId, data) => {
    return api
        .request({
            method: 'PUT',
            url: `/invoice/update-items/${invoiceId}`,
            data
        })
        .then((res) => res);
};

export const getInvoicePaymentStatus = async (invoiceId) => {
    return api.request({
        method: 'GET',
        url: `invoices/get-payment-status/${invoiceId}`
    });
};
