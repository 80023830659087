import { SET_CURRENT_CUSTOMER, SET_CURRENT_CUSTOMER_DATA } from '../constants/currentCustomer';

export const setCurrentCustomer = (id) => async (dispatch) => {
    dispatch({
        type: SET_CURRENT_CUSTOMER,
        payload: id
    });
};

export const setCurrentCustomerData = (data) => async (dispatch) => {
    dispatch({
        type: SET_CURRENT_CUSTOMER_DATA,
        payload: data
    });
};
