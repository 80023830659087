import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Modal, Typography, Button } from '@material-ui/core';

import { confirmationModalStyles } from './styles';
import CloseBtn from '../common/CloseBtn';

function ConfirmBelowStockModal({ isVisible, setIsVisible, addToInvoice, classes }) {
    const closeModal = () => {
        setIsVisible(!isVisible);
    };

    const sendForm = () => {
        addToInvoice();
        closeModal();
    };

    return (
        <Modal open={isVisible} onClose={closeModal} className={classes.modal}>
            <div className={classes.root}>
                <div className={classes.closeBtn}>
                    <CloseBtn onClick={closeModal} />
                </div>
                <div className={classes.content}>
                    <Typography variant="h4">Negative stock warning</Typography>
                    <Typography>
                        The quantity that you are adding to the invoice will result in a negative stock level.
                    </Typography>
                    <Typography>Are you sure you want to continue?</Typography>
                    <div className={classes.actions}>
                        <Button onClick={closeModal} variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={sendForm}>Continue</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

ConfirmBelowStockModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    setIsVisible: PropTypes.func.isRequired,
    addToInvoice: PropTypes.func,
    classes: PropTypes.object
};

export default withStyles(confirmationModalStyles)(ConfirmBelowStockModal);
