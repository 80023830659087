import { SET_NOTIFICATIONS } from '../constants/notifications';

export const setNotifications = (notifications) => {
    return async (dispatch) => {
        return dispatch({
            type: SET_NOTIFICATIONS,
            payload: notifications
        });
    };
};
