import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import CloseBtn from '../common/CloseBtn';
import { toastr } from 'react-redux-toastr';
import { Autocomplete } from '@material-ui/lab';
import { Modal, withStyles, Button, TextField, Tabs, Tab } from '@material-ui/core';

import GiftCardApi from '../../api/GiftcardApi';
import { loadCustomers } from '../../redux/actions/customersActions';

import { saleProductHistory } from './styles';

function RedeemGiftCard({ isVisible, closeModal, voucherOptions, currentCustomer, classes }) {
    const [voucher, setVoucher] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [voucherCode, setVoucherCode] = useState('');

    const dispatch = useDispatch();

    const handleTabChange = (_, newValue) => {
        setVoucher(null);
        setSelectedTab(newValue);
    };

    const redeemGiftCard = async () => {
        try {
            await GiftCardApi.redeemGiftCard(voucher.id, currentCustomer.id);
            toastr.success('Gift voucher redeemed');
            dispatch(loadCustomers());
            closeModal();
            window.location.reload();
        } catch (e) {
            toastr.error('Gift voucher not redeemed');
        }
    };

    const getVoucher = async () => {
        try {
            const res = await GiftCardApi.getGiftcard(voucherCode);
            setVoucher(res);
            toastr.success('Success!', 'The giftvoucher was found');
        } catch (e) {
            toastr.error('Error', e.data.message);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getVoucher();
        }
    };

    return (
        <Modal open={isVisible} className={classes.smallModal}>
            <div className={classes.root}>
                <div className={classes.closeBtn}>
                    <CloseBtn onClick={() => closeModal(false)} />
                </div>
                <div className={classes.header}>
                    <p>{'Redeem giftcard'}</p>
                </div>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    <Tab label="Send value to account" id="tab-0" />
                    <Tab label="Redeem anonymous gift voucher" id="tab-1" />
                </Tabs>
                {selectedTab === 0 && (
                    <div className={classes.voucherTab}>
                        <Autocomplete
                            fullWidth
                            value={voucher}
                            options={voucherOptions}
                            onChange={(e, value) => setVoucher(value)}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} label="Gift voucher" variant="outlined" />}
                        />
                    </div>
                )}
                {selectedTab === 1 && (
                    <div className={classes.voucherTab}>
                        <TextField
                            size={'small'}
                            style={{ width: '100%' }}
                            label="Gift voucher code"
                            variant="outlined"
                            onChange={(e) => setVoucherCode(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <Button className={classes.submitButton} onClick={() => getVoucher()}>
                            Submit
                        </Button>
                    </div>
                )}
                <div className={classes.actions}>
                    <Button onClick={() => closeModal(false)} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={() => redeemGiftCard()}>Redeem</Button>
                </div>
            </div>
        </Modal>
    );
}

RedeemGiftCard.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    voucherOptions: PropTypes.array.isRequired,
    currentCustomer: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(saleProductHistory)(RedeemGiftCard);
