import * as paymentAction from '../constants/payment';

const initialValue = {
    useAccountBalance: false,
    currentPaymentInvoice: null
};

const paymentReducer = (state = initialValue, action) => {
    switch (action.type) {
        case paymentAction.REQUEST_ADD_TO_ACCOUNT_BALANCE:
            return {
                ...state,
                useAccountBalance: true
            };
        case paymentAction.FINISH_ADD_TO_ACCOUNT_BALANCE:
            return {
                ...state,
                useAccountBalance: false
            };
        case paymentAction.SET_CURRENT_INVOICE_PAYMENT: {
            return {
                ...state,
                currentPaymentInvoice: action.payload
            };
        }

        default:
            return state;
    }
};

export default paymentReducer;
