import api from './api';

export const getProductStockHistory = async (productId) => {
    if (productId) {
        return api
            .request({
                method: 'GET',
                url: `stocks/history/${productId}`
            })
            .then((res) => res);
    } else {
        return [];
    }
};

export const getCustomerStockHistory = async (customerId, clinic) => {
    return api
        .request({
            method: 'GET',
            url: `stocks/customer-history/${customerId}?clinic=${clinic}`
        })
        .then((res) => res);
};

export const getProduct = async (productId) => {
    return api
        .request({
            method: 'GET',
            url: `/stocks/${productId}`
        })
        .then((res) => res);
};
