import { LOAD_PRACTITIONERS } from '../constants/practitioners';

const initialState = [];

const practitionersReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PRACTITIONERS:
            return [...state, ...action.payload.items];
        default:
            return state;
    }
};

export default practitionersReducer;
