import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Backspace } from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { saleNumericPadStyles as styles } from './styles';
import { useKeyboard } from '../../utils/numericPadHook';
import { getCurrencySymbol } from '../../collums-components/helpers';

const Button = withStyles(styles)(({ color, small, onClick, classes, children }) => {
    const classesMap = {
        blue: classes.blueBtn,
        bright: classes.brightBtn,
        dark: classes.darkBtn
    };
    return (
        <div className={classes.outerBtn} onClick={onClick}>
            <div className={classNames(classes.innerBtn, classesMap[color])}>
                <Typography className={classNames(classes.label, small && classes.smallLabel)}>{children}</Typography>
            </div>
        </div>
    );
});

const SaleNumericPad = ({ text, setText, confirm, classes }) => {
    const isTooLong = () => {
        const parts = text.split('.');
        if (1 === parts.length && parts[0] && 6 < parts[0].length) {
            return true;
        }
        return parts[1] && 1 < parts[1].length;
    };
    const onDigitClick = (value) => !isTooLong() && setText(`${text}${value}`);
    const oZeroClick = () => {
        if (1 === text.length && '0' === text[0]) {
            return;
        }
        if (isTooLong()) {
            return;
        }
        setText(`${text}0`);
    };
    const onDotClick = () => {
        if (text.includes('.')) {
            return;
        }
        if (!text.length) {
            setText('0.');
        } else {
            setText(`${text}.`);
        }
    };
    const onBackspaceClick = () => setText(text.substring(0, text.length - 1));
    const onCurrencyClick = (value) => {
        const parts = text.split('.');
        const integer = Number(parts[0] || 0) + value;
        setText(String(integer) + (2 === parts.length ? '.' : '') + (parts[1] ? parts[1] : ''));
    };

    /**
     * Handle numeric pad events.
     *
     * @param {Object} keydownEvent is used to check which action to trigger
     * when a key is pressed.
     */
    useKeyboard((keydownEvent) => {
        const { isDigit, number, code } = keydownEvent;

        if (isDigit) {
            onDigitClick(number);
        } else if (code === 'period' || code === 'numpaddecimal') {
            onDotClick();
        } else if (code === 'backspace') {
            onBackspaceClick();
        } else if (code.endsWith('enter')) {
            confirm();
        }
    });

    return (
        <div className={classes.container}>
            <div className={classes.row}>
                <Button color="bright" onClick={() => onDigitClick(1)}>
                    1
                </Button>
                <Button color="bright" onClick={() => onDigitClick(2)}>
                    2
                </Button>
                <Button color="bright" onClick={() => onDigitClick(3)}>
                    3
                </Button>
            </div>
            <div className={classes.row}>
                <Button color="bright" onClick={() => onDigitClick(4)}>
                    4
                </Button>
                <Button color="bright" onClick={() => onDigitClick(5)}>
                    5
                </Button>
                <Button color="bright" onClick={() => onDigitClick(6)}>
                    6
                </Button>
            </div>
            <div className={classes.row}>
                <Button color="bright" onClick={() => onDigitClick(7)}>
                    7
                </Button>
                <Button color="bright" onClick={() => onDigitClick(8)}>
                    8
                </Button>
                <Button color="bright" onClick={() => onDigitClick(9)}>
                    9
                </Button>
            </div>
            <div className={classes.row}>
                <Button color="dark" onClick={onDotClick}>
                    .
                </Button>
                <Button color="bright" onClick={oZeroClick}>
                    0
                </Button>
                <Button color="dark" onClick={onBackspaceClick}>
                    <Backspace className={classes.backspaceIcon} />
                </Button>
            </div>
            <div className={classes.row}>
                <Button color="blue" onClick={() => onCurrencyClick(10)} small>
                    {getCurrencySymbol()}10
                </Button>
                <Button color="blue" onClick={() => onCurrencyClick(20)} small>
                    {getCurrencySymbol()}20
                </Button>
                <Button color="blue" onClick={() => onCurrencyClick(50)} small>
                    {getCurrencySymbol()}50
                </Button>
            </div>
            <div className={classes.row}>
                <Button color="blue" onClick={confirm} small>
                    ENTER
                </Button>
            </div>
        </div>
    );
};

SaleNumericPad.propTypes = {
    text: PropTypes.string,
    setText: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SaleNumericPad);
