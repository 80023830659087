import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentInvoicePrice, setCurrentInvoiceDiscount } from '../../redux/actions/invoiceActions';

import { saleInvoiceSummaryStyles as styles } from './styles';
import { useEffect } from 'react';

import { toLocaleString } from '../../collums-components/helpers';
import { getTotalsFromInvoiceItems } from '../../collums-constants/utils/index';
import { roundTwoDecimals } from '../../services/helpers';

const SaleInvoiceSummary = ({
    customerId,
    invoiceItem,
    discount,
    discountValue,
    setDiscountValue,
    total,
    setTotal,
    paymentStatus,
    classes
}) => {
    const { invoice } = useSelector((state) => state.invoice);

    const [subTotal, setSubTotal] = useState(0);
    const [taxTotal, setTaxTotal] = useState(0);

    const dispatch = useDispatch();

    const formatCurrency = (value) => `${toLocaleString(value || '0.00')}`;

    useEffect(() => {
        setDiscountValue(0);
        setTotal(0);

        invoiceItem.forEach((item) => {
            const tax = item.tax / 100;

            item.taxValue = roundTwoDecimals(item.netPrice * tax) * (item.quantity || 1);
            // If there's no promotion discount, make all items appliable for an invoice discount
            if (!discount.itemsToApply.length) {
                item.appliableDiscount = true;
                item.hasPromotionDiscount = false;
            } else {
                item.hasPromotionDiscount = discount.itemsToApply.indexOf(item.referenceId) >= 0;
                item.appliableDiscount = discount.itemsToApply.indexOf(item.referenceId) >= 0;
                item.appliableDiscountValue =
                    discount.unit === 'currency'
                        ? discount.value
                        : (item.quantity * (item.taxValue + item.netPrice) * discount.value) / 100;
            }
            // Editing cell for the first time
            if (item.isTempDiscountApplied === false) {
                item.editedDiscount = item.discount;
            }
            // Overwrite row discount by promotion discount
            if (item.hasPromotionDiscount) item.editedDiscount = 0;

            const newGrossPrice = roundTwoDecimals(item.netPrice * item.quantity + item.taxValue);

            item.grossPrice = newGrossPrice;
            item._totalPrice = roundTwoDecimals((item.grossPrice || newGrossPrice) - item.discount) || 0;

            if (item.hasPromotionDiscount === false && item.isTempDiscountApplied === true) {
                item.editedDiscount = item.discount;
            }
        });

        const { invoiceDiscount } = getTotalsFromInvoiceItems(invoiceItem, 'pos');

        const invoiceTax = invoiceItem.reduce((acc, item) => {
            return acc + item.taxValue;
        }, 0);

        const invoiceSubTotal = invoiceItem.reduce((acc, item) => {
            let tax = item.taxValue !== undefined ? roundTwoDecimals(item.taxValue) : null;

            if (!tax) {
                const fixedNetPrice = roundTwoDecimals(
                    roundTwoDecimals(item.grossPrice / item.quantity / (1 + item.tax / 100)) * item.quantity
                );

                return acc + fixedNetPrice;
            }

            return acc + (item.grossPrice - tax);
        }, 0);

        const invoiceTotal = invoiceSubTotal + invoiceTax - invoiceDiscount;

        setSubTotal(+invoiceSubTotal);
        setTaxTotal(+invoiceTax);
        setDiscountValue(+invoiceDiscount);
        setTotal(+invoiceTotal);
        dispatch(setCurrentInvoicePrice(invoiceTotal));
        dispatch(setCurrentInvoiceDiscount(invoiceDiscount));

        /* eslint-disable-next-line */
    }, [invoiceItem, discount, paymentStatus]);

    if (!customerId) {
        return null;
    }

    if (invoice && invoice.paymentStatus === 'Refund') {
        return (
            <div className={classes.row}>
                <div className={classNames(classes.summaryLabel, classes.bold)}>Total</div>
                <span className={classNames(classes.summaryAmount, classes.bold)}>
                    {formatCurrency(invoice.amount)}
                </span>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <div className={classes.row}>
                <div className={classes.summaryLabel}>Net Total</div>
                <span className={classNames(classes.summaryAmount, classes.nowrap)}>{formatCurrency(subTotal)}</span>
            </div>
            <div className={classes.row}>
                <div className={classes.summaryLabel}>Tax</div>
                <span className={classNames(classes.summaryAmount, classes.nowrap)}>{formatCurrency(taxTotal)}</span>
            </div>
            <div className={classes.row}>
                <div className={classes.summaryLabel}>Discount</div>
                <span className={classNames(classes.summaryAmount, classes.nowrap)}>
                    {discountValue > 0 && '-'} {formatCurrency(discountValue)}
                </span>
            </div>
            <div className={classes.row}>
                <div className={classNames(classes.summaryLabel, classes.bold)}>Grand Total</div>
                <span className={classNames(classes.summaryAmount, classes.bold, classes.nowrap)}>
                    {formatCurrency(total)}
                </span>
            </div>
        </div>
    );
};

SaleInvoiceSummary.propTypes = {
    customerId: PropTypes.string,
    invoiceItem: PropTypes.array.isRequired,
    discount: PropTypes.object.isRequired,
    paymentStatus: PropTypes.string,
    classes: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
    setTotal: PropTypes.func.isRequired,
    discountValue: PropTypes.number.isRequired,
    setDiscountValue: PropTypes.func.isRequired,
    allTaxes: PropTypes.array.isRequired
};

export default withStyles(styles)(SaleInvoiceSummary);
