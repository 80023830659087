import api from './api';
import Moment from 'moment';

export const getAvaillable = (date, name = '') => {
    return api.request({
        method: 'GET',
        url: `/temp-discounts/getAvaillable?date=${date}&name=${name}`
    });
};

export const getById = (id) => {
    return api.request({
        method: 'GET',
        url: `/temp-discounts/${id}`
    });
};

export const getManyTempDiscountsById = (id_array, clinicId) => {
    return api.request({
        method: 'GET',
        url: `/temp-discounts/many?ids=${id_array}&date=${Moment().toISOString()}&clinicId=${clinicId}`
    });
};
