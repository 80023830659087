import api from './api';

class CoherentApi {
    static async terminalList() {
        return api.request({
            method: 'GET',
            url: '/coherence/terminals'
        });
    }

    static async terminalCheckout(data) {
        return api.request({
            method: 'POST',
            url: '/coherence/terminal-checkout',
            data
        });
    }

    static async checkPayment(id) {
        return api.request({
            method: 'GET',
            url: `/coherence/check-payment/${id}`
        });
    }

    static async clearTerminal(data) {
        return api.request({
            method: 'POST',
            url: '/coherence/clear-terminal',
            data
        });
    }
}

export default CoherentApi;
