import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Modal,
    withStyles,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    FormControl,
    MenuItem,
    Select,
    Button
} from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import { saleProductHistory } from './styles';
import CloseBtn from '../common/CloseBtn';
import StyledTableRow from '../common/StyledTableRow';
import { getCustomerStockHistory } from '../../api/productHistory';
import { formatCurrencySpaceSeparators } from '../../services/helpers';
import { toLocaleString } from '../../collums-components/helpers';
import { calculateTaxValue, roundTwoDecimals } from '../../collums-constants/utils/index';
import { useSelector } from 'react-redux';
import moment from 'moment';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';

const SaleProductHistory = ({ isVisible, setIsVisible, clinic, classes, handleAddMultipleProducts }) => {
    const customer = useSelector((state) => state.currentCustomer);
    const taxes = useSelector((state) => state.taxes);
    const customerId = customer?.customerId || '';
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [productIsNull, setProductIsNull] = useState(false);
    const opts = Array.from(new Array(10), (_, i) => i);

    useEffect(() => {
        setIsLoading(isVisible);

        if (products.length !== 0 || productIsNull) {
            setIsLoading(false);
            setShowModal(true);
        }
        if (!isVisible) {
            setShowModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible, products]);

    useEffect(() => {
        const getProducts = async () => {
            try {
                await getCustomerStockHistory(customer.customerId, clinic).then((res) => {
                    if (res.length === 0) {
                        setProductIsNull(true);
                    }
                    res = res.map((product) => {
                        const taxFound = taxes.find((tax) => tax.id === product.tax);
                        if (taxFound) {
                            return { ...product, tax: taxFound.rate / 100 };
                        }
                        return { ...product, tax: product.tax };
                    });
                    setProducts(res);
                });
            } catch (error) {
                toastr.error('Error on Getting Customer Data');
            }
        };

        getProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);

    const formatFromValue = (value) =>
        `${_.floor(value)}.${_.padStart(_.floor(_.round(100 * (value - _.floor(value)))), 2, '0')}`;

    const getTaxValue = (data) => {
        data.tax = data.itemTax || data.tax;
        return calculateTaxValue(data);
    };

    const getDiscountValue = (data) => {
        return data.discount;
    };

    const getProductValue = (data) => {
        return data.netPrice + roundTwoDecimals(getTaxValue(data)) - getDiscountValue(data);
    };

    const onChangeAddProduct = (data, quantity) => {
        setProducts(
            products.map((product) => {
                if (product.id === data.id) {
                    return { ...data, addQuantity: quantity, addedOn: 'saleHistory' };
                }
                return product;
            })
        );
    };

    const removeAddQuantities = () => {
        const productsArr = products.map((product) => ({ ...product, addQuantity: 0 }));
        setProducts(productsArr);
    };

    const sendForm = () => {
        const addedProducts = products.filter((product) => product.addQuantity > 0);
        handleAddMultipleProducts(addedProducts);
        removeAddQuantities();
        setIsVisible(false);
    };

    return (
        <React.Fragment>
            <LoadingScreen isVisible={isLoading} />
            {products && (
                <Modal open={showModal} className={classes.modal}>
                    <div className={classes.root}>
                        <div className={classes.closeBtn}>
                            <CloseBtn onClick={() => setIsVisible(false)} />
                        </div>
                        <div className={classes.header}>
                            <p>{`${customer.customerData?.firstName} ${customer.customerData?.surname}`}</p>
                            <p>{'Product history'}</p>
                        </div>
                        <div className={classes.content}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Sold by</TableCell>
                                        <TableCell>Item</TableCell>
                                        <TableCell>#</TableCell>
                                        <TableCell align="right">Net Price</TableCell>
                                        <TableCell align="right">Tax %</TableCell>
                                        <TableCell align="right">Tax</TableCell>
                                        <TableCell align="right">Disc</TableCell>
                                        <TableCell align="right">Final Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {products.map((product, index) => (
                                        <StyledTableRow key={product.item}>
                                            <TableCell>
                                                {moment(product.createdAt).format('DD/MM/YYYY HH:mm')}
                                            </TableCell>
                                            <TableCell>{product.soldBy.displayName}</TableCell>
                                            <TableCell>{product.name}</TableCell>
                                            <TableCell>{product.quantity}</TableCell>
                                            <TableCell align="right">{`${toLocaleString(
                                                formatCurrencySpaceSeparators(formatFromValue(product.netPrice))
                                            )}`}</TableCell>
                                            <TableCell align="right">{`${product.tax * 100}%`}</TableCell>
                                            <TableCell align="right">{`${toLocaleString(
                                                formatCurrencySpaceSeparators(formatFromValue(getTaxValue(product)))
                                            )}`}</TableCell>
                                            <TableCell align="right">{`${toLocaleString(
                                                formatCurrencySpaceSeparators(
                                                    formatFromValue(getDiscountValue(product))
                                                )
                                            )}`}</TableCell>
                                            <TableCell align="right">{`${toLocaleString(
                                                formatCurrencySpaceSeparators(formatFromValue(getProductValue(product)))
                                            )}`}</TableCell>
                                            <TableCell>
                                                <FormControl
                                                    variant="outlined"
                                                    key={index}
                                                    className={classes.formControl}
                                                >
                                                    <Select
                                                        id={product.id}
                                                        onChange={(e) => onChangeAddProduct(product, e.target.value)}
                                                        value={product.addQuantity ? product.addQuantity : 0}
                                                    >
                                                        {opts.map((item) => (
                                                            <MenuItem value={item} key={item}>
                                                                {item}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                        <div className={classes.actions}>
                            <Button
                                onClick={() => {
                                    setIsVisible(false);
                                    removeAddQuantities();
                                }}
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                            <Button onClick={sendForm}>Add to invoice</Button>
                        </div>
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
};

SaleProductHistory.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    setIsVisible: PropTypes.func.isRequired,
    handleAddMultipleProducts: PropTypes.func.isRequired,
    clinic: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(saleProductHistory)(SaleProductHistory);
