const Customer = (data = {}) => {
    const receiveMarketingEmails = data.id ? (data.marketingTypes || []).includes('Email') : true;
    const receiveMarketingSms = data.id ? (data.marketingTypes || []).includes('SMS') : true;
    const receiveNotificationEmails = data.id ? (data.notificationTypes || []).includes('Email') : true;
    const receiveNotificationSms = data.id ? (data.notificationTypes || []).includes('SMS') : true;
    return {
        dateOfBirth: null,
        id: null,
        title: '',
        firstName: '',
        avatar: '',
        surname: '',
        gender: '',
        referredBy: '',
        mobilePhone: '',
        email: '',
        postal: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        notes: '',
        middleName: '',
        prevSurname: '',
        aka: '',
        otherPhoneType: '',
        otherPhoneNumber: '',
        county: '',
        country: '',
        referralSource: '',
        receiveMarketingEmails,
        receiveMarketingSms,
        receiveNotificationEmails,
        receiveNotificationSms,
        ...data
    };
};

export default Customer;
