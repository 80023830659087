import api from './config';
class ServiceApi {
    static query(categoryId, practitioner, clinic, queryParams = '') {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: `/services?category=${categoryId || ''}&practitioner=${practitioner || ''}&clinic=${clinic || ''}${
                queryParams ? `&${queryParams}` : ''
            }`
        });
    }

    static queryPublic(categoryId, clinic) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: `/services/public?category=${categoryId || ''}&clinic=${clinic || ''}`
        });
    }

    static get(serviceId) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: `/services/${serviceId}`
        });
    }

    static getFormDetails(serviceId) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: `/services/get-form-details/${serviceId}`
        });
    }

    static update(serviceId, data, isFromOrg = false, clinic) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'POST',
            url: `/services/${serviceId}?isFromOrg=${isFromOrg}${clinic ? `&clinic=${clinic}` : ''}`,
            data
        });
    }

    static create(data) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'POST',
            url: '/services',
            data
        });
    }

    static async getAvailableTime(data) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'POST',
            url: '/practitioners/time',
            data
        });
    }

    static async getServiceWithCategoriesID(data) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'POST',
            url: '/services/categories',
            data
        });
    }

    static async removeService(id, isFromOrg = false, clinic, value) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'DELETE',
            url: `/services/${id}?isFromOrg=${isFromOrg}&clinic=${clinic}&value=${value}`
        });
    }

    static changeServiceActive(id, isFromOrg = false, clinic, value) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'PUT',
            url: `/service/${id}/change-active?isFromOrg=${isFromOrg}&clinic=${clinic}&value=${value}`
        });
    }

    static changeServiceShowOnline(id, clinic, isFromOrg = false, value) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'PUT',
            url: `/service/${id}/change-show-online?isFromOrg=${isFromOrg}&clinic=${clinic}&value=${value}`
        });
    }

    static filter(query) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: `/services/filterBy?${query}`
        });
    }

    static getAll() {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: '/services/get-all'
        });
    }

    static getForPractitioner(fromTreatmentNotes = false, clinic) {
        return api.request({
            method: 'GET',
            url: `/services/get-for-practitioner?fromTreatmentNotes=${fromTreatmentNotes}&clinic=${clinic}`
        });
    }

    static getTags(serviceId) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: `/service/${serviceId}/tags`
        });
    }

    static getServiceAndCategories() {
        return api.request({
            method: 'GET',
            url: '/service-categories'
        });
    }
}

export default ServiceApi;
