import { SET_ORGANISATION } from '../constants/organisation';

export const setOrganisation = (organisation) => {
    return async (dispatch) => {
        return dispatch({
            type: SET_ORGANISATION,
            payload: organisation
        });
    };
};
