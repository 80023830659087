export const tagsStyles = () => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '4px',
        '& > button': {
            padding: 8,
            '& svg > path': {
                fill: '#3083EC'
            }
        }
    },
    tagsContainer: {
        marginTop: 8
    },
    tagChipsContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        gap: '4px'
    },
    addButtonContainer: {
        height: 38,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        placeSelf: 'flex-end'
    },
    addButton: {
        margin: '0 4px'
    },
    autocomplete: {
        '& .MuiInputBase-root': {
            padding: 2
        }
    },
    autocompleteOptions: {
        fontSize: 14,
        fontWeight: 400,
        padding: 0,
        margin: 0.5
    },
    autocompleteInputProps: {
        fontSize: 14,
        fontWeight: 400
    },
    autocompleteLabelProps: {
        fontSize: 14,
        fontWeight: 400
    },
    listBox: {
        fontSize: '14px',
        border: 'rgba(0, 0, 0, 0.4) solid 1px',
        borderRadius: '4px',
        '&::-webkit-scrollbar': {
            width: 8,
            height: 2
        },
        '&::-webkit-scrollbar-button': {
            width: 0,
            height: 0
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c4c4c4',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-track': {
            background: '#e5e5e5',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-corner': {
            background: 'transparent'
        }
    }
});

export const CommissionCategoriesStyles = () => ({
    commissionCategoriesContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    autocomplete: {
        width: '39%',
        '& .MuiInputBase-root': {
            padding: 2
        }
    },

    commissionCategoryChipsContainer: {
        paddingLeft: '2%',
        width: '49%',
        height: '100%',
        display: 'flex',
        gap: '4px',
        flexDirection: 'row',
        maxWidth: '565px',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: '0px !important'
    }
});

export const AutoCompleteStyles = () => ({
    autocomplete: {
        width: '100%',
        minHeight: 38,
        '& input': {
            fontSize: '14px',
            paddingRight: '26px !important'
        },
        '& .MuiOutlinedInput-root': {
            height: '38px !important'
        },
        '& .MuiAutocomplete-inputRoot': {
            padding: '0 !important',
            paddingLeft: '10px !important'
        }
    }
});
