import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

function AddInvoiceItemTabPanel({ children, value, index, ...props }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`sale-invoice-item-tab-panel-${index}`} {...props}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

AddInvoiceItemTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

export default AddInvoiceItemTabPanel;
