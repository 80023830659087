import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Button from '../common/Button';

import { saveDraft } from '../../api/invoiceApi';
import { submitButtonStyles as styles } from '../common/FieldContainer/styles';
import { useHistory } from 'react-router-dom';
import { assignInvoiceWithItems } from '../../api/appointmentApi';

function SaveDraftButton({ origin, amount, discount, customerId, invoiceItems, classes, description, clinic }) {
    const history = useHistory();
    const temporaryDiscount = useSelector((state) => state.invoice.selectedDiscount);
    const discountTotalValue = useSelector((state) => state.invoice.invoiceDiscount);
    const { appointment } = useSelector((state) => state.invoice);
    const [isDisabled, setIsDisabled] = useState(false);

    if (!customerId) return null;

    const handleClick = () => {
        setIsDisabled(true);
        const newInvoiceItems = invoiceItems.map((item) => {
            return {
                ...item,
                soldBy: item.practitioner ? item.practitioner : item.soldBy
            };
        });

        async function save() {
            const discountProperties = (() => {
                if (discount && !temporaryDiscount?.value) {
                    return {
                        value: discount.value,
                        type: discount.type || 'percentage'
                    };
                }
            })();
            const newInvoice = await saveDraft({
                customer: customerId,
                items: newInvoiceItems,
                description,
                discount: discountTotalValue,
                discountProperties,
                clinic,
                origin,
                amount,
                temporaryDiscount: temporaryDiscount?.value || undefined
            });

            if (newInvoice && appointment?.id) {
                await assignInvoiceWithItems(appointment?.id, newInvoice?.id, newInvoiceItems);
            }

            history.push(`/sale?invoice=${newInvoice.id}`);
            window.location.reload();
        }

        save().then(() => {
            setIsDisabled(false);
        });
    };

    return (
        <Button type="primary" variant="outlined" onClick={handleClick} className={classes.btn} isDisabled={isDisabled}>
            Save invoice
        </Button>
    );
}

SaveDraftButton.propTypes = {
    customerId: PropTypes.string,
    invoiceItems: PropTypes.array.isRequired,
    discount: PropTypes.object,
    classes: PropTypes.object.isRequired,
    origin: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    description: PropTypes.string,
    clinic: PropTypes.string
};

export default withStyles(styles)(SaveDraftButton);
