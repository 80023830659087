import { LOAD_CUSTOMERS } from '../constants/customers';

const initialState = [];

const customersReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_CUSTOMERS:
            return [...state, ...action.payload];
        default:
            return state;
    }
};

export default customersReducer;
