import { SET_ORGANISATION } from '../constants/organisation';

const initialState = {
    organisation: {}
};

const organisationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ORGANISATION:
            return {
                ...state,
                organisation: action.payload
            };
        default:
            return state;
    }
};

export default organisationReducer;
