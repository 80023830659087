import React, { useRef } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { uploadButtonStyles } from './styles';
import { toastr } from 'react-redux-toastr';

function UploadButton({
    buttonText,
    onChange,
    imgExtension,
    maxFileSize,
    style,
    classes,
    minWidth,
    minHeight,
    disabled = false
}) {
    const fileRef = useRef();
    const handleOnClickBtn = event => {
        event.stopPropagation();
        event.preventDefault();
        if (disabled) {
            return;
        }
        fileRef.current.click();
    };

    const onChangeFile = async event => {
        var file = event.target.files?.length ? event.target.files[0] : null;
        if (!file) {
            return;
        }
        if (file.size > maxFileSize) {
            return;
        }
        let picture = undefined;
        if (file.type.includes('image')) {
            const result = await new Promise(resolve => {
                let fileReader = new FileReader();

                fileReader.onload = e => {
                    if (!minWidth && !minHeight) {
                        resolve(fileReader.result);
                        return;
                    }

                    let img = new Image();
                    img.src = e.target.result;
                    img.onload = () => {
                        if (img.height < minHeight || img.width < minWidth) {
                            resolve(null);

                            toastr.error('Please choose an image with a minimum size of 300x300 pixels');
                        } else {
                            resolve(fileReader.result);
                        }
                    };
                };
                fileReader.readAsDataURL(file);
            });
            picture = result;
        }
        onChange(file, picture);
    };
    return (
        <div className={classes.uploadButton} style={style}>
            <button disabled={disabled} onClick={handleOnClickBtn} type="button">
                {buttonText}
            </button>
            <input
                ref={fileRef}
                type="file"
                className={classes.inputFile}
                onChange={onChangeFile}
                accept={imgExtension}
            ></input>
        </div>
    );
}

UploadButton.propTypes = {
    buttonText: PropTypes.string.isRequired,
    imgExtension: PropTypes.array.isRequired,
    maxFileSize: PropTypes.number,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    onChange: PropTypes.func,
    style: PropTypes.object,
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool
};

export default withStyles(uploadButtonStyles)(UploadButton);
